var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "30%",
        "before-close": _vm.handleCancel,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "modalFrom",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { prop: "departmentId" },
            },
            [
              _c("el-input", {
                attrs: { disable: "" },
                model: {
                  value: _vm.form.departmentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "departmentId", $$v)
                  },
                  expression: "form.departmentId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "部门名称：", prop: "departmentName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.departmentName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "departmentName", $$v)
                  },
                  expression: "form.departmentName",
                },
              }),
            ],
            1
          ),
          parseInt(_vm.form.parentId) !== -1
            ? _c(
                "el-form-item",
                { attrs: { label: "上级部门：", prop: "parentId" } },
                [
                  _c("el-select-tree", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "check-strictly": "",
                      data: _vm.departmentTree,
                      props: {
                        label: "departmentName",
                        child: "children",
                        value: "departmentId",
                      },
                    },
                    model: {
                      value: _vm.form.parentId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentId", $$v)
                      },
                      expression: "form.parentId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "部门负责人：", prop: "headerPeople" } },
            [
              _c(
                "el-input",
                {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.form.headerPeople,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "headerPeople", $$v)
                    },
                    expression: "form.headerPeople",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "append" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSelectUser },
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "创建日期：", prop: "createTime" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  type: "datetime",
                  placeholder: "选择日期时间",
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.form.createTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "createTime", $$v)
                  },
                  expression: "form.createTime",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleOk },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("select-user-modal", {
        ref: "SelectUserModal",
        on: { selectUser: _vm.selectUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }