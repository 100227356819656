var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "department-tree" },
    [
      _c(
        "header",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            { attrs: { inline: "", model: _vm.depFrom, size: "small" } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "120px" },
                    attrs: { placeholder: "部门名称" },
                    model: {
                      value: _vm.depFrom.departmentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.depFrom, "departmentName", $$v)
                      },
                      expression: "depFrom.departmentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "120px", "margin-left": "10px" },
                      attrs: { placeholder: "启用状态" },
                      model: {
                        value: _vm.depFrom.isEnable,
                        callback: function ($$v) {
                          _vm.$set(_vm.depFrom, "isEnable", $$v)
                        },
                        expression: "depFrom.isEnable",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "", label: "全部" } }),
                      _c("el-option", { attrs: { value: 0, label: "启用" } }),
                      _c("el-option", { attrs: { value: 1, label: "停用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "article",
        { staticClass: "tree-wrapper" },
        [
          _c("el-tree", {
            staticClass: "tree-custom",
            attrs: {
              data: _vm.treeData,
              props: _vm.defaultProps,
              "expand-on-click-node": false,
              "default-expand-all": "",
            },
            on: { "node-click": _vm.handleNodeClick },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ node, data }) {
                  return _c(
                    "div",
                    { staticClass: "custom-tree-node" },
                    [
                      _c("div", [
                        _vm._v(" " + _vm._s(data.departmentName)),
                        _c(
                          "span",
                          {
                            staticClass: "status",
                            class: { "status-stop": data.isDisable === 1 },
                          },
                          [
                            _vm._v(
                              _vm._s(data.isDisable === 0 ? "启用" : "停用")
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "el-dropdown",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.checkedId === data.departmentId,
                              expression: "checkedId === data.departmentId",
                            },
                          ],
                          on: {
                            command: (command) =>
                              _vm.handleCommand(command, data),
                          },
                        },
                        [
                          _c("span", { staticClass: "el-dropdown-link" }, [
                            _c("span", { staticClass: "el-icon-setting" }),
                          ]),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: 1 } },
                                [_vm._v("新建下级部门")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: 2 } },
                                [_vm._v(" 新建平级部门")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: 3 } },
                                [_vm._v("编辑部门")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: 4 } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      data.isDisable === 0
                                        ? "停用部门"
                                        : "启用部门"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("edit-department-modal", {
        ref: "EditDepartmentModal",
        attrs: { departmentTree: _vm.treeData },
        on: { modalFormOk: _vm.modalFormOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }