<template>
  <el-dialog
    title="新增/编辑"
    :visible="visible"
    width="500px"
    @close="onCancel"
    :show-close="false"
  >
    <!-- <div v-loading="loading"></div> -->
    <el-form
      :model="form"
      :rules="rules"
      ref="modalFrom"
      label-width="100px"
      size="small"
      v-if="typeList.length > 0"
    >
      <el-form-item prop="channelId" label="渠道ID：">
        <el-input v-model="form.channelId" placeholder="编号规则示例:首字母 +区号 + 4位数编号">
          <el-tooltip
            slot="suffix"
            class="item"
            effect="dark"
            content="编号规则示例:首字母 +区号 + 4位数编号"
            placement="top"
          >
            <i class="el-input__icon el-icon-warning"></i>
          </el-tooltip>
        </el-input>
      </el-form-item>
      <el-form-item label="渠道类型：" prop="channelTypeId">
        <el-select-tree
          :data="typeList"
          :props="{ label: 'channelTypeName', child: 'children', value: 'channelTypeId' }"
          check-strictly
          v-model="form.channelTypeId"
          style="width: 100%"
          accordion
          node-key="channelTypeId"
          @node-expand="typeChange"
          @current-change="typeChangeToBol"
          ref="tree"
        />
      </el-form-item>
      <el-form-item label="渠道城市：" prop="districtId" v-if="showCity">
        <!-- <el-cascader
          v-model="form.districtId"
          placeholder="请选择"
          :props="props"
          :show-all-levels="false"
          ref="cascader"
        ></el-cascader> -->
        <el-select-tree
          :load="lazyLoad"
          lazy
          :props="{ value: 'districtId', label: 'districtName', child: 'childs' }"
          v-model="form.districtId"
          style="width: 100%"
          accordion
          filterable
          @current-change="cityChange"
          ref="cascader"
        />
      </el-form-item>
      <el-form-item label="渠道名称：" prop="channelName">
        <el-input v-model="form.channelName" placeholder="请输入渠道名称" />
      </el-form-item>
      <el-form-item label="渠道等级：" prop="level" placeholder="请选择渠道等级">
        <el-select v-model="form.level" style="width: 100%">
          <el-option :value="1" label="A级"></el-option>
          <el-option :value="2" label="B级"></el-option>
          <el-option :value="3" label="C级"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="负责人：" prop="userName">
        <!-- <el-input size="small" style="width: 200px" v-model="ruleForm.erpUserId" placeholder="请输入"></el-input> -->
        <el-autocomplete
          v-model="form.userName"
          value-key="userName"
          label="userId"
          :fetch-suggestions="querySearchStaffAsync"
          placeholder="请输入负责人姓名"
          style="width: 100%"
          popper-class="my-autocomplete"
          @select="userSelect"
        >
          <template slot-scope="{ item }">
            <div class="prdtname">
              <span>{{ item.userName + ' - ' }}</span>
              <span>{{ item.userId }}</span>
            </div>
            <div>
              <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
              <span class="brdtype">{{ item.postNames }}</span>
            </div>
          </template>
        </el-autocomplete>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { omit } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';
import { getChannelTree } from '@/api/channel';
import { getDireactionListByKey } from '@/api/dictionary';
import { getCityList } from '@/api/city';
import { userList } from '@/api/user';

export default {
  name: 'EditChannelModal',
  components: {},
  mixins: [TableModalMinix],
  props: {
    channelId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      isEdit: false,
      loading: false,
      isShowSelectParent: true,
      defaultForm: {
        channelId: '',
        channelTypeId: '',
        channelName: '',
        level: '',
        districtId: '',
        districtName: '',
        userId: '',
        userName: ''
      },
      form: {
        id: '',
        channelId: '',
        channelTypeId: '',
        channelName: '',
        level: '',
        districtId: '',
        districtName: '',
        userId: '',
        userName: ''
      },
      url: {
        add: 'misc-web-api/admin/channel/addChannel',
        update: 'misc-web-api/admin/channel/updateChannel'
      },
      levelList: [],
      rules: {
        channelId: [{ required: true, message: '请输入值', trigger: 'blur' }],
        channelName: [
          { required: true, message: '请输入值', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        channelTypeId: [{ required: true, message: '请选择渠道类型', trigger: 'blur' }],
        districtId: [{ required: true, message: '请选择渠道城市', trigger: 'change' }],
        userName: [{ required: true, message: '请选择负责人', trigger: 'change' }]
      },
      typeList: [], // 字典类型
      props: {
        lazy: true,
        value: 'districtId',
        label: 'districtName',
        children: 'childs',
        emitPath: false,
        lazyLoad(node, resolve) {
          console.log(node);
          getCityList({
            data: {
              districtId: node.value,
              districtType: node.level + 1,
              status: node.level + 1 === 2 && 1
            }
          }).then((res) => {
            if (node.level + 1 === 3) {
              res = res.map((e) => {
                e.leaf = true;
                return e;
              });
            }
            console.log(res);
            resolve(res);
          });
        }
      },
      channelTypeId: '1',
      showCity: true
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getTypeList();
      }
    },
    channelId: {
      handler(val) {
        console.log(val);
        this.form.channelTypeId = val;
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    querySearchStaffAsync(queryString, cb) {
      console.log(queryString);
      userList({
        data: {
          userNameOrPhoneNumber: queryString
        },
        pageNumber: 1,
        pageSize: 999
      }).then((res) => {
        console.log(res);
        cb(res.content);
      });
    },
    lazyLoad(node, resolve) {
      console.log(node);
      if (node.data && node.data.isLeaf) return resolve([]);
      getCityList({
        data: {
          districtId: node.data?.districtId,
          districtType: node.level + 1,
          status: node.level + 1 === 2 && 1
        }
      }).then((res) => {
        if (node.level === 2) {
          res = res.map((e) => {
            e.isLeaf = true;
            return e;
          });
        }
        console.log(res);
        resolve(res);
      });
    },
    userSelect(e) {
      console.log(e);
      this.form.userId = e.userId;
    },
    cityChange(e) {
      console.log(e);
      this.form.districtName = e.districtName;
    },
    typeChange(e) {
      console.log(e);
      if (e.level === 2) {
        this.channelTypeId = e.channelTypeId;
      }
    },
    typeChangeToBol(e) {
      console.log(e);
      if (e.channelTypeId === '2') {
        this.showCity = false;
        return;
      }
      if (e.channelTypeId === '1') {
        this.showCity = true;
        return;
      }
      //
      this.showCity = !e.onLine;
    },
    closed() {
      this.isShowSelectParent = true;
    },
    add() {
      this.isEdit = false;
      this.visible = true;
    },
    edit(row) {
      this.isEdit = true;
      this.visible = true;
      this.form.id = row.id;
      this.form.channelId = row.channelId;
      this.form.channelName = row.channelName;
      this.form.level = row.level;
      this.form.channelTypeId = row.channelTypeId;
      this.form.districtId = row.districtId;
      this.form.districtName = row.districtName;
      this.form.userId = row.userId;
      this.form.userName = row.userName;
      // this.cacheData = [{ districtId: row.districtId, districtName: row.districtName }];
    },
    handleClose() {
      console.log('close');
    },
    // 获取level 列表
    async getLevelList() {
      try {
        const parqams = {
          data: {
            key: 'channel_level'
          }
        };
        const res = await getDireactionListByKey(parqams);
        this.levelList = res;
      } catch (e) {
        console.log(e);
      }
    },
    onCancel() {
      this.$refs.modalFrom.resetFields();
    },
    // 获取分类类型
    async getTypeList() {
      this.loading = true;
      try {
        const params = { data: {} };
        let res = await getChannelTree(params);
        console.log(res);
        this.loading = false;
        res = res.map((element) => {
          console.log(element);
          if (element.channelTypeId === '2') element = this.handleTree([element])[0];
          return element;
        });

        this.typeList = res;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    handleTree(tree) {
      console.log(tree);
      return tree.map((e) => {
        e.onLine = true;
        if (e.children.length) e.children = this.handleTree(e.children);
        return e;
      });
    },
    async handleOk() {
      const that = this;
      if (this.confirmLoading) {
        return;
      }

      console.log(this.form);
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            const updateParams = {
              data: {
                ...this.form
              }
            };
            await this.handleModalUpdate(updateParams);
          } else {
            const formData = omit(this.form, ['id']);
            const params = {
              data: formData
            };
            await this.handleModalAdd(params);
          }
          // this.$emit('ok');
          // this.handleCancel();
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .prdtname {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .brdtype {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .brdtype {
      color: #ddd;
    }
  }
}
</style>
