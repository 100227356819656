var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "miniprogram-products" },
    [
      _c("ManageOptions", {
        on: { treeClick: _vm.treeClick, filtersRefresh: _vm.filtersRefresh },
      }),
      _vm.canOperate
        ? _c("ContentEdit", {
            ref: "ContentEdit",
            attrs: { treeNodeItem: _vm.treeNodeItem },
          })
        : _vm._e(),
      !_vm.canOperate
        ? _c(
            "div",
            { staticClass: "nothing-wrap" },
            [_c("el-empty", { attrs: { description: "当前分类不可操作" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }