<template>
  <div class="advertisement">
    <div class="title-bar">
      <span class="title">广告配置</span>

      <div class="btn-wrap">
        <el-button
          type="text"
          icon="el-icon-refresh-left"
          size="large"
          @click="reduceAdvertisement"
        >
          还原到上个版本
        </el-button>

        <el-button type="primary" size="mini" @click="saveAdvertisement">立即发布</el-button>
      </div>
    </div>

    <el-divider class="divider"></el-divider>

    <div class="common-title">轮播间隔时长配置</div>

    <div class="common-row">
      <div class="common-row_name"><span class="symbol">*</span>间隔时长：</div>
      <div class="common-row_container">
        <div>
          <el-input-number v-model="interval" :min="1" :max="4" size="small"></el-input-number> 秒
        </div>
        <div class="tips">仅支持1-4的整数</div>
      </div>
    </div>

    <div class="common-title ad-settings">
      <span>广告配置</span>
      <el-button type="primary" icon="el-icon-plus" size="small" plain @click="addItem"></el-button>
    </div>

    <div class="setting-list">
      <AdvertisementItem
        v-for="(item, index) in adList"
        :key="item.key || item.picUrl"
        :index="index"
        :item="item"
        @deleteItem="deleteItem"
        @change="advertisementItemChange"
      />
    </div>
  </div>
</template>

<script>
import AdvertisementItem from '../components/AdvertiseMentItem.vue';
import {
  getAdvertisementDetail,
  advertisementSave,
  reductionAdvertisement,
  uploadImage
} from '@/api/mediaAdvertisement';

export default {
  components: {
    AdvertisementItem
  },
  data() {
    return {
      interval: 1,
      adList: []
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    addItem() {
      if (this.adList.length >= 4) {
        this.$message.warning('最多添加4个广告配置');
        return;
      }

      this.adList.push({ key: Date.now(), picUrl: '', link: '', sort: 1, file: null });
    },
    deleteItem(index) {
      this.adList.splice(index, 1);
    },
    getDetail() {
      getAdvertisementDetail({})
        .then((res) => {
          console.log(res);
          if (!res) return;

          const { interval, files } = res;
          this.interval = interval || 1;
          this.adList = files ? files.map((el) => ({ key: '', file: null, ...el })) : [];
          console.log(this.adList);
        })
        .catch((err) => console.log(err));
    },
    saveAdvertisement() {
      this.$confirm('是否确认立即发布？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            for (let i = 0; i < this.adList.length; i += 1) {
              const item = this.adList[i];

              if (!item.picUrl || !item.link || !item.sort) {
                this.$message.warning('还有必填项未填写，请检查');
                return;
              }
            }

            const imgHttpsArr = this.adList.filter((el) => !el.file);
            const imgWaitArr = this.adList.filter((el) => el.file);
            const imgWaitSrcArr = await Promise.all(imgWaitArr.map((el) => uploadImage(el.file)));

            imgWaitSrcArr.forEach((url, index) => {
              const blobUrl = imgWaitArr[index].picUrl;
              imgWaitArr[index].picUrl = url.http;
              delete imgWaitArr[index].file;
              delete imgWaitArr[index].key;
              window.URL.revokeObjectURL(blobUrl);
            });

            imgHttpsArr.forEach((el) => {
              delete el.file;
              delete el.key;
            });

            const res = await advertisementSave({
              data: {
                interval: this.interval,
                files: [...imgHttpsArr, ...imgWaitArr]
              }
            });

            if (ResizeObserver) {
              this.getDetail();
              this.$message.success('发布成功');
              return;
            }
            if (!res) {
              this.$message.error('发布失败');
              return;
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => console.log(err));
    },
    advertisementItemChange(obj) {
      this.adList[obj.index] = obj.val;
    },
    reduceAdvertisement() {
      this.$confirm('是否确认还原到上一个版本？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          reductionAdvertisement()
            .then((res) => {
              if (!res) return;
              const { interval, files } = res;
              this.interval = interval || 1;
              this.adList = files ? files.map((el) => ({ key: '', file: null, ...el })) : [];
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
  }
};
</script>

<style lang="scss" scoped>
.advertisement {
  padding: 10px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;

  .divider {
    margin: 10px 0;
  }

  .title-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 14px;
    }

    .btn-wrap {
      text-align: center;

      ::v-deep .el-button {
        .el-icon-refresh-left {
          font-size: 16px;
        }
      }
    }
  }

  .common-title {
    font-size: 18px;
    margin-top: 30px;
  }

  .common-row {
    display: inline-flex;
    margin-top: 30px;

    &_name {
      font-size: 14px;

      .symbol {
        color: #e86247;
        font-size: 14px;
      }
    }

    &_container {
      position: relative;

      .tips {
        color: #9a9d9e;
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }

  .ad-settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .setting-list {
    min-height: 100px;
    margin-top: 30px;

    > :nth-child(n) {
      margin: 0 20px 20px 0;
    }
  }
}
</style>
