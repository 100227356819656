var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        width: "30%",
        "before-close": _vm.handleCancel,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "modalFrom",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "100px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { prop: "postId" },
            },
            [
              _c("el-input", {
                attrs: { disable: "" },
                model: {
                  value: _vm.form.postId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "postId", $$v)
                  },
                  expression: "form.postId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "岗位名称：", prop: "postName" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.postName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "postName", $$v)
                  },
                  expression: "form.postName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "岗位类型：", prop: "postType" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-item-select",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.postType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "postType", $$v)
                    },
                    expression: "form.postType",
                  },
                },
                _vm._l(_vm.positionList, function (item) {
                  return _c("el-option", {
                    key: item.dictionaryValue,
                    attrs: {
                      label: item.dictionaryName,
                      value: item.dictionaryValue,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "角色权限：", prop: "roleId" },
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "form-item-select",
                  attrs: { placeholder: "请选择", filterable: "" },
                  model: {
                    value: _vm.form.roleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "roleId", $$v)
                    },
                    expression: "form.roleId",
                  },
                },
                _vm._l(_vm.roleList, function (role) {
                  return _c("el-option", {
                    key: role.roleId,
                    attrs: { label: role.roleName, value: role.roleId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleOk },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }