<template>
  <section class="advice-detail" v-loading="loading">
    <article class="advice-detail-info">
      <el-row :gutter="24">
        <el-col :span="6">
          <div class="info-item">
            <div class="name">客户ID：</div>
            <div class="text" style="color: #2861e2;cursor: pointer;" @click="handleToJumper(detailInfo.customerId)">
              {{ detailInfo.customerId }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-item">
            <div class="name">姓名：</div>
            <div class="text">
              {{ detailInfo.userName }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-item">
            <div class="name">用户ID：</div>
            <div class="text">
              {{ detailInfo.userId }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-item">
            <div class="name">用户手机号：</div>
            <div class="text">
              {{ detailInfo.phone }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-item">
            <div class="name">类型：</div>
            <div class="text">
              {{ detailInfo.typeName }}
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="info-item">
            <div class="name">提交时间：</div>
            <div class="text">
              {{ detailInfo.createTime }}
            </div>
          </div>
        </el-col>
      </el-row>
    </article>
    <article class="advice-detail-content">
      <common-title title="意见反馈" />
      <div class="user-advice">
        {{ detailInfo.content }}
      </div>
      <div class="img-list" v-if="detailInfo.pics && detailInfo.pics.length > 0">
        <el-image
          v-for="(img, imgIndex) in detailInfo.pics"
          :key="imgIndex"
          class="img"
          style="width: 100px; height: 100px"
          :src="img"
          :preview-src-list="[img]"
        >
        </el-image>
      </div>
    </article>
  </section>
</template>

<script>
import CommonTitle from '@/components/common-title/index.vue';
import { getAdviceDetail } from '@/api/advice';

export default {
  name: 'AdviceDetail',
  components: {
    CommonTitle
  },
  data() {
    return {
      loading: false,
      detailInfo: {}
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    async getDetail() {
      if (!this.$route.query.feedbackId) {
        this.$message.error('反馈id不存在');
        return;
      }
      const params = {
        data: this.$route.query.feedbackId
      };
      this.loading = true;
      try {
        const res = await getAdviceDetail(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        res.pics = res.pics ? JSON.parse(res.pics) : '';
        this.detailInfo = res;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    handleToJumper(id) {
      window.history.pushState(null, null, `/index/chance/clientFollowUp/particulars?id=${id}`);
    }
  }
};
</script>

<style scoped lang="less">
.advice-detail {
  margin-top: 16px;

  .advice-detail-info {
    padding: 20px 20px 0 20px;
    background: #fff;
  }
  .info-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 20px;
    .name {
      color: #9a9d9e;
    }
    .text {
      color: #000;
    }
  }
  .advice-detail-content {
    background: #fff;
    padding-bottom: 30px;
    .user-advice {
      padding-left: 20px;
      font-size: 14px;
      line-height: 30px;
    }
    .img-list {
      display: flex;
      .img {
        margin-left: 20px;
      }
    }
  }
}
</style>
