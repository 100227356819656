var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "left-menu" }, [
    _c("div", { staticClass: "title" }, [_vm._v("首页内容整理")]),
    _c("ul", { staticClass: "content" }, [
      _c(
        "li",
        {
          class: { active: _vm.type === "1" },
          on: {
            click: function ($event) {
              return _vm.handleClick("1")
            },
          },
        },
        [_vm._v("轮播图")]
      ),
      _c(
        "li",
        {
          class: { active: _vm.type === "2" },
          on: {
            click: function ($event) {
              return _vm.handleClick("2")
            },
          },
        },
        [_vm._v("导航栏")]
      ),
      _c(
        "li",
        {
          class: { active: _vm.type === "3" },
          on: {
            click: function ($event) {
              return _vm.handleClick("3")
            },
          },
        },
        [_vm._v("推荐")]
      ),
      _c(
        "li",
        {
          class: { active: _vm.type === "4" },
          on: {
            click: function ($event) {
              return _vm.handleClick("4")
            },
          },
        },
        [_vm._v("首页弹窗")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }