
import request from '@/utils/request';

/**
 *
 * @param data
 * @returns {AxiosPromise}
 */
export function getDictionaryTypeList(data) {
  return request({
    url: "misc-web-api/admin/dictionary/parentDictionarys",
    method: 'POST',
    data,
  })
}


// 获取字典列表
export function getDictionaryList(data) {
  return request({
    url: "misc-web-api/admin/dictionary/list",
    method: 'POST',
    data,
  })
}

// 删除字典

export function deleteDictionary(data) {
  return request({
    url: "misc-web-api/admin/dictionary/del",
    method: 'POST',
    data,
  })
}

// 根据dictionaryKey 查询对应字典

export function getDireactionListByKey(data) {
  return request({
    url: "misc-web-api/admin/dictionary/getDictionary",
    method: 'POST',
    data,
  })
}

// 保存字典
export function editDireactionListByKey(data) {
  return request({
    url: "misc-web-api/admin/dictionary/editDictionary",
    method: 'POST',
    data,
  })
}

export default {}
