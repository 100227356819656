import request from '@/utils/request';

//  获取OSS
export function getSTS(data) {
  return request({
    url: 'misc-web-api/common/file/getSts',
    method: 'get',
    data,
    allResult: true
  });
}
