<template>
  <el-dialog title="新增" :visible="visible" width="30%" v-loading="loading" :before-close="handleCancel">
    <el-form
      :model="form"
      :rules="rules"
      ref="modalFrom"
      label-width="100px"
      size="small"
      v-if="typeList.length > 0"
    >
      <el-form-item prop="channelTypeId" v-show="isEdit" label="渠道类型ID：">
        <el-input disabled v-model="form.channelTypeId"></el-input>
      </el-form-item>
      <el-form-item label="类型名称：" prop="channelTypeName">
        <el-input v-model="form.channelTypeName" placeholder="请输入类型名称" />
      </el-form-item>
      <el-form-item label="上级类型：" prop="pid" v-if="form.pid !== '0'">
        <el-select-tree
          :data="typeList"
          :props="{ label: 'channelTypeName', child: 'children', value: 'channelTypeId' }"
          check-strictly
          v-model="form.pid"
          style="width: 100%"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { omit } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';
import { getChanneleTree } from '@/api/channel';

export default {
  name: 'EditChannelTypeModal',
  components: {},
  mixins: [TableModalMinix],
  data() {
    return {
      visible: false,
      isEdit: false,
      loading: false,
      isShowSelectParent: true,
      defaultForm: {
        channelTypeId: '',
        channelTypeName: '',
        pid: ''
      },
      form: {
        channelTypeId: '',
        channelTypeName: '',
        pid: ''
      },
      url: {
        add: 'misc-web-api/admin/channel/addChannelType',
        update: 'misc-web-api/admin/channel/updateChannelType'
      },
      rules: {
        channelTypeName: [
          { required: true, message: '请输入值', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        pid: [{ required: true, message: '请选择上级部门', trigger: 'blur' }]
      },
      typeList: [] // 字典类型
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getTypeList();
      }
    }
  },

  methods: {
    closed() {
      this.isShowSelectParent = true;
    },
    add() {
      this.isEdit = false;
      this.visible = true;
    },
    edit(row) {
      this.isEdit = true;
      this.visible = true;
      this.form.channelTypeId = row.channelTypeId;
      this.form.channelTypeName = row.channelTypeName;
      this.form.pid = row.pid;
    },
    handleClose() {
      console.log('close');
    },
    // 获取分类类型
    async getTypeList() {
      this.loading = true;
      try {
        const params = { data: {} };
        const res = await getChanneleTree(params);
        this.loading = false;
        this.typeList = res;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async handleOk() {
      const that = this;
      if (this.confirmLoading) {
        return;
      }
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            const updateParams = {
              data: {
                ...this.form
              }
            };
            await this.handleModalUpdate(updateParams);
          } else {
            const formData = omit(this.form, ['channelTypeId']);
            const params = {
              data: formData
            };
            await this.handleModalAdd(params);
          }
          this.$emit('ok');
          this.handleCancel();
        }
      });
    }
  }
};
</script>

<style scoped></style>
