<template>
  <section class="preview-box">
    <div class="banner-box" v-if="bannerList.length > 0">
      <i class="el-icon-picture img"></i>
    </div>
    <div class="navigation-box">
      <div class="navigation-item" v-for="(nav, index) in navigationList" :key="index">
        <i class="el-icon-picture img"></i>
        <div class="1">{{ nav.name }}</div>
      </div>
    </div>
    <div class="recommend-box">
      <div class="recommend-item" v-for="(area, index) in recommandList" :key="index">
        <div class="title">{{ area.title }}</div>
        <div class="sub-title">{{ area.subject }}</div>
        <div class="img">
          <i class="el-icon-picture img"></i>
        </div>
      </div>
    </div>
    <div class="brand-box" v-if="brandData.length > 0">
      <div class="brand-tab" :style="brandData.length > 4 ? '' : 'justify-content: space-around'">
        <div class="brand-name-item" v-for="(item, index) in brandData" :key="index">
          {{ item.recommendBrandName }}
        </div>
      </div>

      <div class="brand-item" v-for="(item, index) in brandData" :key="index">
        <div class="brand-name">{{ item.recommendBrandName }}</div>

        <div class="img">
          <i class="el-icon-picture img"></i>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'PreviewView',
  props: {
    bannerList: {
      type: Array,
      default: () => []
    },
    navigationList: {
      type: Array,
      default: () => []
    },
    recommandList: {
      type: Array,
      default: () => []
    },
    brandList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    brandData() {
      return this.brandList.filter((el) => {
        if (el.isSave === false) {
          return false;
        }
        return true;
      });
    }
  }
};
</script>
<style lang="less">
.preview-box {
  height: 500px;
  background: #fff;
  margin-left: 20px;
  .banner-box {
    display: flex;
    height: 375px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f0f5ff;
    .img {
      font-size: 100px;
      color: #dfe8fc;
    }
  }
  .navigation-box {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    box-sizing: border-box;
    overflow: hidden;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .navigation-item {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      font-size: 12px;
      color: #666;
      .img {
        font-size: 30px;
        color: #dfe8fc;
      }
    }
  }
  .recommend-box {
    .recommend-item {
      .title {
        font-size: 18px;
        font-weight: 500;
        color: #111111;
        padding-left: 15px;
      }
      .sub-title {
        font-size: 12px;
        color: #666;
        padding-left: 15px;
      }
      .img {
        margin-top: 10px;
        height: 200px;
        background: #f0f5ff;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 30px;
          color: #dfe8fc;
        }
      }
    }
  }
  .brand-box {
    margin-top: 20px;

    .brand-tab {
      display: flex;
      flex-wrap: wrap;

      .brand-name-item {
        width: 88px;
        text-align: center;
        margin-bottom: 5px;
      }
    }

    .brand-item {
      margin-top: 20px;

      .brand-name {
        font-size: 18px;
        font-weight: 500;
        color: #111111;
      }

      .img {
        margin-top: 10px;
        height: 200px;
        background: #f0f5ff;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 30px;
          color: #dfe8fc;
        }
      }
    }
  }
}
</style>
