var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "index-config-wrapper" },
    [
      _c("cityProject", { on: { change: _vm.selectCityProject } }),
      _c("left-menu", {
        attrs: {
          navigationList: _vm.navigationList,
          recommandList: _vm.recommandList,
        },
        on: { changeType: _vm.handleTypeChange },
      }),
      _c("section", { staticClass: "right-edit" }, [
        _c("header", { staticClass: "right-edit-header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("设置内容")]),
          _c(
            "div",
            { staticClass: "extra" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text" },
                  on: { click: _vm.handleShowBackModal },
                },
                [_vm._v("还原到当前发布版本")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.handleShowPublishModal },
                },
                [_vm._v("立即发布")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.handleSaveModal },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { class: { "add-ctl": true, "brand-ctl": _vm.editType === "5" } },
          [
            _c("div", { staticClass: "title" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editType === "4",
                      expression: "editType === '4'",
                    },
                  ],
                },
                [_vm._v("首页弹窗")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editType === "3",
                      expression: "editType === '3'",
                    },
                  ],
                },
                [_vm._v("推荐")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editType === "2",
                      expression: "editType === '2'",
                    },
                  ],
                },
                [_vm._v("导航栏")]
              ),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editType === "1",
                      expression: "editType === '1'",
                    },
                  ],
                },
                [_vm._v("banner轮播图")]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editType === "5",
                      expression: "editType === '5'",
                    },
                  ],
                },
                [
                  _vm.editType === "5"
                    ? _c(
                        "el-button",
                        {
                          staticStyle: {
                            "margin-top": "10px",
                            "margin-bottom": "10px",
                          },
                          attrs: {
                            size: "small",
                            icon: "el-icon-plus",
                            type: "primary",
                            plain: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addTab(_vm.editableTabsValue)
                            },
                          },
                        },
                        [_vm._v("添加品牌")]
                      )
                    : _vm._e(),
                  _vm.editableTabs.length
                    ? _c(
                        "el-tabs",
                        {
                          attrs: { type: "card", closable: "" },
                          on: {
                            "tab-remove": _vm.removeTab,
                            "tab-click": _vm.tabClick,
                          },
                          model: {
                            value: _vm.editableTabsValue,
                            callback: function ($$v) {
                              _vm.editableTabsValue = $$v
                            },
                            expression: "editableTabsValue",
                          },
                        },
                        _vm._l(_vm.editableTabs, function (item) {
                          return _c("el-tab-pane", {
                            key: item.name,
                            attrs: { label: item.title, name: item.name },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm.editType != "5"
              ? _c(
                  "div",
                  { staticClass: "extra" },
                  [
                    _c("el-button", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editType === "1" || _vm.editType === "2",
                          expression: "editType === '1' || editType === '2'",
                        },
                      ],
                      staticStyle: { width: "80px" },
                      attrs: {
                        icon: "el-icon-plus",
                        type: "primary",
                        plain: "",
                      },
                      on: { click: _vm.handleAddBannerOrNavigation },
                    }),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editType === "3",
                            expression: "editType === '3'",
                          },
                        ],
                        attrs: {
                          size: "small",
                          icon: "el-icon-plus",
                          type: "primary",
                          disabled: _vm.recommendHasChildren,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddArea(1)
                          },
                        },
                      },
                      [_vm._v("新增吸顶导航区")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editType === "3",
                            expression: "editType === '3'",
                          },
                        ],
                        attrs: {
                          size: "small",
                          icon: "el-icon-plus",
                          type: "primary",
                          plain: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleAddArea(2)
                          },
                        },
                      },
                      [_vm._v("新增推荐区域 ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c(
          "article",
          {
            ref: "editContent",
            class: {
              "edit-content": true,
              "brand-content": _vm.editType === "5" && _vm.editableTabs.length,
            },
          },
          [
            _vm.editType === "1"
              ? _c("banner-edit", {
                  ref: "BannerEdit",
                  attrs: { topicId: _vm.topicId },
                })
              : _vm._e(),
            _vm.editType === "2"
              ? _c("navigation-edit", {
                  ref: "NavigationEdit",
                  attrs: { topicId: _vm.topicId },
                })
              : _vm._e(),
            _vm.editType === "3"
              ? _c("recommend-edit", {
                  ref: "RecommendEdit",
                  attrs: {
                    topicId: _vm.topicId,
                    recommendHasChildren: _vm.recommendHasChildren,
                  },
                  on: { changeHasChildren: _vm.changeHasChildren },
                })
              : _vm._e(),
            _vm.editType === "4"
              ? _c("dialog-settings", {
                  ref: "DialogSettings",
                  attrs: { topicId: _vm.topicId },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("preview-view", {
        attrs: {
          bannerList: _vm.bannerList,
          navigationList: _vm.navigationList,
          recommandList: _vm.recommandList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }