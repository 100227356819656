var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "pagination-wrapper" }, [
    _c("div", {}),
    _c(
      "div",
      {},
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "current-page": _vm.currentPage,
            "page-sizes": _vm.pageSizes,
            "page-size": _vm.pageSize,
            layout: _vm.layout,
            total: _vm.total,
          },
          on: {
            "size-change": _vm.handleSizeChange,
            "current-change": _vm.handleCurrentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }