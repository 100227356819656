<template>
  <section class="user-agreement" v-loading="loading">
    <search @search="handleSearch" @reset="handleRest" ref="Search" />
    <div class="table-wrapper">
      <vxe-grid border :data="dataSource" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #toolbar_buttons>
          <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
          <!-- <el-button size="small" type="default">导出</el-button> -->
        </template>

        <template #operate="{ row }">
          <el-button type="text" @click="handleShowDetail(row)">查看详情</el-button>
          <el-button type="text" @click="handleEdit(row)">在本版本上修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
    <edit-modal ref="modalForm" @modalFormOk="modalFormOk" />
  </section>
</template>

<script>
import Search from './AgreeSearch.vue';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMinix from '@/minixs/TableListMinix';
import EditModal from './EditModal.vue';
import { deleteAgreement } from '@/api/agreement';

export default {
  name: 'AgreemenTable',
  props: {
    type: {
      type: String,
      default: '1'
    }
  },
  mixins: [TableListMinix],
  components: {
    Search,
    IPagination,
    EditModal
  },
  data() {
    return {
      modalForm: 'modalForm',
      url: {
        list: 'misc-web-api/admin/agreement/list',
        singleDeleteUrl: 'misc-web-api/admin/agreement/delete'
      },
      tableColumn: [
        // { type: 'checkbox', fixed: 'left', width: 50 },
        { field: 'id', title: '协议ID', fixed: 'left', width: 100 },
        { field: 'version', title: '版本号' },
        { field: 'createUserName', title: '创建人' },
        { field: 'createTime', title: '创建时间' },
        // { field: 'updateTime', title: '最后更新时间' },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      queryParams: {
        type: 1
      },
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        }
      }
    };
  },
  watch: {
    type(newVal) {
      this.queryParams.type = newVal;
      this.iPagination.total = 0;
      this.iPagination.pageSize = 10;
      this.iPagination.currentPage = 1;
      this.$refs.Search.reset()
      this.loadData();
    }
  },
  methods: {
    handleAdd() {
      this.$refs.modalForm.form.type = this.type;
      this.$refs.modalForm.add();
    },
    handleEdit(row) {
      console.log(row);
      this.$refs.modalForm.form.type = this.type;
      this.$refs.modalForm.edit(row);
    },
    handleShowDetail(row) {
      const { id, type } = row;
      this.$router.push({
        path: '/small/program/agreement/detail',
        query: {
          id,
          type
        }
      });
    },
    handleDelete(row) {
      const { id } = row;
      const that = this;
      this.$confirm('确定删除吗?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const params = { data: { id } };
          try {
            const res = await deleteAgreement(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.$message.success('删除成功');
            that.loadData();
          } catch (e) {}
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    modalFormOk() {
      this.loadData();
    }
  }
};
</script>

<style scoped lang="less">
.user-agreement {
  .table-wrapper {
    padding: 0 20px;
  }
}
</style>
