<template>
  <el-dialog :title="title" :visible="visible" width="30%" :before-close="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalFrom" label-width="100px" size="small">
      <el-form-item prop="roleId" v-show="false">
        <el-input disable v-model="form.rankId"></el-input>
      </el-form-item>
      <el-form-item label="职级名称：" prop="rankName">
        <el-input v-model="form.rankName" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import omit from 'omit.js';
import TableModalMinix from '@/minixs/TableModalMinix';

export default {
  name: 'EditGradeModal',
  mixins: [TableModalMinix],
  data() {
    return {
      visible: false,
      title:'',
      defaultForm: {
        rankId: '',
        rankName: ''
      },
      form: {
        rankId: '',
        rankName: ''
      },
      url: {
        add: 'user-web-api/admin/rank/add',
        update: 'user-web-api/admin/rank/updateInfo'
      },
      rules: {
        rankName: [
          { required: true, message: '请输入职级名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    add() {
       this.title = '新增'
      this.visible = true;

    },
    edit(row) {
       this.title = '编辑'
      this.form.rankId = row.rankId;
      this.form.rankName = row.rankName;
      this.visible = true;
    },

    async handleOk() {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            await this.handleModalUpdate({ data: { ...this.form } });
            this.handleCancel();
          } else {
            const data = omit(this.form, ['rankId']);
            await this.handleModalAdd({ data });
          }
          this.$emit('modalFormOk');
        }
      });
      console.log('ok');
    }
  }
};
</script>

<style scoped></style>
