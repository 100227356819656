var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-drawer",
    {
      ref: "drawer",
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        direction: "rtl",
        "custom-class": "draw-wrapper",
        size: "480px",
        wrapperClosable: false,
        "before-close": _vm.handleCancel,
      },
    },
    [
      _c(
        "div",
        { staticClass: "draw-content" },
        [
          _c(
            "el-form",
            {
              ref: "modalFrom",
              staticClass: "draw-form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "140px",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false",
                    },
                  ],
                  attrs: { prop: "platformUserId" },
                },
                [
                  _c("el-input", {
                    attrs: { disable: "" },
                    model: {
                      value: _vm.form.platformUserId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "platformUserId", $$v)
                      },
                      expression: "form.platformUserId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "员工姓名：", prop: "userName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码：", prop: "phoneNumber" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "phoneNumber", $$v)
                      },
                      expression: "form.phoneNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "ERP员工编号：", prop: "erpUserId" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-key": "empno",
                      label: "empname",
                      "fetch-suggestions": _vm.querySearchStaffAsync,
                      placeholder: "请输入ERP员工名称",
                      "popper-class": "my-autocomplete",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.onErpUserChange(item)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "prdtname" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(item.empname + " - ")),
                                  ]),
                                  _c("span", [_vm._v(_vm._s(item.empno))]),
                                ]),
                                _c("div", [
                                  _c("span", { staticClass: "brdtype" }, [
                                    _vm._v(_vm._s(item.postname)),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.erpUser,
                      callback: function ($$v) {
                        _vm.erpUser = $$v
                      },
                      expression: "erpUser",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "企业微信二维码：",
                    prop: "enterpriseWechatImg",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: _vm.actionUrl,
                        "show-file-list": false,
                        "on-success": _vm.handleAvatarSuccess,
                        "before-upload": _vm.beforeAvatarUpload,
                      },
                    },
                    [
                      _vm.form.enterpriseWechatImg
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.form.enterpriseWechatImg },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱：", prop: "email" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "部门岗位：",
                    rules: {
                      required: true,
                      message: "",
                      trigger: "blur",
                    },
                  },
                },
                _vm._l(
                  _vm.form.userDepartmentList,
                  function (depAndPost, index) {
                    return _c(
                      "div",
                      {
                        key: `depAndKey${index}`,
                        staticClass: "department-form-item",
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: `depId${index}`,
                            attrs: {
                              prop:
                                "userDepartmentList." + index + ".departmentId",
                              rules: {
                                required: true,
                                message: "部门不能为空",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c("el-select-tree", {
                              staticStyle: {
                                width: "120px",
                                "margin-right": "20px",
                              },
                              attrs: {
                                placeholder: "部门",
                                filterable: "",
                                data: _vm.depList,
                                props: {
                                  label: "departmentName",
                                  child: "children",
                                  value: "departmentId",
                                },
                                "check-strictly": "",
                              },
                              model: {
                                value: depAndPost.departmentId,
                                callback: function ($$v) {
                                  _vm.$set(depAndPost, "departmentId", $$v)
                                },
                                expression: "depAndPost.departmentId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: `postId${index}`,
                            attrs: {
                              prop:
                                "userDepartmentList." + index + ".departmentId",
                              rules: {
                                required: true,
                                message: "岗位不能为空",
                                trigger: "blur",
                              },
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  width: "120px",
                                  "margin-right": "20px",
                                },
                                attrs: { placeholder: "岗位", filterable: "" },
                                model: {
                                  value: depAndPost.postId,
                                  callback: function ($$v) {
                                    _vm.$set(depAndPost, "postId", $$v)
                                  },
                                  expression: "depAndPost.postId",
                                },
                              },
                              _vm._l(_vm.postList, function (post) {
                                return _c("el-option", {
                                  key: post.postId,
                                  attrs: {
                                    value: post.postId,
                                    label: post.postName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          [
                            index === 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: { click: _vm.handleAddDepAndPost },
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                            index !== 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleRemoveDepAndPost(index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
              _c(
                "el-form-item",
                { attrs: { label: "直属上级：" } },
                [
                  _c("el-autocomplete", {
                    staticClass: "leader",
                    staticStyle: { width: "100%" },
                    attrs: {
                      id: "leader",
                      "value-key": "userName",
                      label: "userId",
                      "fetch-suggestions": _vm.querySearchUserAsync,
                      placeholder: "请输入员工姓名或手机号",
                      "popper-class": "my-autocomplete",
                    },
                    on: { select: _vm.selectLeader },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "prdtname" }, [
                              _c("span", [
                                _vm._v(_vm._s(item.userName + " - ")),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.platformUserId))]),
                            ]),
                            _c("div", [
                              _c("span", { staticClass: "brdtype" }, [
                                _vm._v(_vm._s(item.postNames)),
                              ]),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.parentUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentUserName", $$v)
                      },
                      expression: "form.parentUserName",
                    },
                  }),
                  _vm.isLeader
                    ? _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("该员工为直属上级"),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职级：", prop: "rankId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { filterable: "", value: "" },
                      model: {
                        value: _vm.form.rankId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "rankId", $$v)
                        },
                        expression: "form.rankId",
                      },
                    },
                    _vm._l(_vm.ranList, function (rank) {
                      return _c("el-option", {
                        key: rank.rankId,
                        attrs: { value: rank.rankId, label: rank.rankName },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "入职日期：", prop: "entryDate" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: { type: "date", placeholder: "选择日期" },
                    model: {
                      value: _vm.form.entryDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "entryDate", $$v)
                      },
                      expression: "form.entryDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "在职状态：", prop: "isResign" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.form.isResign,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isResign", $$v)
                        },
                        expression: "form.isResign",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "在职" } }),
                      _c("el-option", { attrs: { value: 1, label: "离职" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer" },
            [
              _c("el-button", { on: { click: _vm.handleCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.confirmLoading },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.handleSubmit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.confirmLoading ? "提交中 ..." : "确 定"))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }