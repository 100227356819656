<template>
  <div class="search">
    <el-form :model="form" class="demo-form-inline" size="small" inline ref="searchForm">
      <el-form-item label="角色名称：" class="form-item" prop="roleName">
        <el-input placeholder="请输入" class="form-item-input" v-model="form.roleName"></el-input>
      </el-form-item>
      <el-form-item label="启用状态：" class="form-item" v-model="form.isDisable" prop="isDisable">
        <el-select v-model="form.isDisable" placeholder="请选择" class="form-item-select">
          <el-option label="全部" value=""></el-option>
          <el-option label="启用" :value="0"></el-option>
          <el-option label="停用" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item class="form-item" label-width="30px">
        <el-button size="small" type="primary" icon="el-icon-search" @click="handleSearch"
          >查询</el-button
        >
        <el-button size="small" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'RoleSearch',
  components: {},
  data() {
    return {
      form: {
        isDisable: '',
        roleName: ''
      },
      fold: false,
      tags: [{ name: '标签一' }, { name: '标签二' }]
    };
  },
  methods: {
    handleSearch() {
      this.$emit('search', this.form);
    },
    handleReset() {
      this.form.isDisable = '';
      this.roleName = '';
      this.$refs.searchForm.resetFields();
      this.$emit('reset', this.from);
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
