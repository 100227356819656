var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "side-content" },
    [
      _c("el-input", {
        attrs: { placeholder: "请输入专题名称" },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            "text-align": "center",
            "margin-top": "10px",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  _vm.show = true
                },
              },
            },
            [_vm._v("新增城市专题")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "10px" } },
        _vm._l(_vm.list, function (item, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !item.unShow,
                  expression: "!item.unShow",
                },
              ],
              key: index,
              staticClass: "custom-tree-node",
              class: _vm.checkedId === item.topicId ? "is-select" : "",
              on: {
                click: function ($event) {
                  return _vm.handleNodeClick(item)
                },
              },
            },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: item.topicName,
                    disabled: item.topicName.length <= 9,
                  },
                },
                [
                  _c("span", [
                    _c("span", { staticClass: "node-name" }, [
                      _vm._v(" " + _vm._s(item.topicName) + " "),
                    ]),
                    _c("span", [_vm._v("（" + _vm._s(item.cityCount) + "）")]),
                  ]),
                ]
              ),
              _c(
                "el-dropdown",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.checkedId === item.topicId,
                      expression: "checkedId === item.topicId",
                    },
                  ],
                  on: {
                    command: (command) => _vm.handleCommand(command, item),
                  },
                },
                [
                  _c("span", { staticClass: "el-dropdown-link" }, [
                    _c("span", { staticClass: "el-icon-setting" }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "2" } }, [
                        _vm._v("编辑分类"),
                      ]),
                      _c("el-dropdown-item", { attrs: { command: "3" } }, [
                        _vm._v("删除分类"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("subject-edit", {
        attrs: { show: _vm.show, topicId: _vm.topicId },
        on: { ok: _vm.cityOk, cancel: _vm.cityCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }