var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-item" },
    [
      _c("el-button", {
        staticClass: "del-button",
        attrs: { icon: "el-icon-delete", type: "text" },
        on: { click: _vm.delItem },
      }),
      _c(
        "el-form",
        {
          ref: "FormData",
          attrs: {
            model: _vm.formData,
            rules: _vm.formRules,
            "label-width": "100px",
            size: "mini",
            "label-position": "right",
          },
        },
        [
          _c(
            "div",
            { staticClass: "form-wrap" },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "productUrl", "label-width": "0px" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "sample-choose",
                      on: { click: _vm.openChooseSampleDialog },
                    },
                    [
                      !_vm.formData.productUrl
                        ? [
                            _c("i", { staticClass: "el-icon-plus plus-icon" }),
                            _c("span", { staticClass: "tips" }, [
                              _vm._v("从样品中选择 产品封面图"),
                            ]),
                          ]
                        : _vm._e(),
                      _vm.formData.productUrl
                        ? _c("img", { attrs: { src: _vm.formData.productUrl } })
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "space1" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "定位产品：", prop: "productSetId" } },
                    [
                      _c("el-input", {
                        attrs: {
                          autocomplete: "none",
                          type: "number",
                          placeholder: "请输入产品ID",
                        },
                        on: {
                          blur: _vm.getProductDetails,
                          input: _vm.productSetIdInput,
                        },
                        model: {
                          value: _vm.formData.productSetId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productSetId", $$v)
                          },
                          expression: "formData.productSetId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "跳转详情页：", prop: "productDetailId" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.formData.productDetailId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "productDetailId", $$v)
                            },
                            expression: "formData.productDetailId",
                          },
                        },
                        _vm._l(_vm.productDetailOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "排序：", prop: "configurationSubSort" },
                    },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { min: 1, max: 10 },
                        model: {
                          value: _vm.formData.configurationSubSort,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "configurationSubSort",
                              _vm._n($$v)
                            )
                          },
                          expression: "formData.configurationSubSort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "space2" },
                _vm._l(_vm.filterList, function (item, index) {
                  return _c(
                    "el-form-item",
                    {
                      key: index,
                      attrs: { label: `${item.configurationFilterName}：` },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择", multiple: "" },
                          model: {
                            value:
                              _vm.filterCheckedObj[item.configurationFilterId],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filterCheckedObj,
                                item.configurationFilterId,
                                $$v
                              )
                            },
                            expression:
                              "filterCheckedObj[item.configurationFilterId]",
                          },
                        },
                        _vm._l(item.childList, function (item2) {
                          return _c("el-option", {
                            key: item2.configurationFilterId,
                            attrs: {
                              label: item2.configurationFilterName,
                              value: item2.configurationFilterId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("AddSampleModal", {
        ref: "addSampleModal",
        on: { chooseSampleImage: _vm.chooseSampleImage },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }