<template>
  <section class="index-config-wrapper">
    <cityProject @change="selectCityProject" />
    <left-menu
      @changeType="handleTypeChange"
      :navigationList="navigationList"
      :recommandList="recommandList"
    />
    <section class="right-edit">
      <header class="right-edit-header">
        <div class="title">设置内容</div>
        <div class="extra">
          <el-button type="text" @click="handleShowBackModal">还原到当前发布版本</el-button>
          <el-button type="default" size="small" @click="handleShowPublishModal"
            >立即发布</el-button
          >
          <el-button type="primary" size="small" @click="handleSaveModal">保存</el-button>
        </div>
      </header>

      <div :class="{ 'add-ctl': true, 'brand-ctl': editType === '5' }">
        <div class="title">
          <span v-show="editType === '4'">首页弹窗</span>
          <span v-show="editType === '3'">推荐</span>
          <span v-show="editType === '2'">导航栏</span>
          <span v-show="editType === '1'">banner轮播图</span>

          <div v-show="editType === '5'">
            <el-button
              size="small"
              v-if="editType === '5'"
              icon="el-icon-plus"
              type="primary"
              plain
              @click="addTab(editableTabsValue)"
              style="margin-top: 10px; margin-bottom: 10px"
              >添加品牌</el-button
            >
            <el-tabs
              v-if="editableTabs.length"
              v-model="editableTabsValue"
              type="card"
              closable
              @tab-remove="removeTab"
              @tab-click="tabClick"
            >
              <el-tab-pane
                :key="item.name"
                v-for="item in editableTabs"
                :label="item.title"
                :name="item.name"
              >
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div v-if="editType != '5'" class="extra">
          <el-button
            icon="el-icon-plus"
            v-show="editType === '1' || editType === '2'"
            style="width: 80px"
            type="primary"
            plain
            @click="handleAddBannerOrNavigation"
          ></el-button>
          <el-button
            v-show="editType === '3'"
            size="small"
            icon="el-icon-plus"
            type="primary"
            plain
            @click="handleAddArea"
            >增加推荐区域
          </el-button>
        </div>
      </div>

      <article
        :class="{ 'edit-content': true, 'brand-content': editType === '5' && editableTabs.length }"
        ref="editContent"
      >
        <banner-edit v-if="editType === '1'" :topicId="topicId" ref="BannerEdit" />
        <navigation-edit v-if="editType === '2'" :topicId="topicId" ref="NavigationEdit" />
        <recommend-edit v-if="editType === '3'" :topicId="topicId" ref="RecommendEdit" />
        <dialog-settings v-if="editType === '4'" :topicId="topicId" ref="DialogSettings" />
        <!-- <brand-edit
          :currentBrandIndex.sync="currentBrandIndex"
          @brandDelete="brandDelete"
          @brandNameInput="brandNameInput"
          @getBrandData="getBrandData"
          v-if="editType === '5'"
          :topicId="topicId"
          ref="BrandEdit"
        ></brand-edit> -->
      </article>
    </section>
    <preview-view
      :bannerList="bannerList"
      :navigationList="navigationList"
      :recommandList="recommandList"
      :brandList="brandList"
    />
  </section>
</template>

<script>
import LeftMenu from './components/LeftMenu.vue';
import PreviewView from './components/PreviewView.vue';
import BannerEdit from './components/BannerEdit.vue';
import NavigationEdit from './components/NavigationEdit.vue';
import RecommendEdit from './components/RecommendEdit.vue';
import cityProject from './components/cityProject.vue';
import DialogSettings from './components/DialogSettings.vue';
import BrandEdit from './components/BrandEdit.vue';

import {
  recommendAreaSave,
  backBannerOrNavigation,
  recommendAreaBack,
  getBannerOrNavigationList,
  recommendAreaList,
  deleteBrand,
  getBrandList,
  recommendAreaPublish
} from '@/api/wechat';

export default {
  name: 'IndexConfig',
  components: {
    LeftMenu,
    PreviewView,
    BannerEdit,
    NavigationEdit,
    RecommendEdit,
    cityProject,
    DialogSettings
  },
  data() {
    return {
      loading: false,
      editType: '1',
      bannerList: [],
      navigationList: [],
      recommandList: [],
      topicId: '',
      editableTabsValue: '1',
      editableTabs: [],
      tabIndex: '',
      currentBrandIndex: '',
      brandList: []
    };
  },
  watch: {
    editType: {
      handler(newVal) {
        if (newVal === '3') {
          // this.getRecommendData();
        }
      },
      immediate: true
    },
    editableTabsValue: {
      handler(val) {
        // this.currentBrandIndex = String(this.editableTabs.findIndex((el) => el.name === val));
      }
    },
    currentBrandIndex: {
      handler(val) {
        // console.log(val);
        this.editableTabsValue = this.editableTabs[val].name;
      }
    }
  },
  mounted() {
    this.tabIndex = this.editableTabs.length;
  },
  methods: {
    selectCityProject(e) {
      this.topicId = e.topicId;
      this.$nextTick(() => {
        this.getBannerList();
        this.getNavigationList();
        this.getRecommandList();
      });
    },
    handleAddArea() {
      const area = {
        title: '',
        subject: '',
        categoryId: '',
        composeType: 10,
        sortNum: 1,
        products: [],
        isSave: false
      };
      this.$refs.RecommendEdit.form.recommendDataList.push(area);
      this.$refs.RecommendEdit.toDown();
    },
    // 还原弹窗
    handleShowBackModal() {
      const that = this;
      const params = {
        data: {}
      };
      if (that.editType === '1') {
        // 还原轮播图
        params.data = {
          siteId: 1,
          topicId: this.topicId
        };
      }
      if (that.editType === '2') {
        // 还原导航栏
        params.data = {
          siteId: 2,
          topicId: this.topicId
        };
      }
      if (that.editType === '3') {
        // 还原推荐
        params.data = {
          topicId: this.topicId
        };
      }
      const message =
        '还原后将替换当前保存版本，且不可找回当前保存版本。是否确认还原到当前发布版本？';
      that
        .$confirm(message, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          if (this.editType === '1' || this.editType === '2') {
            that.handleBackNavigationOrBanner(params);
          }
          if (that.editType === '3') {
            that.areaBack(params);
          }

          if (this.editType === '4') {
            this.$refs.DialogSettings.resetPreviousData();
          }

          // 还原品牌
          if (this.editType === '5') {
            this.$refs.BrandEdit.restore();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },

    // 发布弹窗
    handleShowPublishModal() {
      const that = this;
      let message = '';
      if (that.editType === '1') {
        // 还原轮播图
        message =
          '发布后将替换当前发布版本，且不可找回当前发布版本。是否确认发布banner轮播图设置内容？';
      }
      if (that.editType === '2') {
        // 还原导航栏
        message = '发布后将替换当前发布版本，且不可找回当前发布版本。是否确认发布导航栏设置内容？';
      }
      if (that.editType === '3') {
        // 还原推荐
        message = '发布后将替换当前发布版本，且不可找回当前发布版本。是否确认发布推荐设置内容？';
      }

      if (that.editType === '4') {
        // 首页弹窗
        message = '发布后将替换当前发布版本，且不可找回当前发布版本。是否确认发布首页弹窗内容？';
      }

      if (that.editType === '5') {
        // 首页弹窗
        message = '发布后将替换当前发布版本，且不可找回当前发布版本。是否确认发布品牌内容？';
      }

      that
        .$confirm(message, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          console.log('this.editType', this.editType);
          // 发布banner
          if (this.editType === '1') {
            that.$refs.BannerEdit.publish();
          }
          // 发布导航
          if (this.editType === '2') {
            console.log(that.$refs.NavigationEdit.publish);
            that.$refs.NavigationEdit.publish();
          }
          // 发布推荐
          if (that.editType === '3') {
            that.publishRecommend();
          }

          // 发布首页弹窗
          if (this.editType === '4') {
            this.$refs.DialogSettings.publish();
          }

          // 发布品牌
          if (this.editType === '5') {
            this.$refs.BrandEdit.publish();
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 分类改变
    handleTypeChange(type) {
      this.editType = type;
    },

    // 保存弹窗
    handleSaveModal() {
      const that = this;
      let message = '';
      if (that.editType === '1') {
        // 还原轮播图
        message = '是否确认保存banner轮播图设置内容？';
      }
      if (that.editType === '2') {
        // 还原导航栏
        message = '是否确认保存导航栏设置内容？';
      }
      if (that.editType === '3') {
        // 还原推荐
        message = '是否确认保存推荐设置内容？';
      }

      if (that.editType === '4') {
        // 首页弹窗
        message = '是否确认保存首页弹窗内容？';
      }

      if (that.editType === '5') {
        // 首页弹窗
        message = '是否确认保存品牌内容？';
      }

      that
        .$confirm(message, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          that.handleSave();
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },

    // 新增 banner或者导航栏
    handleAddBannerOrNavigation() {
      if (this.editType === '1') {
        // 新增 banner
        const banner = {
          siteId: 1,
          picUrl: '',
          productId: '',
          sortNum: 1
        };
        console.log(banner);
        this.$refs.BannerEdit.form.bannerList = [...this.$refs.BannerEdit.form.bannerList, banner];
        this.$refs.BannerEdit.toDown();
      }
      if (this.editType === '2') {
        // 新增导航
        const banner = {
          siteId: 1,
          name: '',
          picUrl: '',
          productCategoryId: '',
          sortNum: 1
        };

        this.$refs.NavigationEdit.form.bannerList = [
          ...this.$refs.NavigationEdit.form.bannerList,
          banner
        ];
        this.$refs.NavigationEdit.toDown();
      }
    },

    // 保存
    handleSave() {
      if (this.editType === '1') {
        // 保存banner
        this.$refs.BannerEdit.save();
      }
      if (this.editType === '2') {
        // 保存导航
        this.$refs.NavigationEdit.save();
      }
      if (this.editType === '3') {
        // 保存推荐区域
        this.$refs.RecommendEdit.save();
      }

      if (this.editType === '4') {
        // 保存首页弹窗
        this.$refs.DialogSettings.save();
      }

      if (this.editType === '5') {
        // 保存首页弹窗
        this.$refs.BrandEdit.save();
      }
    },

    // 发布推荐
    async publishRecommend() {
      this.loading = true;
      try {
        const isValid = await new Promise((resolve) => {
          console.log(
            'this.$refs.RecommendEdit.$refs.recommendForm',
            this.$refs.RecommendEdit.$refs.recommendForm
          );
          this.$refs.RecommendEdit.$refs.recommendForm.validate((valid) => resolve(valid));
        });
        console.log('isValid', isValid);

        if (!isValid) return;

        const params = {
          data: {
            isPublish: 1,
            list: this.$refs.RecommendEdit.form.recommendDataList,
            topicId: this.topicId
            // recommendAreaId:
            //   this.recommandList.length > 0 ? this.recommandList[0].recommendAreaId : undefined
          }
        };
        const res = await recommendAreaPublish(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message.success('发布成功');
        this.$refs.RecommendEdit.getRecommendData();
      } catch (e) {
        this.loading = false;
      }
    },

    // 还原推荐区域
    async areaBack(params) {
      try {
        const res = await recommendAreaBack(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message.success('还原推荐区域成功');
        this.$refs.RecommendEdit.getRecommendData();
      } catch (e) {
        console.log(e);
      }
    },
    // 还原导航或者banner
    async handleBackNavigationOrBanner(params) {
      try {
        const res = await backBannerOrNavigation(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message.success(this.editType === '1' ? '还原轮播图成功' : '还原导航栏成功');
        if (this.editType === '1') {
          this.$refs.BannerEdit.getBannerList();
        }
        if (this.editType === '2') {
          this.$refs.NavigationEdit.getBannerList();
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 获取bannner列表
    async getBannerList() {
      try {
        const params = {
          data: {
            siteId: 1,
            status: 2, // 1发布   2 备份草稿
            topicId: this.topicId
          }
        };

        const res = await getBannerOrNavigationList(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.bannerList = res;
      } catch (e) {}
    },
    // 获取导航栏
    async getNavigationList() {
      try {
        const params = {
          data: {
            siteId: 2,
            status: 2, // 1发布   2 备份草稿
            topicId: this.topicId
          }
        };

        const res = await getBannerOrNavigationList(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.navigationList = res;
      } catch (e) {}
    },
    // 获取推荐数据
    async getRecommandList() {
      try {
        const params = {
          data: {
            status: 2,
            topicId: this.topicId
          }
        };
        const res = await recommendAreaList(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.recommandList = res;
      } catch (e) {}
    },
    // 品牌区域新增品牌tab
    addTab(targetName) {
      this.tabIndex += 1;
      const newTabName = `${this.tabIndex}`;

      this.editableTabs.push({
        title: `品牌${this.editableTabs.length + 1}`,
        name: newTabName
      });

      this.$refs.BrandEdit.form.brandData.push({
        recommendBrandName: `品牌${this.editableTabs.length}`,
        sortNum: 1,
        recommendBrandLogo: '',
        recommendAreaSaveList: [
          {
            title: '',
            categoryId: '',
            composeType: '',
            sortNum: 1,
            products: []
          }
        ],
        isSave: false
      });
      this.editableTabsValue = newTabName;
      const index = String(this.editableTabs.findIndex((el) => el.name === this.editableTabsValue));
      this.$refs.BrandEdit.scrollToBrand(index);
    },
    // 品牌区域删除品牌tab
    removeTab(targetName) {
      this.$confirm('删除会导致所有编辑内容被清空，是否确认删除？')
        .then(async () => {
          const deleteIndex = this.editableTabs.findIndex((el) => el.name === targetName);
          const id = this.$refs.BrandEdit.form.brandData[deleteIndex].recommendBrandId;

          if (id) {
            const reqObj = {
              data: {
                recommendBrandId: id
              }
            };

            const res = await deleteBrand(reqObj);

            if (!res) return;
          }

          const tabs = this.editableTabs;

          let activeName = this.editableTabsValue;
          if (activeName === targetName) {
            tabs.forEach((tab, index) => {
              if (tab.name === targetName) {
                const nextTab = tabs[index + 1] || tabs[index - 1];
                if (nextTab) {
                  activeName = nextTab.name;
                }
              }
            });
          }

          this.editableTabsValue = activeName;
          this.editableTabs = tabs.filter((tab) => tab.name !== targetName);

          this.$refs.BrandEdit.form.brandData.splice(deleteIndex, 1);

          if (!this.editableTabs.length) return;

          const index = this.editableTabs.findIndex((el) => el.name === this.editableTabsValue);
          this.$refs.BrandEdit.scrollToBrand(index);
        })
        .catch();
    },
    tabClick(tab, event) {
      const index = String(this.editableTabs.findIndex((el) => el.name === tab.name));
      this.$refs.BrandEdit.scrollToBrand(index);
    },
    brandDelete(params) {
      const targetName = this.editableTabs[params.index].name;
      this.removeTab(targetName);
    },
    brandNameInput(params) {
      this.editableTabs[params.brandIndex].title = params.val;
    }
  }
};
</script>

<style scoped lang="less">
.index-config-wrapper {
  display: flex;
  height: 100vh;
  box-sizing: border-box;
  padding-top: 20px;
  overflow: hidden;

  .left-menu {
    width: 200px;
    background: #fff;
    height: 100%;
    flex-shrink: 0;
    overflow: auto;
  }

  .preview-box {
    background: #fff;
    width: 375px;
    flex-shrink: 0;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    padding-bottom: 20px;
    margin-right: 20px;
  }

  .right-edit {
    flex: 1;
    background: #fff;
    padding: 0 20px;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    .right-edit-header {
      display: flex;
      padding: 15px 0;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eee;
      box-sizing: border-box;
    }

    .add-ctl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;

      .title {
        ::v-deep .el-tabs__header {
          margin: unset;
        }

        ::v-deep .el-tabs__item {
          // border-bottom: 1px solid white;
        }
      }

      .extra {
        display: flex;
        align-items: center;
      }
    }

    .brand-ctl {
      padding: 15px 0 0 0;
      display: unset;
    }

    .edit-content {
      height: calc(100% - 150px);
      overflow: hidden;
    }

    .brand-content {
      border: 1px solid #e0e0e0;
      border-top: unset;
    }
  }
}
</style>
