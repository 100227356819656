<template>
  <el-dialog
    :title="dialogTitle[dialogStatus]"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="30%"
    @open="onOpen"
    @closed="onClosed"
    :before-close="handleClose">
    <div>
      <el-form ref="SeatForm" :model="formData" :rules="rules" label-width="100px"
               style="width: 90%">
        <el-form-item label="坐席渠道：" prop="callAppConfigId">
          <el-select v-model="formData.callAppConfigId" placeholder="请选择渠道"
                     style="width: 100%">
            <el-option
              v-for="item in optionsCall"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="坐席名称：" prop="ccUserName">
          <el-input v-model="formData.ccUserName" placeholder="请输入坐席名称" />
        </el-form-item>
        <template v-if="formData.callAppConfigId!=='1'">
          <el-form-item label="呼叫技能组：" prop="skillGroupsId">
            <el-select v-model="formData.skillGroupsId" placeholder="请选择技能组"
                       style="width: 100%">
              <el-option
                v-for="itemList in listByQuery"
                :key="itemList.skillGroupsId"
                :label="itemList.skillGroups"
                :value="itemList.skillGroupsId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="编号：" prop="outUserNo">
            <el-input v-model="formData.outUserNo" placeholder="请输入编号" />
          </el-form-item>
          <el-form-item label="账号：" prop="outUserAccount">
            <el-input v-model="formData.outUserAccount" placeholder="请输入账号" />
          </el-form-item>
          <el-form-item label="密码：" prop="outUserPwd">
            <el-input v-model="formData.outUserPwd" placeholder="请输入密码" />
          </el-form-item>
        </template>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose">取 消</el-button>
    <el-button type="primary"
               :loading="buttonLoading"
               @click="createSeat">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import { deepClone } from '@/utils/deepClone';
import { saveCcUser } from '@/api/user';

const defaultFormData = {
  callAppConfigId: '1',//渠道
  ccUserName: '',// 坐席名称
  skillGroupsId: '',// 技能组
  outUserNo: '',// 坐席编号
  outUserAccount: '',// 坐席账号
  outUserPwd: ''// 坐席密码
};
export default {
  name: 'SeatAddOrEdit',
  props: {
    listByQuery: {
      type: Array,
      default: () => {
        return [];
      }
    },
    optionsCall: {
      type: Array,
      default: () => {
        return [];
      }
    },
    userId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: false,
      dialogStatus: 'create',
      buttonLoading: false,
      dialogTitle: {
        create: '新增坐席',
        update: '修改坐席'
      },
      formData: Object.assign({}, deepClone(defaultFormData)),
      //表单校验规则 必填
      rules: {
        callAppConfigId: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        ccUserName: [{ required: true, message: '请输入坐席名称', trigger: 'change' }],
        skillGroupsId: [{ required: true, message: '请选择呼叫技能组', trigger: 'change' }],
        outUserNo: [{ required: true, message: '请输入编号', trigger: 'change' }],
        outUserAccount: [{ required: true, message: '请输入账号', trigger: 'change' }],
        outUserPwd: [{ required: true, message: '请输入密码', trigger: 'change' }]
      }
    };
  },
  methods: {
    /**
     * 异步创建座位信息
     * 通过调用 saveCcUser 函数，根据表单数据保存用户配置，并在成功时关闭当前操作并给出成功提示
     */
    async createSeat() {
      try {
        this.buttonLoading = true;
        // 提取表单中的必要信息
        const { userId } = this;
        const {
          callAppConfigId,
          ccUserName,
          skillGroupsId,
          outUserNo,
          outUserAccount,
          outUserPwd,
          ccUserId
        } = this.formData;
        // 判断 callAppConfigId 是否为 '1'，来决定是否需要过滤掉该字段
        let saveData = this.formData.callAppConfigId === '1' ? {
          userId,
          callAppConfigId,
          ccUserName
        } : {
          userId, callAppConfigId,
          ccUserName,
          skillGroupsId,
          outUserNo,
          outUserAccount,
          outUserPwd
        };
        if (this.dialogStatus === 'update') {
          saveData = {
            ...saveData,
            ccUserId
          };
        }
        // 对表单进行验证，如果验证通过，则保存用户配置
        this.$refs.SeatForm.validate(async valid => {
          if (valid) {
            // 调用保存用户配置的函数
            const res = await saveCcUser({ data: saveData });
            // 如果保存不成功，则直接返回，不做后续操作
            if (res.success === false) {
              return;
            }
            // 执行成功后的操作：显示成功消息、关闭当前界面、调用关闭后的回调函数
            this.$message.success('操作成功');
            this.handleClose();
            this.onClosed();
            this.$emit('getList');
          } else {
            // 验证不通过，则停止后续操作
            return false;
          }
        });
      } catch (err) {
        // 捕获并记录在保存过程中可能出现的错误
        console.log(err);
      } finally {
        this.buttonLoading = false;
      }
    },

    // 修改
    updateSeat() {
      this.$refs.SeatForm.validate(valid => {
        if (valid) {
          this.handleClose();
        } else {
          return false;
        }
      });
    },

    // 弹窗关闭前的操作
    handleClose() {
      this.visible = false;
    },
    // 弹窗关闭后执行的操作
    onClosed() {
      //如果在弹窗关闭前重置了表单数据，那么就会触发校验规则，所以在弹窗关闭后重置数据
      this.formData = Object.assign({}, deepClone(defaultFormData));
    },
    // 弹窗打开执行的操作
    onOpen() {
      //弹窗关闭后读取不到refs 所以在打开的时候清除校验规则
      this.$nextTick(() => {
        this.$refs.SeatForm.clearValidate();
      });
    }
  }
};
</script>

<style scoped lang="less">

</style>
