<template>
  <el-dialog :title="title" :visible="visible" width="30%" v-loading="loading" :before-close="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalFrom" label-width="100px" size="small">
      <el-form-item prop="departmentId" v-show="false">
        <el-input disable v-model="form.departmentId"></el-input>
      </el-form-item>
       <el-form-item label="名称：" prop="dictionaryName">
        <el-input v-model="form.dictionaryName" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="键：" prop="dictionaryKey">
        <el-input v-model="form.dictionaryKey" placeholder="请输入键" />
      </el-form-item>

        <el-form-item label="值：" prop="dictionaryValue">
        <el-input v-model="form.dictionaryValue" placeholder="请输入值" />
      </el-form-item>
      <el-form-item label="排序数字：" prop="sortNum">
        <el-input-number
          v-model="form.sortNum"
          :min="1"
          :max="9999"
          style="width: 100%"
          label="描述文字"
        ></el-input-number>
        <!-- <el-input v-model="form.dictionaryValue" placeholder="请输入排序数字，数字越小越靠前" /> -->
      </el-form-item>
      <!-- <el-form-item label="字典类型：" prop="parentId" v-if="isShowSelectParent">
        <el-select
          v-model="form.parentId"
          filterable
          placeholder="请选择字典类型"
          style="width: 100%"
        >
          <el-option value="" label="请选择"></el-option>
          <el-option
            v-for="item in typeList"
            :key="item.dictionaryKey"
            :label="item.dictionaryName"
            :value="item.dictionaryId"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { omit } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';
import { getDictionaryTypeList } from '@/api/dictionary';

export default {
  name: 'EditDictionaryModal',
  components: {},
  mixins: [TableModalMinix],
  data() {
    return {
      visible: false,
      isEdit: false,
      loading: false,
      title:'',
      isShowSelectParent: true,
      defaultForm: {
        dictionaryId: '',
        dictionaryKey: '',
        dictionaryName: '',
        dictionaryValue: '',
        parentId: '',
        sortNum: 1
      },
      form: {
        dictionaryId: '',
        dictionaryKey: '',
        dictionaryName: '',
        dictionaryValue: '',
        parentId: '',
        sortNum: 1
      },
      url: {
        add: 'misc-web-api/admin/dictionary/add',
        update: 'misc-web-api/admin/dictionary/update'
      },
      rules: {
        dictionaryName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        dictionaryKey: [
          { required: true, message: '请输入键值', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        dictionaryValue: [
          { required: true, message: '请输入键值', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      typeList: [] // 字典类型
    };
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.getTypeList();
      }
    }
  },

  methods: {
    closed() {
      this.isShowSelectParent = true;
    },
    add() {
      this.isEdit = false;
      this.visible = true;
      this.title  = '新增'
    },
    edit(row) {
       this.title  = '编辑'
      this.isEdit = true;
      this.visible = true;
      this.form.dictionaryId = row.dictionaryId;
      this.form.dictionaryKey = row.dictionaryKey;
      this.form.dictionaryName = row.dictionaryName;
      this.form.dictionaryValue = row.dictionaryValue;
      this.form.parentId = row.parentId;
      this.form.sortNum = row.sortNum;
    },
    handleClose() {
      console.log('close');
    },
    // 获取分类类型
    async getTypeList() {
      this.loading = true;
      try {
        const params = { data: {} };
        const res = await getDictionaryTypeList(params);
        this.loading = false;
        this.typeList = res;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    async handleOk() {
      const that = this;
      if (this.confirmLoading) {
        return;
      }
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            const updateParams = {
              data: {
                ...this.form
              }
            };
            await this.handleModalUpdate(updateParams);
          } else {
            const formData = omit(this.form, ['dictionaryId']);
            console.log(formData);
            const params = {
              data: formData
            };
            await this.handleModalAdd(params);
          }
          this.$emit('modalFormOk');
          this.handleCancel();
        }
      });
      console.log('ok');
    }
  }
};
</script>

<style scoped></style>
