<template>
  <el-select
    v-model="selectVal"
    :placeholder="placeholder"
    size="small"
    @change="change"
    :filterable="true"
    :remote="true"
    :remote-method="requestUserList"
    ref="keySelect"
    :disabled="disabled"
    @visible-change="visibleChange"
    multiple
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      <span>用户名：{{ item.label }} </span>
      <span>电话：{{ item.phoneNumber }} </span>
      <span>部门：{{ item.departmentNames }} </span>
    </el-option>
  </el-select>
</template>

<script>
import { getUserList } from '@/api/toolkit';

export default {
  name: 'UserSelect',
  props: {
    arr: {
      type: Array,
      default: () => []
    },
    val: {
      type: Array,
      default: () => []
    },
    valArrStr: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '姓名/电话搜索'
    }
  },
  watch: {
    val: {
      immediate: true,
      handler(newVal) {
        this.selectVal = newVal;
      }
    }
  },
  data() {
    return {
      selectVal: '',
      options: []
    };
  },
  mounted() {
    if (this.arr.length > 0 && this.val.length > 0) this.options = this.arr;
  },
  methods: {
    change() {
      this.$emit('update:val', this.selectVal);
      this.$emit('update:valArrStr', this.selectVal.join(','));

      this.$emit(
        'update:arr',
        this.options.filter((el) => this.selectVal.includes(el.value))
      );
    },
    async requestUserList(query) {
      let userNameOrPhoneNumber = query;
      const { data: res } = await getUserList({
        pageNumber: 1,
        pageSize: 150,
        data: { userNameOrPhoneNumber }
      });

      const currentUser = {
        userName: '当前登录人',
        userId: -1,
        departmentNames: '无',
        phoneNumber: '无'
      };

      this.options = [...[currentUser], ...(res.content || [])].map((el) => ({
        label: el.userName,
        value: el.userId,
        departmentNames: el.departmentNames,
        phoneNumber: el.phoneNumber
      }));
    },
    visibleChange(visible) {
      if (visible) this.requestUserList('');
    }
  }
};
</script>

<style></style>
