var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "order-config-wrapper" },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { inline: "", model: _vm.form } },
        [
          _vm._l(_vm.form.configList, function (config, index) {
            return _c(
              "div",
              { key: config.dictionaryId },
              [
                _c("common-title", { attrs: { title: config.title } }),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item",
                    staticStyle: { "margin-right": "10px" },
                    attrs: {
                      prop: "configList." + index + ".dictionaryValue",
                      "label-width": "180",
                      label: `${config.label}：`,
                      rules: {
                        required: true,
                        message: "请输入",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c("el-input-number", {
                      attrs: { type: "number", min: 1 },
                      model: {
                        value: config.dictionaryValue,
                        callback: function ($$v) {
                          _vm.$set(config, "dictionaryValue", $$v)
                        },
                        expression: "config.dictionaryValue",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "form-footer" },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.confirmLoading },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("提交")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }