<template>
  <div class="content-edit">
    <div class="submit-bar">
      <span class="title">设置内容</span>

      <div class="submit-btns">
        <el-button size="mini" @click="submitContent(0)">保存</el-button>
        <el-button type="primary" size="mini" @click="submitContent(1)">发布</el-button>
      </div>
    </div>

    <el-divider></el-divider>

    <div class="submit-bar">
      <span class="sub-title">{{ treeNodeItem.configurationName }}</span>

      <div class="submit-btns">
        <el-button type="text" icon="el-icon-plus" size="small" @click="addItemClick">
          添加
        </el-button>
      </div>
    </div>

    <template v-if="list.length > 0">
      <ContentItem
        v-for="(item, index) in list"
        :key="item.configurationSubId"
        :item="item"
        :ref="`contentItem${item.configurationSubId}`"
        @delItemClick="delItemClick(item, index)"
        :filterList="filterList"
      />
    </template>

    <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>
import ContentItem from './ContentItem.vue';
import { getConfigurationFilters } from '@/api/miniprogramProducts.js';

import {
  getConfigurationContentList,
  saveConfigurationContent,
  delConfigurationContent
} from '@/api/miniprogramProducts';

export default {
  components: {
    ContentItem
  },
  props: {
    treeNodeItem: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    treeNodeItem: {
      deep: true,
      immediate: true,
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.getList();
          this.getFilters();
        }
      }
    }
  },
  data() {
    return {
      list: [],
      filterList: []
    };
  },
  methods: {
    async getList() {
      try {
        const res = await getConfigurationContentList({
          data: {
            configurationId: this.treeNodeItem.configurationId
          }
        });

        this.list = res.data || [];
      } catch (err) {
        console.log(err);
      }
    },
    delItemClick(item, index) {
      this.$confirm('是否继续删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          if (item.localPush) {
            this.list.splice(index, 1);
            this.$message.success('删除成功');
            return;
          }

          const res = await delConfigurationContent({
            data: { configurationSubId: item.configurationSubId }
          });

          if (res.success) {
            this.$message.success(res.description || '删除成功');
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err || '');
        });
    },
    addItemClick() {
      this.list.unshift({ configurationSubId: Date.now(), localPush: true });
    },
    async submitContent(isPublish) {
      const promiseItemArr = [];

      Object.keys(this.$refs).forEach((key) => {
        if (key.includes('contentItem') && this.$refs[key][0]) {
          promiseItemArr.push(this.$refs[key][0].validAndGetItem());
        }
      });

      if (promiseItemArr.length == 0) return;

      const resArr = await Promise.all(promiseItemArr);
      if (resArr.some((el) => !el[0])) return;

      const res = await saveConfigurationContent({
        data: {
          saveList: resArr.map((el) => el[1]),
          isPublish,
          configurationId: this.treeNodeItem.configurationId
        }
      });

      if (res.success) {
        this.$message.success(res.description || '操作成功');
        this.getList();
      }
    },
    //外部调用
    async getFilters() {
      try {
        const res = await getConfigurationFilters({ data: {} });

        this.filterList = res.data || [];
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-edit {
  height: 100%;
  overflow-y: auto;
  flex: 1;
  color: #333;
  flex-shrink: 0;

  .submit-bar {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    overflow: hidden;

    .title {
      font-size: 16px;
      font-weight: 500;
    }

    .sub-title {
      font-size: 14px;
      font-weight: bold;
    }

    .submit-btns {
      display: inline-flex;
    }
  }

  ::v-deep(.el-divider--horizontal) {
    margin: 0 !important;
  }
}
</style>
