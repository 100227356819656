<template>
  <section class="left-menu">
    <div class="title">首页内容整理</div>
    <ul class="content">
      <li :class="{ active: type === '1' }" @click="handleClick('1')">轮播图</li>
      <!-- <li :class="{ active: type === '2' }" @click="handleClick('2')">导航栏</li> -->
      <li :class="{ active: type === '3' }" @click="handleClick('3')">推荐</li>
      <li :class="{ active: type === '4' }" @click="handleClick('4')">首页弹窗</li>
      <!-- <li :class="{ active: type === '5' }" @click="handleClick('5')">品牌</li> -->
    </ul>
  </section>
</template>

<script>
export default {
  name: 'LeftMenu',
  data() {
    return {
      type: '1'
    };
  },
  methods: {
    handleClick(val) {
      this.type = val;
      this.$emit('changeType', val);
    }
  }
};
</script>

<style scoped lang="less">
.left-menu {
  .title {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    padding: 16px 16px;
  }
  .content {
    li {
      &:hover {
        background: #e9effc;
      }
      cursor: pointer;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
      padding-left: 20px;
      &.active {
        background: #e9effc;
      }
    }
  }
}
</style>
