var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "keySelect",
      attrs: {
        placeholder: _vm.placeholder,
        size: "small",
        disabled: _vm.disabled,
        multiple: "",
      },
      on: { change: _vm.change, "visible-change": _vm.visibleChange },
      model: {
        value: _vm.selectVal,
        callback: function ($$v) {
          _vm.selectVal = $$v
        },
        expression: "selectVal",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c("el-option", {
        key: item.value,
        attrs: { label: item.label, value: item.value },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }