var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "department-wrapper" }, [
    _c(
      "div",
      { staticClass: "side" },
      [
        _c("department-tree", {
          on: { selectDepartment: _vm.selectDepartment },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "department-table" },
      [
        _vm.currentDepartment
          ? _c("department-info", {
              attrs: { departmentId: _vm.currentDepartment.departmentId },
            })
          : _vm._e(),
        _c("department-table", {
          attrs: { departmentId: _vm.currentDepartment.departmentId },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }