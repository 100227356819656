<template>
  <div class="source-channel">
    <div class="choosed-tag">
      <el-popover
        placement="bottom"
        width="400"
        trigger="click"
        @hide="hidePopover"
        @show="showPopover"
      >
        <el-tag
          :closable="false"
          size="small"
          :disable-transitions="true"
          slot="reference"
          style="cursor: pointer"
        >
          +选择来源渠道
        </el-tag>

        <div class="source-channel-el-select-wrap">
          <el-select-tree
            :data="channelList"
            :props="{
              label: 'channelTypeName',
              child: 'children',
              value: 'channelTypeId'
            }"
            size="mini"
            check-strictly
            v-model="channelTypeId"
            style="width: 200px"
            accordion
            ref="tree"
            placeholder="全部"
            @change="channelTypeChange"
            :disabled="disabled"
          />

          <el-select
            size="mini"
            v-model="channelIds"
            placeholder="全部"
            style="width: 100%"
            multiple
            :disabled="disabled"
          >
            <el-option
              v-for="(item, index) in channeMainlList"
              :key="index"
              :label="item.channelName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </el-popover>

      <el-tag
        :closable="!disabled"
        v-for="item in choosedTags"
        :key="item.id"
        @close="removeTag(item)"
        size="small"
        :disable-transitions="true"
      >
        {{ item.channelName }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import { getChannelTree, getChannel } from '@/api/toolkit';

export default {
  name: 'SourceChannel',
  props: {
    val: {
      type: Array,
      default: () => []
    },
    arr: {
      type: Array,
      default: () => []
    },
    valArrStr: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      channelList: [],
      channeMainlList: [],
      channelIds: [],
      channelTypeId: '',
      choosedTags: [],
      isChannelTypeChange: false
    };
  },
  watch: {
    channelIdsCopy: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal && !this.isChannelTypeChange) {
          oldVal.forEach((el) => {
            //必须同时在channeMainlList和choosedTags才能删除
            const item = this.channeMainlList.find((x) => x.id == el);
            if (!item) return;
            const idx = this.choosedTags.findIndex((x) => x.id == el);
            if (idx > -1) this.choosedTags.splice(idx, 1);
          });
        }

        newVal &&
          newVal.forEach((el) => {
            const item = this.channeMainlList.find((x) => x.id == el);
            if (!item) return;
            const idx = this.choosedTags.findIndex((x) => x.id == el);
            if (idx == -1) this.choosedTags.push(item);
          });

        this.isChannelTypeChange = false;

        const arr = this.choosedTags.map((d) => ({
          channelTypeId: d.channelTypeId,
          id: d.id,
          channelName: d.channelName
        }));

        const ids = arr.map((el) => el.id);
        this.$emit('update:val', ids);
        this.$emit('update:arr', arr);
        this.$emit('update:valArrStr', ids.join(','));
      }
    },
    arr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.choosedTags = [...(newVal || [])];
      }
    }
  },
  computed: {
    channelIdsCopy() {
      return this.channelIds.slice();
    }
  },
  methods: {
    channelTypeChange(e) {
      //回显当前属于channelTypeId的渠道时，标记不应该执行删除逻辑
      this.isChannelTypeChange = true;

      setTimeout(() => {
        //回显当前属于channelTypeId的渠道
        this.channelIds = this.choosedTags
          .filter((el) => el.channelTypeId == this.channelTypeId)
          .map((x) => x.id);

        this.getChannelList(e);
      }, 10);
    },
    getChannelList(channelTypeId) {
      getChannel({
        data: {
          channelTypeId
        }
      }).then((res) => {
        this.channeMainlList = res.data || [];
      });
    },
    async getTypeList() {
      try {
        const res = await getChannelTree({ data: {} });
        this.channelList = res.data || [];
      } catch (e) {
        console.log(e);
      }
    },
    removeTag(item) {
      const idx = this.choosedTags.findIndex((x) => x.id == item.id);
      if (idx > -1) this.choosedTags.splice(idx, 1);

      const idx2 = this.channelIds.findIndex((x) => x == item.id);
      if (idx2 > -1) this.channelIds.splice(idx2, 1);
    },
    hidePopover() {
      this.channelIds = [];
      this.channelTypeId = '';
      this.isChannelTypeChange = true;
    },
    showPopover() {
      this.getTypeList();
    },
    resetAll() {
      this.channelList = [];
      this.channeMainlList = [];
      this.channelIds = [];
      this.channelTypeId = '';
      this.choosedTags = [];
      this.isChannelTypeChange = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.source-channel {
  max-height: 120px;
  overflow-y: auto;

  .choosed-tag {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;

    > span {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>

<style lang="scss">
.source-channel-el-select-wrap {
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
  }
}
</style>
