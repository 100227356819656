var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "用户选择",
        visible: _vm.visible,
        width: "1100px",
        size: "small",
        "append-to-body": "",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "el-form",
        {
          staticClass: "search",
          attrs: { model: _vm.form, inline: "", size: "small" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "部门：" } },
            [
              _c("el-select-tree", {
                staticStyle: { width: "120px", "margin-right": "20px" },
                attrs: {
                  data: _vm.depList,
                  props: {
                    label: "departmentName",
                    child: "children",
                    value: "departmentId",
                  },
                  "check-strictly": "",
                },
                model: {
                  value: _vm.form.departmentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "departmentId", $$v)
                  },
                  expression: "form.departmentId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "岗位：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { "min-width": "80px" },
                  attrs: {
                    placeholder: "岗位",
                    multiple: "",
                    filterable: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.form.post,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "post", $$v)
                    },
                    expression: "form.post",
                  },
                },
                _vm._l(_vm.postList, function (postItem) {
                  return _c("el-option", {
                    key: postItem.postId,
                    attrs: { value: postItem.postId, label: postItem.postName },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "员工名称：" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入姓名" },
                model: {
                  value: _vm.form.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userName", $$v)
                  },
                  expression: "form.userName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码：" } },
            [
              _c("el-input", {
                attrs: { size: "small", placeholder: "请输入手机号" },
                model: {
                  value: _vm.form.phoneNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phoneNumber", $$v)
                  },
                  expression: "form.phoneNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
              _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "UserSelectTable",
              staticStyle: { width: "100%" },
              attrs: {
                "row-key": "userId",
                data: _vm.dataSource,
                border: "",
                size: "small",
              },
              on: { "current-change": _vm.handleCurrentChange },
            },
            [
              _c("el-table-column", {
                attrs: { width: "55", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: {
                              value: _vm.checkedId,
                              label: row.platformUserId,
                            },
                          },
                          [_c("span")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "userId", label: "员工ID", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: "员工名称",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phoneNumber",
                  label: "手机号码",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "departmentNames",
                  label: "部门",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "postNames",
                  label: "岗位",
                  "show-overflow-tooltip": "",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-footer" },
            [
              _c("i-pagination", {
                attrs: {
                  "page-size": _vm.iPagination.pageSize,
                  "current-page": _vm.iPagination.currentPage,
                  "page-sizes": [10, 20, 30, 40],
                  layout: "total,prev, pager, next ,sizes,jumper",
                  total: _vm.iPagination.total,
                },
                on: {
                  "current-change": _vm.handlePageChange,
                  "size-change": _vm.handleSizeChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }