import requestExt from '@/utils/request';
const request = (params) => requestExt(Object.assign(params, { allResult: true }));

export function getConfigurationFilters(data) {
  return request({
    url: 'misc-web-api/admin/configuration/filter/list',
    method: 'POST',
    data
  });
}

export function saveConfigurationFilters(data) {
  return request({
    url: 'misc-web-api/admin/configuration/filter/save',
    method: 'POST',
    data
  });
}

export function delConfigurationFilters(data) {
  return request({
    url: 'misc-web-api/admin/configuration/filter/delete',
    method: 'POST',
    data
  });
}

export function getConfigurationList(data) {
  return request({
    url: 'misc-web-api/admin/configuration/management/list',
    method: 'POST',
    data
  });
}

export function saveConfiguration(data) {
  return request({
    url: 'misc-web-api/admin/configuration/management/save',
    method: 'POST',
    data
  });
}

export function delConfiguration(data) {
  return request({
    url: 'misc-web-api/admin/configuration/management/delete',
    method: 'POST',
    data
  });
}

export function getConfigurationContentList(data) {
  return request({
    url: 'misc-web-api/admin/configuration/sub/list',
    method: 'POST',
    data
  });
}

export function saveConfigurationContent(data) {
  return request({
    url: 'misc-web-api/admin/configuration/sub/save',
    method: 'POST',
    data
  });
}

export function delConfigurationContent(data) {
  return request({
    url: 'misc-web-api/admin/configuration/sub/delete',
    method: 'POST',
    data
  });
}

export function getProductDetailList(data) {
  return request({
    url: 'product-web-api/admin/product/list',
    method: 'POST',
    data
  });
}
