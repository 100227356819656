var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advertisement-item" },
    [
      _c("div", { staticClass: "item-row" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "item-row_container" },
          [
            !_vm.info.picUrl
              ? _c("div", { staticClass: "upload-container" }, [
                  _c("input", {
                    ref: "fileInput",
                    staticClass: "file-input",
                    attrs: { type: "file", accept: "image/*" },
                    on: { change: _vm.fileChange },
                  }),
                  _c("i", { staticClass: "el-icon-plus plus-icon" }),
                  _c("span", { staticClass: "upload-text" }, [
                    _vm._v("上传图片"),
                  ]),
                ])
              : _vm._e(),
            _vm.info.picUrl
              ? _c("el-image", {
                  staticClass: "banner",
                  attrs: { src: _vm.info.picUrl, fit: "cover" },
                })
              : _vm._e(),
            _vm.info.picUrl
              ? _c("i", {
                  staticClass: "el-icon-remove remove-icon",
                  on: { click: _vm.deleteImage },
                })
              : _vm._e(),
            _c("div", { staticClass: "tips" }, [_vm._v("建议尺寸704*180px")]),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "item-row" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "item-row_container" },
          [
            _c("el-input", {
              attrs: { size: "small", placeholder: "请填写跳转链接" },
              model: {
                value: _vm.info.link,
                callback: function ($$v) {
                  _vm.$set(_vm.info, "link", $$v)
                },
                expression: "info.link",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "item-row" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "item-row_container" },
          [
            _c("el-input-number", {
              attrs: { min: 1, max: 4, size: "small" },
              model: {
                value: _vm.info.sort,
                callback: function ($$v) {
                  _vm.$set(_vm.info, "sort", $$v)
                },
                expression: "info.sort",
              },
            }),
            _c("div", { staticClass: "tips" }, [_vm._v("仅支持1-4的整数")]),
          ],
          1
        ),
      ]),
      _c("el-button", {
        staticClass: "delete-btn",
        attrs: { icon: "el-icon-delete", size: "small" },
        on: { click: _vm.deleteItem },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-row_name" }, [
      _c("span", { staticClass: "symbol" }, [_vm._v("*")]),
      _vm._v("图片："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-row_name" }, [
      _c("span", { staticClass: "symbol" }, [_vm._v("*")]),
      _vm._v("链接："),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item-row_name" }, [
      _c("span", { staticClass: "symbol" }, [_vm._v("*")]),
      _vm._v("排序："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }