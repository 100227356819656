<template>
  <div class="miniprogram-products">
    <ManageOptions @treeClick="treeClick" @filtersRefresh="filtersRefresh" />
    <ContentEdit v-if="canOperate" ref="ContentEdit" :treeNodeItem="treeNodeItem" />

    <div class="nothing-wrap" v-if="!canOperate">
      <el-empty description="当前分类不可操作"></el-empty>
    </div>
  </div>
</template>

<script>
import ContentEdit from './components/ContentEdit.vue';
import ManageOptions from './components/ManageOptions.vue';

export default {
  components: {
    ManageOptions,
    ContentEdit
  },
  data() {
    return {
      canOperate: false,
      treeNodeItem: ''
    };
  },
  methods: {
    treeClick(canOperate, data) {
      this.canOperate = canOperate;
      this.treeNodeItem = data;
    },
    filtersRefresh() {
      this.$refs.ContentEdit && this.$refs.ContentEdit.getFilters();
    }
  }
};
</script>

<style lang="scss" scoped>
.miniprogram-products {
  display: flex;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
  padding: 23px;

  .nothing-wrap {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 100px 0;
  }
}
</style>
