var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "dictionary-wrapper" }, [
    _c(
      "div",
      { staticClass: "side" },
      [_c("dictionary-type-tree", { on: { nodeSelect: _vm.nodeSelect } })],
      1
    ),
    _c(
      "div",
      {
        staticClass: "dictionary-main",
        staticStyle: { "padding-top": "16px" },
      },
      [_c("dictionary-table", { attrs: { parentId: _vm.parentId } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }