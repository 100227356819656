var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.type,
            callback: function ($$v) {
              _vm.type = $$v
            },
            expression: "type",
          },
        },
        [_c("el-tab-pane", { attrs: { label: "用户协议", name: "1" } })],
        1
      ),
      _c("agreement-table", {
        ref: "AgreementTable",
        attrs: { type: _vm.type },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }