<template>
  <div class="common-header">
    <div class="common-header-content">
      <div class="title">{{title}}</div>
      <div class="extra">
        <slot name="extra"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:'标题',
      required:true
    }
  },
  name: "CommonHeader"
}
</script>

<style scoped lang="less">
.common-header{
  position: relative;
  &:before{
    position: absolute;
    left: 0;
    top: 50%;
    content: "";
    width: 3px;
    height: 22px;
    background: #2861E2;
    transform: translateY(-50%);
    border-radius: 0px 3px 3px 0px;
  }
  padding: 0 20px;
  .common-header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E0E0E0;
    padding: 20px 0 ;
    .title{
      font-size: 16px;
      color: #000;
      font-weight: 500;
    }
  }
}
</style>
