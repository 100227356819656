<template>
  <div class="brand-edit" v-loading="loading">
    <el-form label-width="180px" :model="form" ref="brandForm">
      <div
        class="all-brand"
        @scroll.passive="brandScroll"
        style="overflow: auto; height: 80vh; position: relative"
      >
        <div
          class="brand-area"
          v-for="(brandArea, index) in form.brandData"
          :key="`brandArea${index}`"
          :id="'brand' + index"
        >
          <div class="brand-title">
            <span class="bold-title">品牌信息</span>

            <!-- <el-popconfirm
              icon=""
              title="删除会导致所有编辑内容被清空，是否确认删除？"
              @confirm="brandDelete(index)"
            >
              <el-button slot="reference" type="info" size="mini" icon="el-icon-delete"></el-button>
            </el-popconfirm> -->

            <el-button
              @click="brandDelete(index, brandArea.recommendBrandId)"
              type="info"
              size="mini"
              icon="el-icon-delete"
            ></el-button>
          </div>

          <el-form-item
            label="品牌名称："
            :prop="'brandData.' + index + '.recommendBrandName'"
            :rules="rules.recommendBrandName"
          >
            <el-input
              style="max-width: 210px"
              placeholder="请填写品牌名称"
              maxlength="10"
              show-word-limit
              v-model="brandArea.recommendBrandName"
              @input="brandNameInput($event, index)"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="排序："
            :prop="'brandData.' + index + '.sortNum'"
            :rules="rules.sortNum"
          >
            <el-input-number v-model="brandArea.sortNum" :min="1"></el-input-number>
          </el-form-item>

          <el-form-item
            label="品牌头图："
            :prop="'brandData.' + index + '.recommendBrandLogo'"
            :rules="rules.picture"
          >
            <el-upload
              class="brand-cover-uploader"
              accept=".jpg, .png, .jpeg"
              :action="actionUrl"
              :show-file-list="false"
              :on-success="(res, file) => brandCoverSuccess(res, file, index)"
              :before-upload="beforeUpload"
            >
              <img
                v-if="brandArea.recommendBrandLogo"
                :src="brandArea.recommendBrandLogo"
                class="brand-cover"
              />
              <i v-else class="el-icon-plus brand-cover-uploader-icon"></i>
            </el-upload>

            <div class="upload-tip">
              <i class="el-icon-info"></i>
              只能上传jpg/png格式文件，且不超过500kb，建议尺寸1053*345px
            </div>
          </el-form-item>

          <div
            class="brand-recommend"
            v-for="(recommendItem, rindex) in brandArea.recommendAreaSaveList"
            :key="rindex"
            :id="'brand' + index + '-' + 'recommend' + rindex"
          >
            <div class="recommend-title">
              <span class="bold-title">推荐区域{{ rindex + 1 }}</span>

              <span>
                <el-button
                  @click="addRecommend(index, rindex)"
                  v-if="brandArea.recommendAreaSaveList.length - 1 === rindex"
                  size="mini"
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  >增加推荐区域</el-button
                >

                <el-popconfirm
                  icon=""
                  title="删除会导致所有编辑内容被清空，是否确认删除？"
                  @confirm="deleteRecommend(index, rindex)"
                  style="margin-left: 10px"
                >
                  <el-button
                    slot="reference"
                    v-if="brandArea.recommendAreaSaveList.length !== 1"
                    type="info"
                    size="mini"
                    icon="el-icon-delete"
                  ></el-button>
                </el-popconfirm>
              </span>
            </div>

            <el-form-item
              label="标题："
              :prop="'brandData.' + index + '.recommendAreaSaveList.' + rindex + '.title'"
              :rules="rules.title"
            >
              <el-input
                placeholder="请填写标题"
                maxlength="15"
                show-word-limit
                style="max-width: 300px"
                v-model="recommendItem.title"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="套系产品分类id："
              :prop="'brandData.' + index + '.recommendAreaSaveList.' + rindex + '.categoryId'"
              :rules="rules.categoryId"
            >
              <el-input
                placeholder="请填写套系产品分类ID"
                style="max-width: 420px"
                v-model="recommendItem.categoryId"
              ></el-input>
            </el-form-item>

            <el-form-item
              label="推荐样式："
              :prop="'brandData.' + index + '.recommendAreaSaveList.' + rindex + '.composeType'"
              :rules="rules.composeType"
            >
              <div style="overflow: auto" class="recommend-list">
                <el-row style="min-width: 600px">
                  <el-col
                    :md="8"
                    :sm="8"
                    :lg="4"
                    v-for="(layout, sIndex) in layoutList"
                    :key="`s${sIndex}`"
                  >
                    <el-radio-group
                      v-model="recommendItem.composeType"
                      @change="(e) => handleStyleChange(e, index, rindex)"
                    >
                      <div class="layout-wrapper">
                        <we-chat-style-wrapper :index="parseInt(layout.dictionaryValue)" />

                        <el-radio :label="Number(layout.dictionaryValue)">{{
                          layout.dictionaryName
                        }}</el-radio>
                      </div>
                    </el-radio-group>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>

            <el-form-item
              label="排序："
              :prop="'brandData.' + index + '.recommendAreaSaveList.' + rindex + '.sortNum'"
              :rules="rules.sortNum"
            >
              <el-input-number :min="1" v-model="recommendItem.sortNum"></el-input-number>
            </el-form-item>

            <div
              class="recommend-product"
              v-for="(productItem, pindex) in recommendItem.products"
              :key="pindex"
            >
              <el-button
                class="product-delete-button"
                type="info"
                size="mini"
                icon="el-icon-delete"
                @click="deleteProduct(index, rindex, pindex)"
                v-if="[1, 8, 7].includes(recommendItem.composeType)"
              ></el-button>

              <el-form-item
                label="产品图片："
                :prop="
                  'brandData.' +
                  index +
                  '.recommendAreaSaveList.' +
                  rindex +
                  '.products.' +
                  pindex +
                  '.picUrl'
                "
                :rules="rules.picture"
              >
                <el-upload
                  class="product-cover-uploader"
                  accept=".jpg, .png, .jpeg"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="(res, file) => productCoverSuccess(res, file, index, rindex, pindex)"
                  :before-upload="beforeUpload"
                >
                  <img v-if="productItem.picUrl" :src="productItem.picUrl" class="product-cover" />
                  <i v-else class="el-icon-plus product-cover-uploader-icon"></i>
                </el-upload>

                <div class="upload-tip">
                  <i class="el-icon-info"></i>
                  只能上传jpg/png格式文件，且不超过500kb，建议尺寸1053*345px
                </div>
              </el-form-item>

              <el-form-item
                label="产品标题："
                :prop="
                  'brandData.' +
                  index +
                  '.recommendAreaSaveList.' +
                  rindex +
                  '.products.' +
                  pindex +
                  '.productName'
                "
                :rules="
                  recommendItem.composeType == 7
                    ? { required: true, message: '请填写标题', trigger: 'blur' }
                    : {}
                "
              >
                <el-input
                  style="max-width: 420px"
                  placeholder="请输入产品标题"
                  v-model="productItem.productName"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="链接："
                :prop="
                  'brandData.' +
                  index +
                  '.recommendAreaSaveList.' +
                  rindex +
                  '.products.' +
                  pindex +
                  '.url'
                "
                :rules="rules.url"
              >
                <el-input
                  style="max-width: 420px"
                  placeholder="请输入链接"
                  v-model="productItem.url"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="排序："
                :prop="
                  'brandData.' +
                  index +
                  '.recommendAreaSaveList.' +
                  rindex +
                  '.products.' +
                  pindex +
                  '.sortNum'
                "
                :rules="rules.picture"
              >
                <el-input-number v-model="productItem.sortNum" :min="1"></el-input-number>
              </el-form-item>
            </div>

            <div class="bottom-button" v-if="[1, 8, 7].includes(recommendItem.composeType)">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-plus"
                @click="addProduct(index, rindex)"
                >增加推荐产品</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import WeChatStyleWrapper from '@/components/wechat-style/index.vue';
import { getDireactionListByKey } from '@/api/dictionary';
import { getBrandList, publishOrSaveBrand, restoreBrand } from '@/api/wechat';

export default {
  components: {
    WeChatStyleWrapper
  },
  props: {
    topicId: {
      type: String,
      default: ''
    },
    currentBrandIndex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        brandData: []
      },
      layoutList: [],
      isClick: false,
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`,
      loading: false,
      rules: {
        recommendBrandName: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
        sortNum: [{ required: true, message: '请排序', trigger: 'blur' }],
        picture: [{ required: true, message: '请选择图片', trigger: 'blur' }],
        title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        categoryId: [{ required: true, message: '请输入套系产品分类ID', trigger: 'blur' }],
        composeType: [{ required: true, message: '请选择样式', trigger: 'blur' }],
        url: [{ required: true, message: '请输入链接', trigger: 'blur' }]
      }
    };
  },
  watch: {
    topicId: {
      immediate: true,
      handler(val) {
        this.getBrandDetail();
      }
    }
    // currentBrandIndex: {
    //   handler(val) {
    //     // console.log(val);
    //     // this.$nextTick(() => {
    //     //   document.querySelector(`#brand${val}`).scrollIntoView({ behavior: 'smooth' });
    //     // });
    //   }
    // }
  },
  mounted() {
    this.getLayouts();
  },
  methods: {
    async getBrandDetail() {
      this.loading = true;
      const reqObj = {
        data: {
          topicId: this.topicId
        }
      };

      const res = await getBrandList(reqObj);

      if (res) {
        res.forEach((el) => {
          el['recommendAreaSaveList'] = JSON.parse(JSON.stringify(el.list));
          delete el['list'];
        });
      }

      this.form.brandData = res || [];

      this.$emit('getBrandData', this.form.brandData);

      this.loading = false;
    },
    async getLayouts() {
      try {
        const params = {
          data: {
            dictionaryKey: 'introduction_sample'
          }
        };
        const res = await getDireactionListByKey(params);

        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        this.layoutList = res;
      } catch (e) {}
    },
    handleStyleChange(radioVal, index, rindex) {
      const productsLength = this.form.brandData[index].recommendAreaSaveList[rindex].products
        ? this.form.brandData[index].recommendAreaSaveList[rindex].products.length
        : 0;

      if (radioVal === 1 || radioVal === 8 || radioVal === 7) {
        return;
      }
      if (radioVal === 2) {
        this.dynamicRecommendProduct(productsLength, 2, index, rindex);
      }
      if (radioVal === 3) {
        this.dynamicRecommendProduct(productsLength, 3, index, rindex);
      }
      if (radioVal === 4) {
        this.dynamicRecommendProduct(productsLength, 4, index, rindex);
      }
      if (radioVal === 5) {
        this.dynamicRecommendProduct(productsLength, 4, index, rindex);
      }
      if (radioVal === 6) {
        this.dynamicRecommendProduct(productsLength, 5, index, rindex);
      }
    },
    // number：现有产品数量 total：目标数量 index：品牌区域索引 rindex：推荐区域索引
    dynamicRecommendProduct(number, total, index, rindex) {
      if (number - total > 0) {
        this.form.brandData[index].recommendAreaSaveList[rindex].products.splice(
          total - 1,
          number - total
        );
      } else {
        for (let i = 0; i < total - number; i += 1) {
          const item = {
            picUrl: '',
            productName: '',
            url: '',
            sortNum: ''
          };
          this.form.brandData[index].recommendAreaSaveList[rindex].products.push(item);
        }
      }
    },
    brandScroll(e) {
      if (this.isClick || !this.form.brandData.length) return;

      const children = Array.from(document.querySelector('.all-brand').children);
      const scrollTop = document.querySelector('.all-brand').scrollTop;

      const currentBrandIndex = children.reverse().findIndex((el) => scrollTop >= el.offsetTop);

      this.$emit(
        'update:currentBrandIndex',
        String(currentBrandIndex > -1 ? children.length - 1 - currentBrandIndex : 0)
      );
    },
    scrollToBrand(index) {
      this.isClick = true;

      this.$nextTick(() => {
        document.querySelector(`#brand${index}`).scrollIntoView();
      });

      setTimeout(() => {
        this.isClick = false;
      }, 500);
    },
    brandDelete(index, id) {
      this.$emit('brandDelete', { index });
    },
    brandNameInput(e, index) {
      this.$emit('brandNameInput', { val: e, brandIndex: index });
    },
    addRecommend(brandIndex, rindex) {
      this.form.brandData[brandIndex].recommendAreaSaveList.push({
        title: '',
        categoryId: '',
        composeType: '',
        products: []
      });

      this.$nextTick(() => {
        document.querySelector(`#brand${brandIndex}-recommend${rindex + 1}`).scrollIntoView();
      });
    },
    deleteRecommend(brandIndex, rindex) {
      this.form.brandData[brandIndex].recommendAreaSaveList.splice(rindex, 1);
    },
    deleteProduct(brandIndex, rindex, pindex) {
      this.form.brandData[brandIndex].recommendAreaSaveList[rindex].products.splice(pindex, 1);
    },
    addProduct(brandIndex, rindex) {
      this.form.brandData[brandIndex].recommendAreaSaveList[rindex].products.push({
        picUrl: '',
        productName: '',
        url: '',
        sortNum: ''
      });
    },
    brandCoverSuccess(res, file, index) {
      if (res.success === false) {
        this.$message.error('上传图片失败');
        return;
      }

      this.form.brandData[index].recommendBrandLogo = res.data.http;
    },
    beforeUpload(file) {
      const isLt500K = file.size / 1024 < 500;

      if (!isLt500K) {
        this.$message.error('图片大小超过500k');
      }

      return true;
    },
    productCoverSuccess(res, file, index, rindex, pindex) {
      console.log(res);
      if (res.success === false) {
        this.$message.error('上传失败');
        return;
      }

      this.form.brandData[index].recommendAreaSaveList[rindex].products[pindex].picUrl = res.data.http;

      console.log(this.form.brandData);
    },
    async save() {
      this.$refs.brandForm.validate(async (valid) => {
        if (valid) {
          const recommendOrProductEmpty = this.form.brandData.some((el) => {
            if (el.recommendAreaSaveList.length) {
              return el.recommendAreaSaveList.some((el2) => !el2.products.length);
            }

            return true;
          });

          if (recommendOrProductEmpty) {
            this.$message.error('请检查品牌是否都包含推荐区域和产品');
            return;
          }

          const reqObj = {
            data: {
              topicId: this.topicId,
              isPublish: 0,
              saveList: this.form.brandData
            }
          };

          const res = await publishOrSaveBrand(reqObj);

          if (res) {
            this.$message.success('保存成功');
            this.getBrandDetail();
          }
        } else {
          this.$message.error('请填写必填项');
        }
      });
    },
    async publish() {
      this.$refs.brandForm.validate(async (valid) => {
        if (valid) {
          const recommendOrProductEmpty = this.form.brandData.some((el) => {
            if (el.recommendAreaSaveList.length) {
              return el.recommendAreaSaveList.some((el2) => !el2.products.length);
            }

            return true;
          });

          if (recommendOrProductEmpty) {
            this.$message.error('请检查品牌是否都包含推荐区域和产品');
            return;
          }

          const reqObj = {
            data: {
              topicId: this.topicId,
              isPublish: 1,
              saveList: this.form.brandData
            }
          };

          const res = await publishOrSaveBrand(reqObj);

          if (res) {
            this.$message.success('发布成功');
            this.getBrandDetail();
          }
        } else {
          this.$message.error('请填写必填项');
        }
      });
    },
    async restore() {
      const reqObj = {
        data: {
          topicId: this.topicId
        }
      };

      const res = await restoreBrand(reqObj);

      if (res) {
        this.$message.success('还原品牌区域成功');
        this.getBrandDetail();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.brand-edit {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // overflow: auto;
  padding-bottom: 20px;

  .brand-area {
    margin: 20px;
    padding: 20px 0;
    background: #f7f7f7;
    overflow-x: hidden;

    .brand-title {
      margin: 0 20px 26px;
      display: flex;
      justify-content: space-between;
    }

    .brand-cover-uploader .el-upload {
      border: 1px dashed #000;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      background: #fff;
    }

    .brand-cover-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .brand-cover-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 350px;
      height: 112px;
      line-height: 100px;
      text-align: center;
      background: #fff;
      border: 1px dashed #e0e0e0;
    }

    .brand-cover {
      width: 350px;
      height: 112px;
    }

    .upload-tip {
      font-size: 12px;
      color: #999;
    }
  }
  .brand-recommend {
    margin: 0 20px 20px;
    border-top: 1px solid #e0e0e0;
    padding-top: 24px;

    .recommend-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 26px;
    }

    .recommend-list::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      /**/
    }
    .recommend-list::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 5px;
      display: none;
    }
    .recommend-list::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 10px;
    }
    .layout-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .recommend-product {
      border: 1px solid #ededed;
      background: #fff;
      padding: 30px 0;
      margin-bottom: 20px;
      position: relative;

      .product-delete-button {
        position: absolute;
        right: 10px;
        top: 10px;
      }

      .product-cover-uploader .el-upload {
        border: 1px dashed #000;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        background: #fff;
      }

      .product-cover-uploader .el-upload:hover {
        border-color: #409eff;
      }

      .product-cover-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        background: #fff;
        border: 1px dashed #e0e0e0;
      }

      .product-cover {
        width: 100px;
        height: 100px;
      }
    }

    .bottom-button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }

    .el-radio-group {
      margin-bottom: 10px;
    }
  }

  .bold-title {
    font-size: 18px;
    font-weight: bold;
  }
}
</style>
