import { render, staticRenderFns } from "./SeatAddOrEdit.vue?vue&type=template&id=1312f3ba&scoped=true&"
import script from "./SeatAddOrEdit.vue?vue&type=script&lang=js&"
export * from "./SeatAddOrEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1312f3ba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\code\\大片来了\\前端\\tongyuan-vue-system-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1312f3ba')) {
      api.createRecord('1312f3ba', component.options)
    } else {
      api.reload('1312f3ba', component.options)
    }
    module.hot.accept("./SeatAddOrEdit.vue?vue&type=template&id=1312f3ba&scoped=true&", function () {
      api.rerender('1312f3ba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/department/components/SeatAddOrEdit.vue"
export default component.exports