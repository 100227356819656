var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "brand-edit",
    },
    [
      _c(
        "el-form",
        {
          ref: "brandForm",
          attrs: { "label-width": "180px", model: _vm.form },
        },
        [
          _c(
            "div",
            {
              staticClass: "all-brand",
              staticStyle: {
                overflow: "auto",
                height: "80vh",
                position: "relative",
              },
              on: {
                "&scroll": function ($event) {
                  return _vm.brandScroll.apply(null, arguments)
                },
              },
            },
            _vm._l(_vm.form.brandData, function (brandArea, index) {
              return _c(
                "div",
                {
                  key: `brandArea${index}`,
                  staticClass: "brand-area",
                  attrs: { id: "brand" + index },
                },
                [
                  _c(
                    "div",
                    { staticClass: "brand-title" },
                    [
                      _c("span", { staticClass: "bold-title" }, [
                        _vm._v("品牌信息"),
                      ]),
                      _c("el-button", {
                        attrs: {
                          type: "info",
                          size: "mini",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.brandDelete(
                              index,
                              brandArea.recommendBrandId
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "品牌名称：",
                        prop: "brandData." + index + ".recommendBrandName",
                        rules: _vm.rules.recommendBrandName,
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { "max-width": "210px" },
                        attrs: {
                          placeholder: "请填写品牌名称",
                          maxlength: "10",
                          "show-word-limit": "",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.brandNameInput($event, index)
                          },
                        },
                        model: {
                          value: brandArea.recommendBrandName,
                          callback: function ($$v) {
                            _vm.$set(brandArea, "recommendBrandName", $$v)
                          },
                          expression: "brandArea.recommendBrandName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "排序：",
                        prop: "brandData." + index + ".sortNum",
                        rules: _vm.rules.sortNum,
                      },
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 1 },
                        model: {
                          value: brandArea.sortNum,
                          callback: function ($$v) {
                            _vm.$set(brandArea, "sortNum", $$v)
                          },
                          expression: "brandArea.sortNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "品牌头图：",
                        prop: "brandData." + index + ".recommendBrandLogo",
                        rules: _vm.rules.picture,
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "brand-cover-uploader",
                          attrs: {
                            accept: ".jpg, .png, .jpeg",
                            action: _vm.actionUrl,
                            "show-file-list": false,
                            "on-success": (res, file) =>
                              _vm.brandCoverSuccess(res, file, index),
                            "before-upload": _vm.beforeUpload,
                          },
                        },
                        [
                          brandArea.recommendBrandLogo
                            ? _c("img", {
                                staticClass: "brand-cover",
                                attrs: { src: brandArea.recommendBrandLogo },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus brand-cover-uploader-icon",
                              }),
                        ]
                      ),
                      _c("div", { staticClass: "upload-tip" }, [
                        _c("i", { staticClass: "el-icon-info" }),
                        _vm._v(
                          " 只能上传jpg/png格式文件，且不超过500kb，建议尺寸1053*345px "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._l(
                    brandArea.recommendAreaSaveList,
                    function (recommendItem, rindex) {
                      return _c(
                        "div",
                        {
                          key: rindex,
                          staticClass: "brand-recommend",
                          attrs: {
                            id: "brand" + index + "-" + "recommend" + rindex,
                          },
                        },
                        [
                          _c("div", { staticClass: "recommend-title" }, [
                            _c("span", { staticClass: "bold-title" }, [
                              _vm._v("推荐区域" + _vm._s(rindex + 1)),
                            ]),
                            _c(
                              "span",
                              [
                                brandArea.recommendAreaSaveList.length - 1 ===
                                rindex
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-plus",
                                          plain: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addRecommend(
                                              index,
                                              rindex
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("增加推荐区域")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-popconfirm",
                                  {
                                    staticStyle: { "margin-left": "10px" },
                                    attrs: {
                                      icon: "",
                                      title:
                                        "删除会导致所有编辑内容被清空，是否确认删除？",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteRecommend(
                                          index,
                                          rindex
                                        )
                                      },
                                    },
                                  },
                                  [
                                    brandArea.recommendAreaSaveList.length !== 1
                                      ? _c("el-button", {
                                          attrs: {
                                            slot: "reference",
                                            type: "info",
                                            size: "mini",
                                            icon: "el-icon-delete",
                                          },
                                          slot: "reference",
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标题：",
                                prop:
                                  "brandData." +
                                  index +
                                  ".recommendAreaSaveList." +
                                  rindex +
                                  ".title",
                                rules: _vm.rules.title,
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "300px" },
                                attrs: {
                                  placeholder: "请填写标题",
                                  maxlength: "15",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: recommendItem.title,
                                  callback: function ($$v) {
                                    _vm.$set(recommendItem, "title", $$v)
                                  },
                                  expression: "recommendItem.title",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "套系产品分类id：",
                                prop:
                                  "brandData." +
                                  index +
                                  ".recommendAreaSaveList." +
                                  rindex +
                                  ".categoryId",
                                rules: _vm.rules.categoryId,
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { "max-width": "420px" },
                                attrs: { placeholder: "请填写套系产品分类ID" },
                                model: {
                                  value: recommendItem.categoryId,
                                  callback: function ($$v) {
                                    _vm.$set(recommendItem, "categoryId", $$v)
                                  },
                                  expression: "recommendItem.categoryId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "推荐样式：",
                                prop:
                                  "brandData." +
                                  index +
                                  ".recommendAreaSaveList." +
                                  rindex +
                                  ".composeType",
                                rules: _vm.rules.composeType,
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "recommend-list",
                                  staticStyle: { overflow: "auto" },
                                },
                                [
                                  _c(
                                    "el-row",
                                    { staticStyle: { "min-width": "600px" } },
                                    _vm._l(
                                      _vm.layoutList,
                                      function (layout, sIndex) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: `s${sIndex}`,
                                            attrs: { md: 8, sm: 8, lg: 4 },
                                          },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                on: {
                                                  change: (e) =>
                                                    _vm.handleStyleChange(
                                                      e,
                                                      index,
                                                      rindex
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    recommendItem.composeType,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      recommendItem,
                                                      "composeType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "recommendItem.composeType",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "layout-wrapper",
                                                  },
                                                  [
                                                    _c(
                                                      "we-chat-style-wrapper",
                                                      {
                                                        attrs: {
                                                          index: parseInt(
                                                            layout.dictionaryValue
                                                          ),
                                                        },
                                                      }
                                                    ),
                                                    _c(
                                                      "el-radio",
                                                      {
                                                        attrs: {
                                                          label: Number(
                                                            layout.dictionaryValue
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            layout.dictionaryName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "排序：",
                                prop:
                                  "brandData." +
                                  index +
                                  ".recommendAreaSaveList." +
                                  rindex +
                                  ".sortNum",
                                rules: _vm.rules.sortNum,
                              },
                            },
                            [
                              _c("el-input-number", {
                                attrs: { min: 1 },
                                model: {
                                  value: recommendItem.sortNum,
                                  callback: function ($$v) {
                                    _vm.$set(recommendItem, "sortNum", $$v)
                                  },
                                  expression: "recommendItem.sortNum",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._l(
                            recommendItem.products,
                            function (productItem, pindex) {
                              return _c(
                                "div",
                                {
                                  key: pindex,
                                  staticClass: "recommend-product",
                                },
                                [
                                  [1, 8, 7].includes(recommendItem.composeType)
                                    ? _c("el-button", {
                                        staticClass: "product-delete-button",
                                        attrs: {
                                          type: "info",
                                          size: "mini",
                                          icon: "el-icon-delete",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteProduct(
                                              index,
                                              rindex,
                                              pindex
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品图片：",
                                        prop:
                                          "brandData." +
                                          index +
                                          ".recommendAreaSaveList." +
                                          rindex +
                                          ".products." +
                                          pindex +
                                          ".picUrl",
                                        rules: _vm.rules.picture,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-upload",
                                        {
                                          staticClass: "product-cover-uploader",
                                          attrs: {
                                            accept: ".jpg, .png, .jpeg",
                                            action: _vm.actionUrl,
                                            "show-file-list": false,
                                            "on-success": (res, file) =>
                                              _vm.productCoverSuccess(
                                                res,
                                                file,
                                                index,
                                                rindex,
                                                pindex
                                              ),
                                            "before-upload": _vm.beforeUpload,
                                          },
                                        },
                                        [
                                          productItem.picUrl
                                            ? _c("img", {
                                                staticClass: "product-cover",
                                                attrs: {
                                                  src: productItem.picUrl,
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "el-icon-plus product-cover-uploader-icon",
                                              }),
                                        ]
                                      ),
                                      _c("div", { staticClass: "upload-tip" }, [
                                        _c("i", {
                                          staticClass: "el-icon-info",
                                        }),
                                        _vm._v(
                                          " 只能上传jpg/png格式文件，且不超过500kb，建议尺寸1053*345px "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "产品标题：",
                                        prop:
                                          "brandData." +
                                          index +
                                          ".recommendAreaSaveList." +
                                          rindex +
                                          ".products." +
                                          pindex +
                                          ".productName",
                                        rules:
                                          recommendItem.composeType == 7
                                            ? {
                                                required: true,
                                                message: "请填写标题",
                                                trigger: "blur",
                                              }
                                            : {},
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "max-width": "420px" },
                                        attrs: {
                                          placeholder: "请输入产品标题",
                                        },
                                        model: {
                                          value: productItem.productName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              productItem,
                                              "productName",
                                              $$v
                                            )
                                          },
                                          expression: "productItem.productName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "链接：",
                                        prop:
                                          "brandData." +
                                          index +
                                          ".recommendAreaSaveList." +
                                          rindex +
                                          ".products." +
                                          pindex +
                                          ".url",
                                        rules: _vm.rules.url,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { "max-width": "420px" },
                                        attrs: { placeholder: "请输入链接" },
                                        model: {
                                          value: productItem.url,
                                          callback: function ($$v) {
                                            _vm.$set(productItem, "url", $$v)
                                          },
                                          expression: "productItem.url",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "排序：",
                                        prop:
                                          "brandData." +
                                          index +
                                          ".recommendAreaSaveList." +
                                          rindex +
                                          ".products." +
                                          pindex +
                                          ".sortNum",
                                        rules: _vm.rules.picture,
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: { min: 1 },
                                        model: {
                                          value: productItem.sortNum,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              productItem,
                                              "sortNum",
                                              $$v
                                            )
                                          },
                                          expression: "productItem.sortNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          [1, 8, 7].includes(recommendItem.composeType)
                            ? _c(
                                "div",
                                { staticClass: "bottom-button" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        size: "mini",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addProduct(index, rindex)
                                        },
                                      },
                                    },
                                    [_vm._v("增加推荐产品")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }
                  ),
                ],
                2
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }