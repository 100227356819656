<template>
  <div class="condition-setting">
    <div class="setting-name">
      <span>条件{{ index + 1 }}</span>
      <i class="el-icon-delete delete-button" @click="deleteItem"></i>
    </div>

    <div class="setting-content">
      <el-select
        v-model="item.databaseFieldName"
        size="small"
        placeholder="请选择表字段"
        class="select-ex"
        @change="fieldChange"
      >
        <el-option
          v-for="item in fieldOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <KeySelect
        req-key="logic"
        :val.sync="item.logic"
        placeholderWord="运算符"
        class="select-ex"
      />

      <component
        :is="componentItem.component"
        v-if="componentItem.type == 'array' || componentItem.type == 'keySelect'"
        :valArrStr.sync="item.value"
        :val.sync="item.val"
        :arr.sync="item.arr"
        :reqKey="componentItem.reqKey"
        class="select-ex"
      >
      </component>

      <el-input
        size="small"
        placeholder="请输入字段值"
        class="input-ex"
        v-model="item.value"
        v-else
      ></el-input>
    </div>
  </div>
</template>

<script>
import KeysMap, { components } from './tookit/index.js';

export default {
  components: {
    ...components
  },
  inject: ['fieldOptionList'],
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    fieldOptions() {
      return this.fieldOptionList();
    },
    componentItem() {
      return KeysMap[this.item.fieldType] || {};
    }
  },
  methods: {
    deleteItem() {
      this.$emit('delete', this.item);
    },
    fieldChange() {
      this.resetValueField();

      const field = this.fieldOptions.find((el) => el.value == this.item.databaseFieldName);
      if (!field) return;

      this.item.fieldName = field.fieldName;
      this.item.fieldType = field.fieldType;
    },
    resetValueField() {
      this.item.value = '';
      this.item.val = [];
      this.item.arr = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.condition-setting {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .setting-name {
    font-size: 14px;
    margin-top: 10px;
    display: inline-flex;
    align-items: center;

    .delete-button {
      font-size: 16px;
      cursor: pointer;
      color: #999;
      margin-left: 10px;
    }
  }

  .setting-content {
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;

    > :nth-child(n) {
      flex-shrink: 0;
    }
  }

  .select-ex,
  .input-ex {
    width: 32%;
    margin-right: 1.2%;
  }
}
</style>
