var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "agreement-detail",
    },
    [
      _c("div", { staticClass: "header" }, [_vm._v("协议详情")]),
      _c(
        "el-form",
        {
          staticClass: "form",
          attrs: { size: "small", "label-width": "60px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "版本号：" } },
            [
              _c("el-input", {
                staticStyle: { color: "#000" },
                attrs: { value: _vm.detailData.version, readonly: "" },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "内容：" } }, [
            _c("div", {
              staticClass: "textarea",
              domProps: { innerHTML: _vm._s(_vm.detailData.content) },
            }),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "图片：" } },
            [
              _vm.detailData.picUrl === ""
                ? _c("span")
                : _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: _vm.detailData.picUrl,
                      "preview-src-list": [_vm.detailData.picUrl],
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }