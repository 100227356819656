<template>
  <div class="tree-condition-item">
    <div class="tree-content" v-if="item.params.length > 0">
      <div class="top-line-group">
        <div class="top-line-group-text">
          <span
            v-for="(item, index) in conditionWordsArr"
            :key="index"
            @click="topLineGroupClick(index)"
            :class="[{ 'top-line-group-text-active': topConditionCheckValue == item.value }]"
          >
            {{ item.label }}
          </span>
        </div>
      </div>

      <div class="rigth-conditions">
        <ConditionSettingItem
          v-for="(item, index) in item.params"
          :key="item.key"
          @delete="deleteRow"
          :item="item"
          :index="index"
        />

        <div class="buttons">
          <el-button size="small" type="text" @click="addRow">添加关联条件</el-button>

          <el-button size="small" type="text" @click="deleteSelfItem" style="color: #f56c6c">
            删除全部
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConditionSettingItem from './ConditionSettingItem.vue';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ConditionSettingItem
  },
  data() {
    return {
      conditionWordsArr: [
        { label: '且', value: 'and' },
        { label: '或', value: 'or' }
      ]
    };
  },
  computed: {
    topConditionCheckValue() {
      return this.item.relations;
    }
  },
  created() {
    (!this.item.params || this.item.params.length == 0) && this.addRow();
  },
  methods: {
    topLineGroupClick(index) {
      this.item.relations = this.conditionWordsArr[index].value;
    },
    addRow() {
      this.item.params.push({
        key: Date.now(),
        fieldName: '',
        databaseFieldName: '',
        logic: '',
        value: '',
        fieldType: '',
        val: [],
        arr: []
      });
    },
    deleteRow(item) {
      const conditions = this.item;
      conditions.params.splice(conditions.params.indexOf(item), 1);

      if (conditions.params == 0) {
        this.deleteSelfItem();
      }
    },
    deleteSelfItem() {
      this.$emit('delete', this.item);
    }
  }
};
</script>

<style lang="scss" scoped>
.tree-condition-item {
  box-sizing: border-box;
  width: 100%;

  .tree-content {
    display: flex;
    flex-direction: row;
    margin-top: 30px;

    > div {
      flex-shrink: 0;
    }

    .top-line-group {
      width: 1px;
      position: relative;
      height: auto;
      background-color: rgb(192 196 204 / 60%);

      &-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        writing-mode: vertical-lr;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 6px;
        user-select: none;
        overflow: hidden;

        > span {
          display: inline;
          background-color: #f5f5f5;
          color: #9a9d9e;
          padding: 4px;
          font-size: 13px;
          font-weight: 400;
        }

        &-active {
          background-color: #e9effc !important;
          color: #2861e2 !important;
        }
      }
    }

    .rigth-conditions {
      flex: 1;
      margin-left: 30px;

      .buttons {
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
