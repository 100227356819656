<template>
  <section class="department-tree">
    <header class="search">
      <el-form inline :model="depFrom" size="small">
        <el-form-item>
          <el-input v-model="depFrom.departmentName" placeholder="部门名称" style="width: 120px" />
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="depFrom.isEnable"
            style="width: 120px; margin-left: 10px"
            placeholder="启用状态"
          >
            <el-option value="" label="全部"></el-option>
            <el-option :value="0" label="启用"></el-option>
            <el-option :value="1" label="停用"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </header>
    <article class="tree-wrapper">
      <el-tree
        :data="treeData"
        :props="defaultProps"
        @node-click="handleNodeClick"
        :expand-on-click-node="false"
        class="tree-custom"
        default-expand-all
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div>
            {{ data.departmentName
            }}<span class="status" :class="{ 'status-stop': data.isDisable === 1 }">{{
              data.isDisable === 0 ? '启用' : '停用'
            }}</span>
          </div>
          <el-dropdown
            v-show="checkedId === data.departmentId"
            @command="(command) => handleCommand(command, data)"
          >
            <span class="el-dropdown-link">
              <span class="el-icon-setting"></span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1">新建下级部门</el-dropdown-item>
              <el-dropdown-item :command="2"> 新建平级部门</el-dropdown-item>
              <el-dropdown-item :command="3">编辑部门</el-dropdown-item>
              <el-dropdown-item :command="4">{{
                data.isDisable === 0 ? '停用部门' : '启用部门'
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-tree>
    </article>
    <edit-department-modal ref="EditDepartmentModal" @modalFormOk="modalFormOk" :departmentTree="treeData" />
  </section>
</template>

<script>
import EditDepartmentModal from './EditDepartmentModal.vue';
import { getDepartments, setDepartmentStatus } from '@/api/department';

export default {
  name: 'DepartmentTree',
  components: {
    EditDepartmentModal
  },
  data() {
    return {
      depFrom: {
        departmentName: '',
        isEnable: ''
      },
      treeData: [],
      departmentTree: [],
      defaultProps: {
        children: 'children',
        label: 'departmentName'
      },
      iPagination: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      visible: false,
      checkedId: -1
    };
  },
  mounted() {
    this.getDepartmentList();
  },
  watch: {
    depFrom: {
      handler() {
        this.getDepartmentList();
      },
      deep: true
    }
  },
  methods: {
    handleNodeClick(data) {
      this.checkedId = data.departmentId;
      this.$emit('selectDepartment', data);
    },
    handleCommand(command, node) {
      console.log(command, node);
      if (command === 1) {
        // 新增下级部门
        this.$refs.EditDepartmentModal.add(command, node);
      }
      if (command === 2) {
        // 新增平级部门
        this.$refs.EditDepartmentModal.add(command, node);
      }
      if (command === 3) {
        // 编辑
        this.$refs.EditDepartmentModal.edit(node);
      }
      if (command === 4) {
        // 启用停用
        this.handleShowIsDisableModal(node);
      }
    },
    handleShowIsDisableModal(row) {
      const that = this;
      const params = {
        data: {
          departmentId: parseInt(row.departmentId, 10),
          isEnable: row.isDisable === 0 ? 1 : 0
        }
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await setDepartmentStatus(params);
          if (res.success === false) {
            this.$message.error(res.description);
            return;
          }
          this.getDepartmentList();
          this.$message.success('操作成功');
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    handleDeleteClassify(id) {
      console.log('删除分类', id);
    },
    async getDepartmentList() {
      try {
        const res = await getDepartments({ data: { ...this.depFrom } });
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        this.treeData = res;
      } catch (err) {
        console.error(err);
      }
    },
    modalFormOk() {
      this.getDepartmentList();
    }
  }
};
</script>

<style scoped lang="less">
.department-tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  .search {
    background: #fff;
    padding: 10px 20px 0px 10px;
    border-bottom: 1px solid #eaeaea;
  }
  .tree-wrapper {
    flex: 1;
    overflow: auto;
  }
}
.tree-custom {
  /deep/ .el-tree-node__content {
    height: 40px !important;

    .custom-tree-node {
      padding-right: 10px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      font-size: 14px;
      .status {
        margin-left: 10px;
        color: #00a199;
      }
      .status-stop {
        margin-right: 10px;
        color: #e86247;
      }
      .el-icon-setting {
      }
    }
  }
  ::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #e9effc;
  }
}
</style>
