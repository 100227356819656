var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "advice-detail",
    },
    [
      _c(
        "article",
        { staticClass: "advice-detail-info" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("客户ID：")]),
                  _c(
                    "div",
                    {
                      staticClass: "text",
                      staticStyle: { color: "#2861e2", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.handleToJumper(_vm.detailInfo.customerId)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.detailInfo.customerId) + " ")]
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("姓名：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.userName) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("用户ID：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.userId) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("用户手机号：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.phone) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("类型：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.typeName) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "info-item" }, [
                  _c("div", { staticClass: "name" }, [_vm._v("提交时间：")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(" " + _vm._s(_vm.detailInfo.createTime) + " "),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "article",
        { staticClass: "advice-detail-content" },
        [
          _c("common-title", { attrs: { title: "意见反馈" } }),
          _c("div", { staticClass: "user-advice" }, [
            _vm._v(" " + _vm._s(_vm.detailInfo.content) + " "),
          ]),
          _vm.detailInfo.pics && _vm.detailInfo.pics.length > 0
            ? _c(
                "div",
                { staticClass: "img-list" },
                _vm._l(_vm.detailInfo.pics, function (img, imgIndex) {
                  return _c("el-image", {
                    key: imgIndex,
                    staticClass: "img",
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: img, "preview-src-list": [img] },
                  })
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }