<template>
  <el-form inline size="small" :model="searchForm" ref="searchForm">
    <el-form-item>
      <el-input placeholder="渠道ID" style="width: 110px" v-model="searchForm.channelId" />
    </el-form-item>
    <el-form-item>
      <el-input placeholder="渠道名称" style="width: 130px" v-model="searchForm.channelName" />
    </el-form-item>
    <el-form-item>
      <el-date-picker
        v-model="time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        style="width: 320px"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-select
        value=""
        placeholder="启用状态"
        style="width: 100px"
        v-model="searchForm.isDisable"
      >
        <el-option value="" label="全部"></el-option>
        <el-option :value="0" label="启用"></el-option>
        <el-option :value="1" label="停用"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="所属区域：">
      <el-select-tree
        :load="lazyLoad"
        lazy
        :props="{ value: 'districtId', label: 'districtName', child: 'childs' }"
        v-model="searchForm.districtId"
        style="width: 100%"
        accordion
        filterable
        ref="cascader"
      />
    </el-form-item>
    <el-form-item label="负责人：">
      <!-- <el-input size="small" style="width: 200px" v-model="ruleForm.erpUserId" placeholder="请输入"></el-input> -->
      <el-autocomplete
        v-model="userName"
        value-key="userName"
        label="userId"
        :fetch-suggestions="querySearchStaffAsync"
        placeholder="请输入负责人姓名"
        style="width: 100%"
        popper-class="my-autocomplete"
        @select="userSelect"
      >
        <template slot-scope="{ item }">
          <div class="prdtname">
            <span>{{ item.userName + ' - ' }}</span>
            <span>{{ item.userId }}</span>
          </div>
          <div>
            <!-- <span class="brdtype">{{ item.brandno + ' - ' }}</span> -->
            <span class="brdtype">{{ item.postNames }}</span>
          </div>
        </template>
      </el-autocomplete>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button @click="reset">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import dayjs from 'dayjs';
import { getCityList } from '@/api/city';
import { userList } from '@/api/user';

export default {
  name: '',
  data() {
    return {
      time: '',
      searchForm: {
        channelId: '',
        channelName: '',
        createTimeStart: '',
        createTimeEnd: '',
        isDisable: '',
        districtId: '',
        userId: ''
      },
      userName: ''
    };
  },
  watch: {
    time(newVal) {
      if (newVal) {
        this.searchForm.createTimeStart = dayjs(newVal[0]).format('YYYY-MM-DD HH:mm:ss');
        this.searchForm.createTimeEnd = dayjs(newVal[1]).format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.searchForm.createTimeStart = '';
        this.searchForm.createTimeEnd = '';
      }
    }
  },
  methods: {
    search() {
      console.log(this.searchForm);
      this.$emit('search', this.searchForm);
    },
    reset() {
      console.log(this.searchForm);
      this.time = '';
      this.searchForm.isDisable = '';
      this.searchForm.channelId = '';
      this.searchForm.channelName = '';
      this.searchForm.createTimeEnd = '';
      this.searchForm.createTimeStart = '';
      this.userName = '';
      this.searchForm.userId = '';
      this.searchForm.districtId = '';
      this.$emit('reset', this.searchForm);
    },
    querySearchStaffAsync(queryString, cb) {
      console.log(queryString);
      userList({
        data: {
          userNameOrPhoneNumber: queryString
        },
        pageNumber: 1,
        pageSize: 999
      }).then((res) => {
        console.log(res);
        cb(res.content);
      });
    },
    lazyLoad(node, resolve) {
      console.log(node);
      if (node.data && node.data.isLeaf) return resolve([]);
      getCityList({
        data: {
          districtId: node.data?.districtId,
          districtType: node.level + 1,
          status: node.level + 1 === 2 && 1
        }
      }).then((res) => {
        if (node.level === 2) {
          res = res.map((e) => {
            e.isLeaf = true;
            return e;
          });
        }
        console.log(res);
        resolve(res);
      });
    },
    userSelect(e) {
      this.searchForm.userId = e.userId;
    }
  }
};
</script>

<style lang="scss" scoped></style>
