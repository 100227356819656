<template>
  <el-dialog :title="title" :visible="visible" width="30%" :before-close="handleCancel">
    <el-form :model="form" :rules="rules" ref="modalFrom" label-width="100px" size="small">
      <el-form-item prop="roleId" v-show="false">
        <el-input disable v-model="form.roleId"></el-input>
      </el-form-item>
      <el-form-item label="角色名称：" prop="roleName">
        <el-input v-model="form.roleName" />
      </el-form-item>
      <el-form-item label="角色描述：" prop="description">
        <el-input v-model="form.description" type="textarea" rows="4" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk" :loading="confirmLoading">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { omit } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';

export default {
  name: 'EditRole',
  mixins: [TableModalMinix],
  data() {
    return {
      visible: false,
      defaultForm: {
        roleId: '',
        roleName: '',
        description: ''
      },
      title: '',
      url: {
        add: 'user-web-api/admin/role/addRole',
        update: 'user-web-api/admin/role/updateRole'
      },
      form: {
        roleId: '',
        roleName: '',
        description: ''
      },
      rules: {
        roleName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    add() {
      this.visible = true;
      this.isEdit = false;
    },
    edit(row) {
      this.visible = true;
      this.form.roleId = row.roleId;
      this.form.roleName = row.roleName;
      this.form.description = row.description;
    },
    handleOk() {
      this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          if (this.isEdit) {
            await this.handleModalUpdate({ data: { ...this.form } }, '操作成功');
          } else {
            const data = omit(this.form, ['roleId']);
            await this.handleModalAdd({ data }, '操作成功');
          }
        }
        return false;
      });
      console.log('ok');
    }
  }
};
</script>

<style scoped></style>
