<template>
  <section class="position-wrapper">
    <!--    <search @search="handleSearch" @reset="handleRest" />-->
    <div class="search">
      <div class="search-item">
        <label for="" class="search-item__label">端口</label>
        <el-select v-model="search.nodePort" size="small" placeholder="请选择">
          <el-option
            v-for="item in portoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-item">
        <label for="" class="search-item__label">节点名称</label>
        <el-input v-model="search.nodeName" size="small" placeholder="节点名称"></el-input>
      </div>
      <div class="search-item">
        <el-button type="primary" @click="onSearch">搜 索</el-button>
        <el-button @click="onReset">重 置</el-button>
      </div>
    </div>
    <div class="table-wrapper">
      <vxe-grid
        :ref="tableRef"
        :rowId="rowId"
        border
        :data="dataSource"
        :columns="tableColumn"
        size="small"
        v-bind="tableOptions"
        v-loading="loading"
      >
        <template #toolbar_buttons>
          <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        </template>
        <template #nodePort="{ row }">
          <span v-if="row.nodePort === 1">运营端</span>
          <span v-if="row.nodePort === 2">企微端</span>
          <span v-if="row.nodePort === 3">供应端</span>
          <span v-if="row.nodePort === 4">企业端</span>
        </template>
        <template #nodeType="{ row }">
          <span v-if="row.nodeType === 1">菜单</span>
          <span v-if="row.nodeType === 2">操作</span>
        </template>
        <template #operate="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleDelete(row, 1)">删除</el-button>
        </template>
      </vxe-grid>
    </div>
    <edit-menu ref="modalForm" @ok="modalFormOk" :menuTree="selectTree" />
  </section>
</template>

<script>
import { sortBy } from 'lodash';
import TableListMixin from '@/minixs/TableListMinix';
import { getNodeTree } from '@/api/menu';
import EditMenu from './components/EditMenu.vue';

export default {
  name: 'MenuList',
  components: {
    EditMenu
  },
  mixins: [TableListMixin],
  data() {
    return {
      rowId: 'nodeId',
      tableRef: 'MenuTable',
      loading: false,
      dataSource: [],
      menuTree: [],
      selectTree: [],
      url: {
        list: 'user-web-api/admin/permission/tree',
        status: 'user-web-api/admin/rank/updateDisable',
        singleDeleteUrl: 'user-web-api/admin/permission/deleteByIds',
        multiDeleteUrl: 'user-web-api/admin/permission/deleteByIds'
      },
      tableColumn: [
        { field: 'nodeName', title: '节点名称', treeNode: true },
        { field: 'nodeType', title: '节点类型', slots: { default: 'nodeType' } },
        { field: 'nodePort', title: '菜单显示端', slots: { default: 'nodePort' } },
        // { field: 'nodeType', title: '菜单类型' },
        { field: 'mircoName', title: '微应用名称' },
        { field: 'mircoEntry', title: '微应用入口地址' },
        { field: 'path', title: '路由地址' },
        { field: 'icon', title: '图标' },
        { field: 'sort', title: '排序' },

        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        },
        expandConfig: {
          expandAll: false
        },
        treeConfig: {
          transform: true,
          rowField: 'nodeId',
          parentField: 'parentId',
          expandAll: true
        }
      },
      portoptions: [
        {
          label: '运营端',
          value: 1
        },
        {
          label: '企微端',
          value: 2
        },
        {
          label: '供应端',
          value: 3
        },
        {
          label: '企业端',
          value: 4
        }
      ],
      search: {}
    };
  },
  created() {},
  methods: {
    flatMenuTreeArr(items) {
      let newArr = [];
      items.forEach((item) => {
        newArr.push(item);
        // console.log(item.subList)
        if (Array.isArray(item.subList)) {
          newArr = newArr.concat(this.flatMenuTreeArr(item.subList));
        }
      });

      return sortBy(newArr, (menu) => menu.sort);
    },
    async loadData() {
      this.loading = true;
      try {
        const res = await getNodeTree({ data: this.search });
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        const selectTree = [
          {
            parentId: '-1',
            nodeId: '0',
            nodeName: '大片来了',
            subList: [...res]
          }
        ];
        this.selectTree = selectTree;
        this.menuTree = res;

        this.dataSource = this.flatMenuTreeArr(res);
        // console.log(this.dataSource);
      } catch (e) {
        this.loading = false;
      }
    },
    onSearch() {
      this.loadData();
    },
    onReset() {
      this.search = {};
      this.loadData();
    },
    toggleExpandChangeEvent({ row, expanded }) {
      const $table = this.$refs.xTable;
      console.log('节点展开事件', expanded, '获取父节点：', $table.getParentRow(row));
    },
    getTreeExpansionEvent() {
      const $table = this.$refs.xTable;
      const treeExpandRecords = $table.getTreeExpandRecords();
      this.$XModal.alert(treeExpandRecords.length);
    },
    handleDelete(row, type) {
      const that = this;
      this.$confirm('确定删除吗?', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (type === 1) {
            // 单个删除
            const params = { data: [row.nodeId] };
            that.handleSingleDelete(params);
          }
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    async modalFormOk(data) {
      this.$refs.modalForm.visible = false;
      await this.loadData();
      // this.$refs[this.tableRef].setAllTreeExpand(true);
    }
  }
};
</script>

<style scoped lang="less">
.position-wrapper {
  margin-top: 16px;

  .table-wrapper {
    background: #fff;
    padding: 0 20px;
    margin-top: 20px;
  }
}
.search {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  padding: 0 20px;
  box-sizing: border-box;
}
.search-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;
  &__label {
    width: 60px;
    flex-shrink: 0;
    margin-right: 10px;
    font-size: 14px;
    color: #333;
  }
}
</style>
