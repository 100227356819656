var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "新增",
        visible: _vm.visible,
        width: "30%",
        "before-close": _vm.handleCancel,
      },
    },
    [
      _vm.typeList.length > 0
        ? _c(
            "el-form",
            {
              ref: "modalFrom",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isEdit,
                      expression: "isEdit",
                    },
                  ],
                  attrs: { prop: "channelTypeId", label: "渠道类型ID：" },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.channelTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelTypeId", $$v)
                      },
                      expression: "form.channelTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型名称：", prop: "channelTypeName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入类型名称" },
                    model: {
                      value: _vm.form.channelTypeName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelTypeName", $$v)
                      },
                      expression: "form.channelTypeName",
                    },
                  }),
                ],
                1
              ),
              _vm.form.pid !== "0"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "上级类型：", prop: "pid" } },
                    [
                      _c("el-select-tree", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.typeList,
                          props: {
                            label: "channelTypeName",
                            child: "children",
                            value: "channelTypeId",
                          },
                          "check-strictly": "",
                        },
                        model: {
                          value: _vm.form.pid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pid", $$v)
                          },
                          expression: "form.pid",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleOk },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }