var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "department-info" },
    [
      _c("common-title", { attrs: { title: "部门基本信息" } }),
      _c(
        "article",
        { staticClass: "department-info-content" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("部门名称："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _c("span", { staticStyle: { color: "#2861e2" } }, [
                      _vm._v(_vm._s(_vm.depInfo.departmentName)),
                    ]),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("部门ID："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.departmentId) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("上级部门名称："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.parentName) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("上级部门ID："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.depInfo.parentId !== -1
                            ? _vm.depInfo.parentId
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("部门负责人："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.headerPeopleName) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("部门在职人数："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.count) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("创建日期："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.createTime) + " "),
                  ]),
                ]),
              ]),
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", { staticClass: "department-info-item" }, [
                  _c("div", { staticClass: "department-info-item-name" }, [
                    _vm._v("最近更新时间："),
                  ]),
                  _c("div", { staticClass: "department-info-item-text" }, [
                    _vm._v(" " + _vm._s(_vm.depInfo.updateTime) + " "),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }