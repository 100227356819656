var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "department-table-wrapper" },
    [
      _c(
        "article",
        { staticClass: "table-wrapper" },
        [
          _c("div", { staticClass: "table-control" }, [
            _c(
              "div",
              { staticClass: "main" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "add",
                    attrs: { size: "small", type: "primary" },
                    on: { click: _vm.handleAdd },
                  },
                  [_vm._v("添加员工")]
                ),
                _c(
                  "el-form",
                  {
                    ref: "searchForm",
                    staticClass: "form",
                    attrs: { inline: "", size: "small" },
                  },
                  [
                    _c(
                      "el-form-item",
                      [
                        _c("el-input", {
                          staticStyle: { width: "100px" },
                          attrs: { placeholder: "员工ID" },
                          model: {
                            value: _vm.searchForm.platformUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchForm, "platformUserId", $$v)
                            },
                            expression: "searchForm.platformUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c("el-input", {
                          staticStyle: { width: "140px" },
                          attrs: { placeholder: "员工姓名/手机号" },
                          model: {
                            value: _vm.searchForm.userNameOrPhoneNumber,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.searchForm,
                                "userNameOrPhoneNumber",
                                $$v
                              )
                            },
                            expression: "searchForm.userNameOrPhoneNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "post" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { "min-width": "80px" },
                            attrs: {
                              filterable: "",
                              placeholder: "岗位",
                              "collapse-tags": "",
                            },
                            model: {
                              value: _vm.searchForm.post,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "post", $$v)
                              },
                              expression: "searchForm.post",
                            },
                          },
                          _vm._l(_vm.postList, function (postItem) {
                            return _c("el-option", {
                              key: postItem.postId,
                              attrs: {
                                value: postItem.postId,
                                label: postItem.postName,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "isResign" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "140px" },
                            attrs: { placeholder: "在职状态" },
                            model: {
                              value: _vm.searchForm.isResign,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "isResign", $$v)
                              },
                              expression: "searchForm.isResign",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 1, label: "离职" },
                            }),
                            _c("el-option", {
                              attrs: { value: 0, label: "在职" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "isDisable" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100px" },
                            attrs: { placeholder: "启用状态" },
                            model: {
                              value: _vm.searchForm.isDisable,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "isDisable", $$v)
                              },
                              expression: "searchForm.isDisable",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: 1, label: "停用" },
                            }),
                            _c("el-option", {
                              attrs: { value: 0, label: "启用" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      [
                        _c("el-button", { on: { click: _vm.handleSearch } }, [
                          _vm._v("查询"),
                        ]),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.handleRest(_vm.searchForm)
                              },
                            },
                          },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "extra" },
              [
                _c(
                  "el-dropdown",
                  {
                    attrs: { trigger: "click" },
                    on: {
                      command: (command) =>
                        _vm.handleCommand(command, _vm.checkedRows),
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "el-dropdown-link" },
                      [
                        _c("el-button", { attrs: { type: "text" } }, [
                          _vm._v("更多"),
                        ]),
                        _c("i", {
                          staticClass: "el-icon-arrow-down el-icon--right",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", { attrs: { command: 3 } }, [
                          _vm._v("停用"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: 4 } }, [
                          _vm._v("启用"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: 5 } }, [
                          _vm._v("离职"),
                        ]),
                        _c("el-dropdown-item", { attrs: { command: 6 } }, [
                          _vm._v("在职"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticStyle: { color: "#999", padding: "10px 0" } }, [
            _vm._v("员工账号初始密码为：dpll20220308"),
          ]),
          _c(
            "vxe-grid",
            _vm._b(
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: _vm.tableRef,
                attrs: {
                  border: "",
                  data: _vm.dataSource,
                  columns: _vm.tableColumn,
                  size: "small",
                },
                on: {
                  "checkbox-change": _vm.handleCheckboxChange,
                  "checkbox-all": _vm.handleCheckboxAll,
                },
                scopedSlots: _vm._u([
                  {
                    key: "leader",
                    fn: function ({ row }) {
                      return [
                        row.parentUserName
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.queryLeader(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.parentUserName))]
                            )
                          : _c("span", [_vm._v("/")]),
                      ]
                    },
                  },
                  {
                    key: "lower",
                    fn: function ({ row }) {
                      return [
                        row.subUserNum
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.queryLower(row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(row.subUserNum))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.subUserNum))]),
                      ]
                    },
                  },
                  {
                    key: "isDisable",
                    fn: function ({ row }) {
                      return [
                        _vm._v(_vm._s(row.isDisable === 0 ? "启用" : "停用")),
                      ]
                    },
                  },
                  {
                    key: "isResign",
                    fn: function ({ row }) {
                      return [
                        _vm._v(_vm._s(row.isResign === 1 ? "离职" : "在职")),
                      ]
                    },
                  },
                  {
                    key: "isOpenSeats",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              row.callAppConfigId > "0" ? "已开通" : "未开通"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        row.isDisable === 0
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openChangePhoneSeat(row)
                                  },
                                },
                              },
                              [_vm._v("坐席设置")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: () => _vm.handleShowRestPassword(row),
                            },
                          },
                          [_vm._v("密码重置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: () => _vm.handleShowIsDisableModal(row),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(row.isDisable === 0 ? "停用" : "启用")
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "pager",
                    fn: function () {
                      return [
                        _c("i-pagination", {
                          attrs: {
                            "page-size": _vm.iPagination.pageSize,
                            "current-page": _vm.iPagination.currentPage,
                            "page-sizes": [10, 20, 30, 40],
                            layout: "total,prev, pager, next ,sizes,jumper",
                            total: _vm.iPagination.total,
                          },
                          on: {
                            "current-change": _vm.handlePageChange,
                            "size-change": _vm.handleSizeChange,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              "vxe-grid",
              _vm.tableOptions,
              false
            )
          ),
        ],
        1
      ),
      _c("edit-user", { ref: "modalForm", on: { ok: _vm.modalFormOk } }),
      _c("seat-settings", {
        attrs: {
          isDrawerCall: _vm.drawerCall,
          isTableListCall: _vm.tableListCall,
        },
        on: { isDrawerCall: _vm.changeDrawerCall },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }