<template>
  <section class="position-wrapper">
    <search @search="handleSearch" @reset="handleRest" />
    <div class="table-wrapper">
      <vxe-grid border :data="dataSource" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #operate="{ row }">
          <el-button type="text" @click="handleShowDetail(row)">详情</el-button>
        </template>
        <template #customerId="{ row }">
          <el-button type="text" @click="handleJump(row.customerId)">{{
            row.customerId
          }}</el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
  </section>
</template>

<script>
import Search from './components/search/index.vue';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMixin from '@/minixs/TableListMinix';

export default {
  name: 'List',
  mixins: [TableListMixin],
  components: {
    Search,
    IPagination
  },
  data() {
    return {
      url: {
        list: 'misc-web-api/admin/feedback/pageList'
      },
      tableColumn: [
        { type: 'seq', title: '序号', fixed: 'left', width: 50 },
        { field: 'customerId', title: '客户ID', slots: { default: 'customerId' } },
        { field: 'userName', title: '姓名' },
        { field: 'userId', title: '用户ID' },
        { field: 'phone', title: '用户手机号' ,width:150},
        { field: 'typeName', title: '类型',width:50 },
        { field: 'content', title: '意见反馈',  width: 300,
  showOverflow: 'tooltip', },
        { field: 'createTime', title: '提交时间',width:180 },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      tableOptions: {}
    };
  },
  methods: {
    handleShowDetail(row) {
      const { feedbackId } = row;
      this.$router.push({
        path: '/small/program/advice/detail',
        query: {
          feedbackId
        }
      });
    },
    handleJump(id) {
      window.history.pushState(null, null, `/index/chance/clientFollowUp/particulars?id=${id}`);
    }
  }
};
</script>

<style scoped lang="less">
.position-wrapper {
  margin-top: 16px;

  .table-wrapper {
    background: #fff;
    padding: 0 20px;
  }
}
</style>
