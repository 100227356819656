var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "channel-wrapper" }, [
    _c(
      "div",
      { staticClass: "side" },
      [_c("channel-tree", { on: { nodeSelect: _vm.nodeClick } })],
      1
    ),
    _c(
      "div",
      { staticClass: "channel-table" },
      [
        _c("channel-table", {
          attrs: { channelTypeId: _vm.channelTypeId, isDisable: _vm.isDisable },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }