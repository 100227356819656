var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-drawer",
        {
          attrs: {
            title: "坐席设置",
            "custom-class": "draw-wrapper",
            visible: _vm.isDrawerCall,
            size: "75%",
            "before-close": _vm.handleCancel,
            wrapperClosable: false,
          },
          on: { open: _vm.onOpen },
        },
        [
          _c(
            "div",
            { staticClass: "draw-content" },
            [
              _c(
                "div",
                { staticClass: "draw-switch" },
                [
                  _c("span", [_vm._v("是否开通坐席")]),
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#1890ff",
                      "inactive-color": "#a0a1a3",
                      "active-value": 1,
                      "inactive-value": 0,
                      "active-text": _vm.isTableListCall.isOpenSeats
                        ? "是"
                        : "否",
                    },
                    on: { change: _vm.seatSwitchChange },
                    model: {
                      value: _vm.isTableListCall.isOpenSeats,
                      callback: function ($$v) {
                        _vm.$set(_vm.isTableListCall, "isOpenSeats", $$v)
                      },
                      expression: "isTableListCall.isOpenSeats",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "draw-button" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.addSeat } },
                    [_vm._v("新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.deleteSeat({}, true)
                        },
                      },
                    },
                    [_vm._v("删除")]
                  ),
                ],
                1
              ),
              _c("vxe-grid", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.listLoading,
                    expression: "listLoading",
                  },
                ],
                ref: "SeatRef",
                staticClass: "draw-table",
                attrs: {
                  data: _vm.dataSource,
                  columns: _vm.tableColumn,
                  "empty-text": "暂无坐席数据",
                  size: "small",
                },
                on: {
                  "checkbox-change": _vm.handleCheckboxChange,
                  "checkbox-all": _vm.handleCheckboxAll,
                },
                scopedSlots: _vm._u([
                  {
                    key: "callApp",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.seatMap[row.callAppConfigId])),
                        ]),
                      ]
                    },
                  },
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.editSeat(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSeat(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c("el-button", { on: { click: _vm.handleCancel } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("SeatAddOrEdit", {
        ref: "SeatAddOrEdit",
        attrs: {
          listByQuery: _vm.listByQuery,
          optionsCall: _vm.optionsCall,
          userId: _vm.isTableListCall.userId,
        },
        on: { getList: _vm.getList },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }