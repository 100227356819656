// 企业影像馆广告配置

import request from '@/utils/request';

// 配置详情
export function getAdvertisementDetail(data) {
  return request({
    url: 'image-web-api/admin/companyAd/detail ',
    method: 'POST',
    data
  });
}

export function advertisementSave(data) {
  return request({
    url: 'image-web-api/admin/companyAd/save ',
    method: 'POST',
    data
  });
}

export function reductionAdvertisement(data) {
  return request({
    url: 'image-web-api/admin/companyAd/reduce ',
    method: 'POST',
    data
  });
}

export function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);

  return request({
    url: 'misc-web-api/common/file/uploadImage',
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export default {};
