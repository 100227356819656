<template>
  <section class="dictionary-wrapper">
    <div class="side">
      <dictionary-type-tree @nodeSelect="nodeSelect" />
    </div>
    <div class="dictionary-main" style="padding-top: 16px">
      <dictionary-table :parentId="parentId" />
    </div>
  </section>
</template>

<script>
import DictionaryTypeTree from './components/DictionaryTypeTree.vue';
import DictionaryTable from './components/DictionaryTable.vue';

export default {
  name: 'DictionaryList',
  components: {
    DictionaryTypeTree,
    DictionaryTable
  },
  data() {
    return {
      parentId: ''
    };
  },
  mounted() {},
  methods: {
    nodeSelect(data) {
      this.parentId = data.dictionaryId;
    }
  }
};
</script>

<style scoped lang="less">
.dictionary-wrapper {
  display: flex;
  height: calc(100% - 80px);
  .dictionary-main {
    padding: 0 20px;
    flex: 1;
  }
  .side {
    width: 300px;
    margin-right: 20px;
    height: 100%;
    overflow: auto;
  }
}
</style>
