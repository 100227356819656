var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "altitude",
      staticClass: "recommentd-edit-content",
      on: {
        "&scroll": function ($event) {
          return _vm.contentScroll.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "recommendForm",
          attrs: { "label-width": "180px", model: _vm.form },
        },
        _vm._l(_vm.form.recommendDataList, function (area, index) {
          return _c(
            "div",
            { key: `area${index}`, staticClass: "area-item" },
            [
              _c("div", { staticClass: "area-item-title" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(area.isTab ? "吸顶导航区" : "推荐区")),
                    ]),
                    _c("i", {
                      staticClass: "el-icon-delete area-delete-icon",
                      on: {
                        click: function ($event) {
                          return _vm.deleteArea(area, index)
                        },
                      },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "area-item-sort" }, [
                _c(
                  "span",
                  {
                    staticClass: "text-black-a",
                    staticStyle: {
                      "font-size": "14px",
                      "margin-right": "20px",
                    },
                  },
                  [_vm._v("排序:")]
                ),
                _c(
                  "span",
                  [
                    _c("el-input-number", {
                      attrs: { size: "medium", min: 1 },
                      model: {
                        value: area.sortNum,
                        callback: function ($$v) {
                          _vm.$set(area, "sortNum", $$v)
                        },
                        expression: "area.sortNum",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              area.isTab
                ? [
                    area.isTab
                      ? _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "margin-bottom": "10px",
                            },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addChildren(area, index)
                              },
                            },
                          },
                          [_vm._v("增加分组")]
                        )
                      : _vm._e(),
                    area.isTab && area.childrenList.length
                      ? _c(
                          "el-tabs",
                          {
                            attrs: { type: "card", closable: "" },
                            on: {
                              "tab-remove": (e) => _vm.removeTab(e, area, 1),
                            },
                            model: {
                              value:
                                _vm.form.recommendDataList[index]
                                  .currentChildren,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.recommendDataList[index],
                                  "currentChildren",
                                  $$v
                                )
                              },
                              expression:
                                "form.recommendDataList[index].currentChildren",
                            },
                          },
                          _vm._l(
                            _vm.form.recommendDataList[index].childrenList,
                            function (item, childrenIndex) {
                              return _c("el-tab-pane", {
                                key: childrenIndex,
                                attrs: {
                                  label: item.title,
                                  name: String(childrenIndex),
                                },
                              })
                            }
                          ),
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "area-item-content",
                        staticStyle: { "border-top": "none" },
                      },
                      [
                        _vm._l(
                          area.childrenList,
                          function (child, childrenIndex) {
                            return [
                              _c(
                                "div",
                                {
                                  key: childrenIndex,
                                  style: {
                                    display:
                                      childrenIndex == area.currentChildren
                                        ? ""
                                        : "none",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        rules: _vm.rules.title,
                                        prop:
                                          "recommendDataList." +
                                          index +
                                          ".childrenList." +
                                          childrenIndex +
                                          ".title",
                                        label: "主标题：",
                                        "label-width": "70px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "10",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: child.title,
                                          callback: function ($$v) {
                                            _vm.$set(child, "title", $$v)
                                          },
                                          expression: "child.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "副标题：",
                                        "label-width": "70px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          placeholder: "请输入",
                                          maxlength: "15",
                                          "show-word-limit": "",
                                        },
                                        model: {
                                          value: child.subject,
                                          callback: function ($$v) {
                                            _vm.$set(child, "subject", $$v)
                                          },
                                          expression: "child.subject",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "排序：",
                                        "label-width": "70px",
                                      },
                                    },
                                    [
                                      _c("el-input-number", {
                                        attrs: { size: "medium", min: 1 },
                                        model: {
                                          value: child.sortNum,
                                          callback: function ($$v) {
                                            _vm.$set(child, "sortNum", $$v)
                                          },
                                          expression: "child.sortNum",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "探索更多：",
                                        "label-width": "70px",
                                      },
                                    },
                                    [
                                      _c("el-select", {
                                        attrs: {
                                          placeholder: "请选择",
                                          "no-data-text": "暂无",
                                        },
                                        model: {
                                          value: child.recommendLinkValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              child,
                                              "recommendLinkValue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "child.recommendLinkValue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "margin-bottom": "10px" },
                                      attrs: { size: "mini", type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addGroup(child)
                                        },
                                      },
                                    },
                                    [_vm._v("增加分类")]
                                  ),
                                  child.groupList.length
                                    ? _c(
                                        "el-tabs",
                                        {
                                          attrs: { type: "card", closable: "" },
                                          on: {
                                            "tab-remove": (e) =>
                                              _vm.removeTab(e, child, 2),
                                          },
                                          model: {
                                            value:
                                              _vm.form.recommendDataList[index]
                                                .childrenList[childrenIndex]
                                                .currentGroup,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.form.recommendDataList[
                                                  index
                                                ].childrenList[childrenIndex],
                                                "currentGroup",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "form.recommendDataList[index].childrenList[childrenIndex].currentGroup",
                                          },
                                        },
                                        _vm._l(
                                          _vm.form.recommendDataList[index]
                                            .childrenList[childrenIndex]
                                            .groupList,
                                          function (item, grandsonIndex) {
                                            return _c("el-tab-pane", {
                                              key: grandsonIndex,
                                              attrs: {
                                                label:
                                                  item.recommendAreaGroupName,
                                                name: String(grandsonIndex),
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    { staticClass: "area-item-content-b" },
                                    [
                                      _vm._l(
                                        child.groupList,
                                        function (grandson, grIndex) {
                                          return [
                                            _c(
                                              "div",
                                              {
                                                key: grIndex,
                                                style: {
                                                  display:
                                                    grIndex ==
                                                    child.currentGroup
                                                      ? ""
                                                      : "none",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules:
                                                        _vm.rules.childrenTitle,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".childrenList." +
                                                        childrenIndex +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".recommendAreaGroupName",
                                                      "label-width": "80px",
                                                      label: "分类名称：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                        maxlength: "8",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value:
                                                          grandson.recommendAreaGroupName,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            grandson,
                                                            "recommendAreaGroupName",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "grandson.recommendAreaGroupName",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules:
                                                        _vm.rules.composeType,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".childrenList." +
                                                        childrenIndex +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".composeType",
                                                      "label-width": "80px",
                                                      label: "样式库：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-container",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                grandson.composeTypeName,
                                                              expression:
                                                                "grandson.composeTypeName",
                                                            },
                                                          ],
                                                          staticClass: "input",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder:
                                                              "请选择",
                                                            readonly:
                                                              "readonly",
                                                            autocomplete: "off",
                                                          },
                                                          domProps: {
                                                            value:
                                                              grandson.composeTypeName,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return ((e) =>
                                                                _vm.toggleDropdown(
                                                                  e,
                                                                  grandson,
                                                                  index,
                                                                  childrenIndex,
                                                                  grIndex
                                                                )).apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                            input: function (
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              )
                                                                return
                                                              _vm.$set(
                                                                grandson,
                                                                "composeTypeName",
                                                                $event.target
                                                                  .value
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _c("i", {
                                                          staticClass:
                                                            "dropdown-icon",
                                                          class: {
                                                            "el-icon-arrow-down":
                                                              !grandson.dropdownVisible,
                                                            "el-icon-arrow-up":
                                                              grandson.dropdownVisible,
                                                          },
                                                        }),
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  grandson.dropdownVisible,
                                                                expression:
                                                                  "grandson.dropdownVisible",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "dropdown-list",
                                                            style: {
                                                              top:
                                                                grandson.top +
                                                                "px",
                                                              left:
                                                                grandson.left +
                                                                "px",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return (() => {}).apply(
                                                                  null,
                                                                  arguments
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-radio-group",
                                                              {
                                                                on: {
                                                                  change: (e) =>
                                                                    _vm.handleStyleChange(
                                                                      e,
                                                                      grandson,
                                                                      index,
                                                                      childrenIndex,
                                                                      grIndex
                                                                    ),
                                                                },
                                                                model: {
                                                                  value:
                                                                    grandson.composeTypeName,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        grandson,
                                                                        "composeTypeName",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "grandson.composeTypeName",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.layoutList,
                                                                function (
                                                                  layout,
                                                                  layIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: layIndex,
                                                                      staticClass:
                                                                        "dropdown-item",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-image",
                                                                        {
                                                                          staticClass:
                                                                            "layout-image",
                                                                          attrs:
                                                                            {
                                                                              src: require(`../../../../assets/images/style${
                                                                                _vm
                                                                                  .layoutStyle[
                                                                                  layout.dictionaryValue -
                                                                                    1
                                                                                ]
                                                                              }.png`),
                                                                            },
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "el-radio",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              label:
                                                                                layout.dictionaryName,
                                                                            },
                                                                          nativeOn:
                                                                            {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.radioClick(
                                                                                    grandson
                                                                                  )
                                                                                },
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              layout.dictionaryName
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              0
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules:
                                                        _vm.rules
                                                          .recommendProduct,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".childrenList." +
                                                        childrenIndex +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".picList",
                                                      "label-width": "80px",
                                                      label: "推荐产品：",
                                                    },
                                                  },
                                                  [
                                                    _vm._l(
                                                      grandson.picList,
                                                      function (pic, picIndex) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: picIndex,
                                                            staticClass:
                                                              "pic-item",
                                                          },
                                                          [
                                                            grandson.composeType ==
                                                              6 ||
                                                            ([1, 2, 5].includes(
                                                              grandson.composeType
                                                            ) &&
                                                              grandson.picList
                                                                .length > 2)
                                                              ? _c("i", {
                                                                  staticClass:
                                                                    "el-icon-delete pic-delete-icon",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.deletePic(
                                                                          pic,
                                                                          index,
                                                                          childrenIndex,
                                                                          grIndex,
                                                                          picIndex
                                                                        )
                                                                      },
                                                                  },
                                                                })
                                                              : _vm._e(),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  rules:
                                                                    _vm.rules
                                                                      .pic,
                                                                  prop:
                                                                    "recommendDataList." +
                                                                    index +
                                                                    ".childrenList." +
                                                                    childrenIndex +
                                                                    ".groupList." +
                                                                    grIndex +
                                                                    ".picList." +
                                                                    picIndex +
                                                                    ".picUrl",
                                                                  "label-width":
                                                                    "80px",
                                                                  label:
                                                                    "产品图片：",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-upload",
                                                                  {
                                                                    staticClass:
                                                                      "avatar-uploader",
                                                                    attrs: {
                                                                      accept:
                                                                        ".jpg, .png, .jpeg",
                                                                      action:
                                                                        _vm.actionUrl,
                                                                      "show-file-list": false,
                                                                      "on-success":
                                                                        (
                                                                          res,
                                                                          file
                                                                        ) =>
                                                                          _vm.handleAvatarSuccess(
                                                                            res,
                                                                            file,
                                                                            pic
                                                                          ),
                                                                      "before-upload":
                                                                        _vm.beforeUpload,
                                                                      "list-type":
                                                                        "picture-card",
                                                                    },
                                                                  },
                                                                  [
                                                                    pic.picUrl
                                                                      ? _c(
                                                                          "img",
                                                                          {
                                                                            style:
                                                                              {
                                                                                width:
                                                                                  "150px",
                                                                                height:
                                                                                  "150px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                src: pic.picUrl,
                                                                              },
                                                                          }
                                                                        )
                                                                      : _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-plus avatar-uploader-icon",
                                                                          }
                                                                        ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "upload-tip",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-warning",
                                                                    }),
                                                                    _vm._v(
                                                                      " 支持 1MB 以内 JPG、PNG 图片，" +
                                                                        _vm._s(
                                                                          [
                                                                            1,
                                                                            2,
                                                                            5,
                                                                            6,
                                                                          ].includes(
                                                                            grandson.composeType
                                                                          )
                                                                            ? _vm
                                                                                .picTye[
                                                                                grandson
                                                                                  .composeType
                                                                              ][0]
                                                                            : _vm
                                                                                .picTye[
                                                                                grandson
                                                                                  .composeType
                                                                              ][
                                                                                picIndex
                                                                              ]
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  "label-width":
                                                                    "80px",
                                                                  label:
                                                                    "产品名称：",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                    maxlength:
                                                                      "15",
                                                                    "show-word-limit":
                                                                      "",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      pic.picDec,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          pic,
                                                                          "picDec",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "pic.picDec",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  "label-width":
                                                                    "80px",
                                                                  rules:
                                                                    _vm.rules
                                                                      .linkType,
                                                                  prop:
                                                                    "recommendDataList." +
                                                                    index +
                                                                    ".childrenList." +
                                                                    childrenIndex +
                                                                    ".groupList." +
                                                                    grIndex +
                                                                    ".picList." +
                                                                    picIndex +
                                                                    ".linkType",
                                                                  label:
                                                                    "链接类型：",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-select",
                                                                  {
                                                                    model: {
                                                                      value:
                                                                        pic.linkType,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            pic,
                                                                            "linkType",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "pic.linkType",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "套系产品ID",
                                                                          value: 1,
                                                                        },
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "el-option",
                                                                      {
                                                                        attrs: {
                                                                          label:
                                                                            "自定义链接",
                                                                          value: 2,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  rules:
                                                                    _vm.rules
                                                                      .link,
                                                                  prop:
                                                                    "recommendDataList." +
                                                                    index +
                                                                    ".childrenList." +
                                                                    childrenIndex +
                                                                    ".groupList." +
                                                                    grIndex +
                                                                    ".picList." +
                                                                    picIndex +
                                                                    ".linkValue",
                                                                  "label-width":
                                                                    "80px",
                                                                  label:
                                                                    "链接：",
                                                                },
                                                              },
                                                              [
                                                                _c("el-input", {
                                                                  attrs: {
                                                                    placeholder:
                                                                      "请输入",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      pic.linkValue,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          pic,
                                                                          "linkValue",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "pic.linkValue",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "el-form-item",
                                                              {
                                                                attrs: {
                                                                  rules:
                                                                    _vm.rules
                                                                      .sort,
                                                                  prop:
                                                                    "recommendDataList." +
                                                                    index +
                                                                    ".childrenList." +
                                                                    childrenIndex +
                                                                    ".groupList." +
                                                                    grIndex +
                                                                    ".picList." +
                                                                    picIndex +
                                                                    ".sortNum",
                                                                  "label-width":
                                                                    "80px",
                                                                  label:
                                                                    "排序：",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input-number",
                                                                  {
                                                                    attrs: {
                                                                      size: "medium",
                                                                      min: 1,
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        pic.sortNum,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            pic,
                                                                            "sortNum",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "pic.sortNum",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    [1, 2, 5, 6].includes(
                                                      grandson.composeType
                                                    ) || !grandson.composeType
                                                      ? _c(
                                                          "el-button",
                                                          {
                                                            staticClass:
                                                              "add-pic-button",
                                                            staticStyle: {
                                                              color: "#2861e2",
                                                              border:
                                                                "1px solid #2861e2",
                                                              width: "100%",
                                                            },
                                                            style: {
                                                              opacity:
                                                                grandson.composeType
                                                                  ? 1
                                                                  : 0.4,
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                !grandson.composeType,
                                                              icon: "el-icon-plus ",
                                                              size: "medium",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.addPic(
                                                                  grandson
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "添加推荐产品"
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  2
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass: "area-item-content",
                        staticStyle: { "margin-top": "10px" },
                      },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "主标题：", "label-width": "70px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "10",
                                "show-word-limit": "",
                              },
                              model: {
                                value: area.title,
                                callback: function ($$v) {
                                  _vm.$set(area, "title", $$v)
                                },
                                expression: "area.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "副标题：", "label-width": "70px" },
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "15",
                                "show-word-limit": "",
                              },
                              model: {
                                value: area.subject,
                                callback: function ($$v) {
                                  _vm.$set(area, "subject", $$v)
                                },
                                expression: "area.subject",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "探索更多：",
                              "label-width": "70px",
                            },
                          },
                          [
                            _c("el-select", {
                              attrs: {
                                placeholder: "请选择",
                                "no-data-text": "暂无",
                              },
                              model: {
                                value: area.recommendLinkValue,
                                callback: function ($$v) {
                                  _vm.$set(area, "recommendLinkValue", $$v)
                                },
                                expression: "area.recommendLinkValue",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-bottom": "10px" },
                            attrs: { size: "mini", type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.addGroup(area)
                              },
                            },
                          },
                          [_vm._v("增加分类")]
                        ),
                        area.groupList.length
                          ? _c(
                              "el-tabs",
                              {
                                attrs: { type: "card", closable: "" },
                                on: {
                                  "tab-remove": (e) =>
                                    _vm.removeTab(e, area, 2),
                                },
                                model: {
                                  value:
                                    _vm.form.recommendDataList[index]
                                      .currentGroup,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.recommendDataList[index],
                                      "currentGroup",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.recommendDataList[index].currentGroup",
                                },
                              },
                              _vm._l(
                                _vm.form.recommendDataList[index].groupList,
                                function (grItem, grIndex) {
                                  return _c("el-tab-pane", {
                                    key: grIndex,
                                    attrs: {
                                      label: grItem.recommendAreaGroupName,
                                      name: String(grIndex),
                                    },
                                  })
                                }
                              ),
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "area-item-content-b" },
                          [
                            _vm._l(area.groupList, function (grItem, grIndex) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: grIndex,
                                    style: {
                                      display:
                                        grIndex == area.currentGroup
                                          ? ""
                                          : "none",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          rules: _vm.rules.childrenTitle,
                                          prop:
                                            "recommendDataList." +
                                            index +
                                            ".groupList." +
                                            grIndex +
                                            ".recommendAreaGroupName",
                                          "label-width": "80px",
                                          label: "分类名称：",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入",
                                            maxlength: "8",
                                            "show-word-limit": "",
                                          },
                                          model: {
                                            value:
                                              grItem.recommendAreaGroupName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                grItem,
                                                "recommendAreaGroupName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "grItem.recommendAreaGroupName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          rules: _vm.rules.composeType,
                                          prop:
                                            "recommendDataList." +
                                            index +
                                            ".groupList." +
                                            grIndex +
                                            ".composeType",
                                          "label-width": "80px",
                                          label: "样式库：",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "dropdown-container" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: grItem.composeTypeName,
                                                  expression:
                                                    "grItem.composeTypeName",
                                                },
                                              ],
                                              staticClass: "input",
                                              attrs: {
                                                type: "text",
                                                placeholder: "请选择",
                                                readonly: "readonly",
                                                autocomplete: "off",
                                              },
                                              domProps: {
                                                value: grItem.composeTypeName,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return ((e) =>
                                                    _vm.toggleDropdown(
                                                      e,
                                                      grItem,
                                                      index,
                                                      grIndex
                                                    )).apply(null, arguments)
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    grItem,
                                                    "composeTypeName",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c("i", {
                                              staticClass: "dropdown-icon",
                                              class: {
                                                "el-icon-arrow-down":
                                                  !grItem.dropdownVisible,
                                                "el-icon-arrow-up":
                                                  grItem.dropdownVisible,
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      grItem.dropdownVisible,
                                                    expression:
                                                      "grItem.dropdownVisible",
                                                  },
                                                ],
                                                staticClass: "dropdown-list",
                                                style: {
                                                  top: grItem.top + "px",
                                                  left: grItem.left + "px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return (() => {}).apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-radio-group",
                                                  {
                                                    on: {
                                                      change: (e) =>
                                                        _vm.handleStyleChange(
                                                          e,
                                                          grItem,
                                                          index,
                                                          grIndex
                                                        ),
                                                    },
                                                    model: {
                                                      value:
                                                        grItem.composeTypeName,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          grItem,
                                                          "composeTypeName",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "grItem.composeTypeName",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.layoutList,
                                                    function (
                                                      layout,
                                                      layIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: layIndex,
                                                          staticClass:
                                                            "dropdown-item",
                                                        },
                                                        [
                                                          _c("el-image", {
                                                            staticClass:
                                                              "layout-image",
                                                            attrs: {
                                                              src: require(`../../../../assets/images/style${
                                                                _vm.layoutStyle[
                                                                  layout.dictionaryValue -
                                                                    1
                                                                ]
                                                              }.png`),
                                                            },
                                                          }),
                                                          _c(
                                                            "el-radio",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  layout.dictionaryName,
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.radioClick(
                                                                      grItem
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  layout.dictionaryName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          rules: _vm.rules.recommendProduct,
                                          prop:
                                            "recommendDataList." +
                                            index +
                                            ".groupList." +
                                            grIndex +
                                            ".picList",
                                          "label-width": "80px",
                                          label: "推荐产品：",
                                        },
                                      },
                                      [
                                        _vm._l(
                                          grItem.picList,
                                          function (pic, picIndex) {
                                            return _c(
                                              "div",
                                              {
                                                key: picIndex,
                                                staticClass: "pic-item",
                                              },
                                              [
                                                grItem.composeType == 6 ||
                                                ([1, 2, 5].includes(
                                                  grItem.composeType
                                                ) &&
                                                  grItem.picList.length > 3)
                                                  ? _c("i", {
                                                      staticClass:
                                                        "el-icon-delete pic-delete-icon",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deletePic(
                                                            pic,
                                                            index,
                                                            grIndex,
                                                            picIndex
                                                          )
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules: _vm.rules.pic,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".picList." +
                                                        picIndex +
                                                        ".picUrl",
                                                      "label-width": "80px",
                                                      label: "产品图片：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-upload",
                                                      {
                                                        staticClass:
                                                          "avatar-uploader",
                                                        attrs: {
                                                          accept:
                                                            ".jpg, .png, .jpeg",
                                                          action: _vm.actionUrl,
                                                          "show-file-list": false,
                                                          "on-success": (
                                                            res,
                                                            file
                                                          ) =>
                                                            _vm.handleAvatarSuccess(
                                                              res,
                                                              file,
                                                              pic
                                                            ),
                                                          "before-upload":
                                                            _vm.beforeUpload,
                                                          "list-type":
                                                            "picture-card",
                                                        },
                                                      },
                                                      [
                                                        pic.picUrl
                                                          ? _c("img", {
                                                              style: {
                                                                width: "150px",
                                                                height: "150px",
                                                              },
                                                              attrs: {
                                                                src: pic.picUrl,
                                                              },
                                                            })
                                                          : _c("i", {
                                                              staticClass:
                                                                "el-icon-plus avatar-uploader-icon",
                                                            }),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "upload-tip",
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "el-icon-warning",
                                                        }),
                                                        _vm._v(
                                                          " 支持 1MB 以内 JPG、PNG 图片，" +
                                                            _vm._s(
                                                              [
                                                                1, 2, 5, 6,
                                                              ].includes(
                                                                grItem.composeType
                                                              )
                                                                ? _vm.picTye[
                                                                    grItem
                                                                      .composeType
                                                                  ][0]
                                                                : _vm.picTye[
                                                                    grItem
                                                                      .composeType
                                                                  ][picIndex]
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "80px",
                                                      label: "产品名称：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                        maxlength: "15",
                                                        "show-word-limit": "",
                                                      },
                                                      model: {
                                                        value: pic.picDec,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pic,
                                                            "picDec",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pic.picDec",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      "label-width": "80px",
                                                      rules: _vm.rules.linkType,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".picList." +
                                                        picIndex +
                                                        ".linkType",
                                                      label: "链接类型：",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-select",
                                                      {
                                                        model: {
                                                          value: pic.linkType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              pic,
                                                              "linkType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "pic.linkType",
                                                        },
                                                      },
                                                      [
                                                        _c("el-option", {
                                                          attrs: {
                                                            label: "套系产品ID",
                                                            value: 1,
                                                          },
                                                        }),
                                                        _c("el-option", {
                                                          attrs: {
                                                            label: "自定义链接",
                                                            value: 2,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules: _vm.rules.link,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".picList." +
                                                        picIndex +
                                                        ".linkValue",
                                                      "label-width": "80px",
                                                      label: "链接：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input", {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                      },
                                                      model: {
                                                        value: pic.linkValue,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pic,
                                                            "linkValue",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pic.linkValue",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "el-form-item",
                                                  {
                                                    attrs: {
                                                      rules: _vm.rules.sort,
                                                      prop:
                                                        "recommendDataList." +
                                                        index +
                                                        ".groupList." +
                                                        grIndex +
                                                        ".picList." +
                                                        picIndex +
                                                        ".sortNum",
                                                      "label-width": "80px",
                                                      label: "排序：",
                                                    },
                                                  },
                                                  [
                                                    _c("el-input-number", {
                                                      attrs: {
                                                        size: "medium",
                                                        min: 1,
                                                      },
                                                      model: {
                                                        value: pic.sortNum,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            pic,
                                                            "sortNum",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "pic.sortNum",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        [1, 2, 5, 6].includes(
                                          grItem.composeType
                                        ) || !grItem.composeType
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "add-pic-button",
                                                staticStyle: {
                                                  color: "#2861e2",
                                                  border: "1px solid #2861e2",
                                                  width: "100%",
                                                },
                                                style: {
                                                  opacity: grItem.composeType
                                                    ? 1
                                                    : 0.4,
                                                },
                                                attrs: {
                                                  disabled: !grItem.composeType,
                                                  icon: "el-icon-plus ",
                                                  size: "medium",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addPic(grItem)
                                                  },
                                                },
                                              },
                                              [_vm._v("添加推荐产品")]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }