var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "数据范围配置",
        visible: _vm.dialogVisible,
        width: "900px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80px", size: "mini" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "状态：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.formData.isDisable,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "isDisable", $$v)
                    },
                    expression: "formData.isDisable",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("启用")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("停用")]),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "条件：" } }, [
            _c("div", { staticClass: "condition-tips" }, [
              _vm._v("设置条件后，仅满足以下条件的数据对当前角色可见"),
            ]),
            _c(
              "div",
              { staticClass: "tree-condition" },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      size: "small",
                      icon: "el-icon-plus",
                    },
                    on: { click: _vm.addRow },
                  },
                  [_vm._v(" 新增条件分组 ")]
                ),
                _vm.showTreeConditionList
                  ? _c("div", { staticClass: "tree-content" }, [
                      _c("div", { staticClass: "top-line-group" }, [
                        _c(
                          "div",
                          { staticClass: "top-line-group-text" },
                          _vm._l(_vm.conditionWordsArr, function (item, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                class: [
                                  {
                                    "top-line-group-text-active":
                                      _vm.topConditionCheckValue == item.value,
                                  },
                                ],
                                on: {
                                  click: function ($event) {
                                    return _vm.topLineGroupClick(index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            )
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "right-item-list" },
                        _vm._l(_vm.formData.params, function (item) {
                          return _c("TreeCondition", {
                            key: item.key,
                            attrs: { item: item },
                            on: { delete: _vm.deleteRow },
                          })
                        }),
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }