<template>
  <div class="source-channel">
    <div class="choosed-tag">
      <el-popover
        placement="bottom"
        width="300"
        trigger="click"
        @show="showPopover"
        @hide="hidePopover"
      >
        <el-tag
          :closable="false"
          size="small"
          :disable-transitions="true"
          slot="reference"
          style="cursor: pointer"
        >
          +选择部门
        </el-tag>

        <div class="department-select-el-tree" v-loading="treeDataLoading">
          <el-tree
            :data="list"
            show-checkbox
            node-key="departmentId"
            :default-checked-keys="vals"
            :props="defaultELTreeProps"
            @check="nodeCheck"
            ref="ElTree"
          >
          </el-tree>
        </div>
      </el-popover>

      <el-tag
        :closable="!disabled"
        v-for="item in choosedTags"
        :key="item.departmentId"
        @close="removeTag(item)"
        size="small"
        :disable-transitions="true"
      >
        {{ item.departmentName }}
      </el-tag>
    </div>
  </div>
</template>

<script>
import { getDepartments } from '@/api/toolkit';

export default {
  name: 'DepartmentSelect',
  props: {
    val: {
      type: Array,
      default: () => []
    },
    arr: {
      type: Array,
      default: () => []
    },
    valArrStr: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [],
      choosedTags: [],
      vals: [],
      defaultELTreeProps: {
        children: 'children',
        label: 'departmentName'
      },
      treeDataLoading: true
    };
  },
  watch: {
    arr: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.choosedTags = [...(newVal || [])];
      }
    },
    val: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.vals = [...(newVal || [])];
      }
    }
  },
  methods: {
    getDepartmentList() {
      this.treeDataLoading = true;

      getDepartments({
        data: {}
      })
        .then((res) => {
          this.list = [
            ...[
              { departmentId: -1, departmentName: '当前登录人部门' },
              { departmentId: -2, departmentName: '当前登录人下级部门' },
              { departmentId: -3, departmentName: '当前登录人同级部门' }
            ],
            ...(res.data || [])
          ];
        })
        .finally(() => {
          this.treeDataLoading = false;
        });
    },
    removeTag(item) {
      const idx = this.vals.indexOf(item.departmentId);
      idx > -1 && this.vals.splice(idx, 1);
      const index = this.choosedTags.indexOf(item);
      index > -1 && this.choosedTags.splice(index, 1);
      this.$refs.ElTree.setChecked(item.departmentId, false);
    },
    showPopover() {
      this.getDepartmentList();
    },
    nodeCheck() {
      const checkedNodes = this.$refs.ElTree.getCheckedNodes(true, false) || [];
      this.vals = checkedNodes.map((el) => el.departmentId);

      const arr = checkedNodes.map((x) => ({
        departmentId: x.departmentId,
        departmentName: x.departmentName
      }));

      this.choosedTags = arr;
      this.$emit('update:val', this.vals);
      this.$emit('update:valArrStr', this.vals.join(','));
      this.$emit('update:arr', arr);
    },
    hidePopover() {
      this.list = [];
      this.treeDataLoading = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.source-channel {
  max-height: 120px;
  overflow-y: auto;

  .choosed-tag {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;

    > span {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>

<style lang="scss">
.department-select-el-tree {
  width: 100%;
  height: 300px;
  overflow-y: auto;
}
</style>
