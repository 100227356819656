var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.confirmLoading,
          expression: "confirmLoading",
        },
      ],
      staticClass: "user-score",
    },
    [
      _c("div", { staticClass: "header" }, [
        _vm._v("* 为每一个客户近30天内的行为打分"),
      ]),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c("vxe-grid", {
            attrs: {
              border: "",
              data: _vm.tableData,
              columns: _vm.tableColumn,
              size: "small",
            },
            scopedSlots: _vm._u([
              {
                key: "operate",
                fn: function ({ row }) {
                  return [
                    _c("el-input-number", {
                      attrs: { min: 0, max: 100, precision: 0 },
                      model: {
                        value: row.dictionaryValue,
                        callback: function ($$v) {
                          _vm.$set(row, "dictionaryValue", $$v)
                        },
                        expression: "row.dictionaryValue",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("div", { staticClass: "footer-tip" }, [
        _vm._v(
          "* 客户有当前行为则得分，无当前行为则不得分，最终客户活跃度为各个行为得分总和"
        ),
      ]),
      _c(
        "footer",
        { staticClass: "footer" },
        [
          _c(
            "el-button",
            { staticClass: "btn", on: { click: _vm.handleReset } },
            [_vm._v("恢复默认值")]
          ),
          _c(
            "el-button",
            {
              staticClass: "btn",
              attrs: { type: "primary" },
              on: { click: _vm.handleSave },
            },
            [_vm._v("保存设置")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }