var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "source-channel" }, [
    _c(
      "div",
      { staticClass: "choosed-tag" },
      [
        _c(
          "el-popover",
          {
            attrs: { placement: "bottom", width: "400", trigger: "click" },
            on: { hide: _vm.hidePopover, show: _vm.showPopover },
          },
          [
            _c(
              "el-tag",
              {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  slot: "reference",
                  closable: false,
                  size: "small",
                  "disable-transitions": true,
                },
                slot: "reference",
              },
              [_vm._v(" +选择来源渠道 ")]
            ),
            _c(
              "div",
              { staticClass: "source-channel-el-select-wrap" },
              [
                _c("el-select-tree", {
                  ref: "tree",
                  staticStyle: { width: "200px" },
                  attrs: {
                    data: _vm.channelList,
                    props: {
                      label: "channelTypeName",
                      child: "children",
                      value: "channelTypeId",
                    },
                    size: "mini",
                    "check-strictly": "",
                    accordion: "",
                    placeholder: "全部",
                    disabled: _vm.disabled,
                  },
                  on: { change: _vm.channelTypeChange },
                  model: {
                    value: _vm.channelTypeId,
                    callback: function ($$v) {
                      _vm.channelTypeId = $$v
                    },
                    expression: "channelTypeId",
                  },
                }),
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      size: "mini",
                      placeholder: "全部",
                      multiple: "",
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.channelIds,
                      callback: function ($$v) {
                        _vm.channelIds = $$v
                      },
                      expression: "channelIds",
                    },
                  },
                  _vm._l(_vm.channeMainlList, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.channelName, value: item.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._l(_vm.choosedTags, function (item) {
          return _c(
            "el-tag",
            {
              key: item.id,
              attrs: {
                closable: !_vm.disabled,
                size: "small",
                "disable-transitions": true,
              },
              on: {
                close: function ($event) {
                  return _vm.removeTag(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.channelName) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }