import request from '@/utils/request';

// 获取组织机构树
export function getDepartments(data) {
  return request({
    url: "user-web-api/admin/department/tree",
    method: 'POST',
    data,
  })
}

export function addDepartment(data) {
  return request({
    url: "user-web-api/admin/department/add",
    method: 'POST',
    data,
  })
}

export function updateDepartment(data) {
  return request({
    url: "user-web-api/admin/department/edit",
    method: 'POST',
    data,
  })
}

export function setDepartmentStatus(data) {
  return request({
    url: "user-web-api/admin/department/isEnable",
    method: 'POST',
    data,
  })
}

export function getDepartmentDetail(data) {
  return request({
    url: "user-web-api/admin/department/queryById",
    method: 'POST',
    data,
  })
}

export default {}
