<template>
  <div>
    <el-drawer
      title="坐席设置"
      custom-class="draw-wrapper"
      :visible="isDrawerCall"
      size="75%"
      :before-close="handleCancel"
      :wrapperClosable="false"
      @open="onOpen"
    >
      <div class="draw-content">
        <div class="draw-switch">
          <span>是否开通坐席</span>
          <el-switch
            v-model="isTableListCall.isOpenSeats"
            active-color="#1890ff"
            inactive-color="#a0a1a3"
            :active-value="1"
            :inactive-value="0"
            :active-text="isTableListCall.isOpenSeats ? '是':'否'"
            @change="seatSwitchChange"
          >
          </el-switch>
        </div>
        <div class="draw-button">
          <el-button type="primary" @click="addSeat">新增</el-button>
          <el-button type="danger" @click="deleteSeat({},true)">删除</el-button>
        </div>
        <vxe-grid
          ref="SeatRef"
          class="draw-table"
          :data="dataSource"
          v-loading="listLoading"
          :columns="tableColumn"
          empty-text="暂无坐席数据"
          size="small"
          @checkbox-change="handleCheckboxChange"
          @checkbox-all="handleCheckboxAll"
        >
          <template #callApp="{ row }">
            <span>{{seatMap[row.callAppConfigId]}}</span>
          </template>
          <template #operate="{ row }">
            <el-button type="text" @click="editSeat(row)">编辑</el-button>
            <el-button type="text" @click="deleteSeat(row)">删除</el-button>
          </template>
        </vxe-grid>

        <div class="footer">
          <el-button @click="handleCancel">关 闭</el-button>
        </div>
      </div>
    </el-drawer>

    <!--  坐席新增修改-模态框  -->
    <SeatAddOrEdit ref="SeatAddOrEdit" :listByQuery="listByQuery" :optionsCall="optionsCall"
                   :userId="isTableListCall.userId" @getList="getList" />
  </div>

</template>
<script>
import {
  changeUserOpenSeats,
  getCcUserListByUserId,
  delByCcUser,
  getCallApp,
  getListByQuery
} from '@/api/user';
import SeatAddOrEdit from './SeatAddOrEdit';

export default {
  components: {
    SeatAddOrEdit
  },
  props: {
    isDrawerCall: {
      type: Boolean,
      default: false
    },
    isTableListCall: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      seatSwitch: false,// 坐席开关
      listLoading: false,// 加载中
      dataSource: [],// 坐席列表数据
      tableColumn: [// 坐席列表项配置
        { type: 'checkbox', width: 50 },
        { field: 'ccUserName', title: '坐席名称' },
        { field: 'callAppConfigId', title: '坐席渠道', slots: { default: 'callApp' } },
        { field: 'skillsetName', title: '呼叫技能组' },
        { field: 'outUserNo', title: '编号' },
        { field: 'outUserAccount', title: '账号' },
        { field: 'createTime', title: '创建时间' },
        { field: 'updateTime', title: '更新时间' },
        { title: '操作', slots: { default: 'operate' }, width: 120 }
      ],
      seatMap: {
        2: '优音',
        1: '得助'
      },
      optionsCall: [],// 坐席渠道
      listByQuery: [],// 优音技能组
      chockedList: []// 坐席列表选中项
    };
  },
  methods: {
    // 打开弹窗再执行
    onOpen() {
      this.getList();
      this.getCallAppApi();
      this.getListByQueryApi();
    },
    //  坐席设置-下拉选项框
    getCallAppApi() {
      getCallApp({ id: '' }).then((res) => {
        this.optionsCall = res;
      });
    },
    // 坐席设置-取消
    handleCancel() {
      this.$emit('isDrawerCall', false);
    },

    // 呼叫技能组-下拉选项框
    getListByQueryApi() {
      getListByQuery({ callAppConfigId: '2' }).then((res) => {
        this.listByQuery = res;
      });
    },

    //改变当前用户坐席状态
    async seatSwitchChange(val) {
      try {
        const params = {
          data: {
            userId: this.isTableListCall.userId,
            isOpenSeats: val
          }
        };
        const res = await changeUserOpenSeats(params);
        if (res.success === false) {
          return;
        }
        this.$message.success('操作成功');
      } catch (err) {
        console.log(err);
      }
    },

    // 获取坐席列表
    async getList() {
      try {
        this.listLoading = true;
        const res = await getCcUserListByUserId({ data: this.isTableListCall.userId });
        if (res.success === false) {
          return;
        }
        this.dataSource = res;
      } catch (err) {
        console.log(err);
      } finally {
        this.listLoading = false;
      }
    },
    handleCheckboxChange({ records }) {
      this.chockedList = records;
    },
    handleCheckboxAll({ records }) {
      this.chockedList = records;
    },
    //新增坐席
    addSeat() {
      this.$refs.SeatAddOrEdit.dialogStatus = 'create';
      this.$refs.SeatAddOrEdit.visible = true;
    },
    //编辑坐席
    editSeat(row) {
      this.$refs.SeatAddOrEdit.formData = { ...row };
      this.$refs.SeatAddOrEdit.dialogStatus = 'update';
      this.$refs.SeatAddOrEdit.visible = true;
    },
    //删除坐席
    async deleteSeat(row, batchDel = false) {
      try {
        const ccUserIds = batchDel ? this.chockedList.map(item => item.ccUserId) : [row.ccUserId];
        const { userId } = this.isTableListCall;
        const params = {
          data: {
            userId,
            ccUserIds
          }
        };

        const confirmMessage = batchDel
          ? `是否确认删除${this.chockedList.length}条选择项？`
          : '是否确认删除选择项？';

        await this.$confirm(confirmMessage, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        const res = await delByCcUser(params);
        if (res.success === false) {
          return;
        }

        this.$message.success('操作成功');
        await this.getList();
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.draw-content {
  display: flex;
  flex-direction: column;
  padding: 0 40px;
  height: 100%;

  .draw-switch {
    display: flex;
    align-items: center;

    span {
      font-weight: bold;
      margin-right: 20px;
    }
  }

  .draw-button {
    padding: 20px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #919191;
  }

  .draw-table {
    flex: 1;
    overflow: auto;
  }

  //.draw-form {
  //  flex: 1;
  //  overflow: auto;
  //  padding-right: 20px;
  //}

  .footer {
    display: flex;
    padding: 40px 0;

    .el-button {
      padding: 9px 40px;
    }
  }
}
</style>
