<template>
  <section class="user-score" v-loading="confirmLoading">
    <div class="header">* 为每一个客户近30天内的行为打分</div>
    <div class="table-wrapper">
      <vxe-grid border :data="tableData" :columns="tableColumn" size="small">
        <template #operate="{ row }">
          <el-input-number v-model="row.dictionaryValue" :min="0" :max="100"   :precision="0"/>
        </template>
      </vxe-grid>
    </div>
    <div class="footer-tip">* 客户有当前行为则得分，无当前行为则不得分，最终客户活跃度为各个行为得分总和</div>
    <footer class="footer">
      <el-button class="btn" @click="handleReset">恢复默认值</el-button>
      <el-button class="btn" type="primary" @click="handleSave">保存设置</el-button>
    </footer>
  </section>
</template>

<script>
import { getDireactionListByKey } from '@/api/dictionary'
import { saveScore } from '@/api/wechat'

export default {
  name: "User",
  data () {
    return {
      tableColumn: [
        { field: 'dictionaryKey', title: '行为ID' },
        { field: 'dictionaryName', title: '行为说明' },
        { field: 'dictionaryValue', title: '行为打分(正数)', slots: { default: 'operate' } },
      ],
      tableData: [],
      confirmLoading: false,
    }
  },
  mounted () {
    this.getScoreList()
  },
  methods: {
    async getScoreList () {
      const params = {
        data: {
          dictionaryKey: 'admin_behavior_description'
        }
      };
      const res = await getDireactionListByKey(params)
      this.tableData = res;

    },
    async saveScoreList (params) {
      try {
        const res = await saveScore(params)
        console.log(res)
        if (res.success === false) {
          this.$message.error(res.description)
          return
        }
        this.$message.success('操作成功')
        this.getScoreList()
        this.confirmLoading = false
      } catch (e) {
        this.confirmLoading = false
      }
    },
    handleReset () {
      if (this.confirmLoading) {
        return
      }
      this.confirmLoading = true;
      const list = [];
      const { parentId } = this.tableData[0];
      this.tableData.forEach(item => {
        const listitem = {
          dictionaryKey: item.dictionaryKey,
          dictionaryValue: 50
        }
        list.push(listitem)
      })

      const params = {
        data: {
          parentId,
          dictionaryKeyValueReqDto: list
        }
      }
      this.saveScoreList(params)
    },
    handleSave () {
      if (this.confirmLoading) {
        return
      }
      this.confirmLoading = true;
      const list = [];
      const { parentId } = this.tableData[0];
      this.tableData.forEach(item => {
        const listitem = {
          dictionaryKey: item.dictionaryKey,
          dictionaryValue: item.dictionaryValue
        }
        list.push(listitem)
      })
      const params = {
        data: {
          parentId,
          dictionaryKeyValueReqDto: list
        }
      }
      this.saveScoreList(params)
    }
  },

}
</script>

<style scoped lang="less">
.user-score {
  background: #fff;
  margin-top: 16px;
  padding: 0 20px;
  .header {
    padding: 10px 0;
    font-size: 14px;
    color: #9a9d9e;
  }
  .footer-tip {
    padding: 20px 0;
    font-size: 14px;
    color: #9a9d9e;
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .btn {
    }
    margin-right: 20px;
  }
}
</style>
