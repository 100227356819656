<template>
  <section class="side-content">
    <el-input placeholder="请输入专题名称" v-model="value"></el-input>
    <div style="width: 100%; text-align: center; margin-top: 10px">
      <el-button size="mini" @click="show = true">新增城市专题</el-button>
    </div>
    <div style="margin-top: 10px">
      <div
        class="custom-tree-node"
        v-for="(item, index) in list"
        :key="index"
        @click="handleNodeClick(item)"
        :class="checkedId === item.topicId ? 'is-select' : ''"
        v-show="!item.unShow"
      >
        <el-tooltip
          class="item"
          effect="dark"
          :content="item.topicName"
          :disabled="item.topicName.length <= 9"
        >
          <span>
            <span class="node-name"> {{ item.topicName }} </span>
            <span>（{{ item.cityCount }}）</span>
          </span>
        </el-tooltip>
        <el-dropdown
          v-show="checkedId === item.topicId"
          @command="(command) => handleCommand(command, item)"
        >
          <span class="el-dropdown-link">
            <span class="el-icon-setting"></span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">编辑分类</el-dropdown-item>
            <el-dropdown-item command="3">删除分类</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <subject-edit :show="show" :topicId="topicId" @ok="cityOk" @cancel="cityCancel" />
  </section>
</template>

<script>
import { cityProjectList, cityProjectDel } from '@/api/wechat';
import subjectEdit from './subjectEdit.vue';

export default {
  name: 'ProductListAside',
  components: {
    subjectEdit
  },
  props: {},
  data() {
    return {
      show: false,
      list: [],
      visible: false,
      checkedId: -1,
      parentId: 0,
      command: '0', // 用来判断新增，还是编辑以及删除
      topicId: '',
      value: ''
    };
  },
  watch: {
    value(val) {
      console.log(val);
      this.list.forEach((e) => {
        if (val !== '' && e.topicName.indexOf(val) === -1) {
          e.unShow = true;
        } else {
          e.unShow = false;
        }
      });
    }
  },
  async created() {
    await this.getList();
    if (this.list.length > 0) {
      this.$emit('change', this.list[0]);
      this.checkedId = this.list[0].topicId;
    }
  },
  computed: {
    isCanDelete() {
      if (this.list.length <= 1) {
        return false;
      }
      return true;
    }
  },
  methods: {
    cityOk() {
      this.topicId = '';
      this.show = false;
      this.getList();
    },
    cityCancel() {
      this.topicId = '';
      this.show = false;
    },
    // 获取产品分类树形结构
    async getList() {
      const response = await cityProjectList({ data: {} });
      this.list = [...response];
    },
    handleNodeClick(e) {
      this.checkedId = e.topicId;
      this.$emit('change', e);
    },

    handleCommand(command, data) {
      this.command = command;
      if (command === '2') {
        this.show = true;
        this.topicId = data.topicId;
        // this.$refs.EditProductType.isEdit = true;
        // // 编辑
        // this.$refs.EditProductType.form.categoryId = parseInt(data.categoryId, 10);
        // this.$refs.EditProductType.form.categoryName = data.categoryName;
        // this.$refs.EditProductType.form.sort = data.sort;
      }
      if (command === '3') {
        const params = {
          data: data.topicId
        };
        const message = '确定要删除吗？';
        this.$confirm(message, '确认提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.handleDeleteProductType(params);
          })
          .catch(() => {
            // this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });
          });
        // 删除
      }
    },
    handleHideEditType() {
      this.show = false;
    },
    handleOk() {
      this.getTree();
      this.show = false;
    },

    // 删除产品分类
    async handleDeleteProductType(params) {
      try {
        const response = await cityProjectDel(params);
        console.log(response);
        if (response) {
          this.getList();
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.side-content {
  width: 200px;
  padding: 0 10px;
  .custom-tree-node {
    height: 36px;
    line-height: 36px;
    position: relative;
    cursor: pointer;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100% !important;
    font-size: 14px;
    color: #000;
    .node-name {
      max-width: 140px;
      display: inline-block;
      word-wrap: break-word;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: bottom;
    }

    .el-icon-setting {
      position: absolute;
      width: 14px;
      height: 14px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      color: #2861e2;
    }
  }
  .is-select {
    background-color: #e9effc;
  }
}
</style>
