<template>
  <section class="channel-wrapper">
    <div class="side">
      <channel-tree @nodeSelect="nodeClick" />
    </div>
    <div class="channel-table">
      <channel-table :channelTypeId="channelTypeId" :isDisable="isDisable" />
    </div>
  </section>
</template>

<script>
import ChannelTree from './components/ChannelTree.vue';
import ChannelTable from './components/ChannelTable.vue';

export default {
  name: 'channel',
  components: {
    ChannelTree,
    ChannelTable
  },
  data() {
    return {
      channelTypeId: '',
      isDisable: 0
    };
  },
  methods: {
    nodeClick(data) {
      this.isDisable = data.isDisable;
      this.channelTypeId = data.channelTypeId;
    }
  }
};
</script>

<style scoped lang="less">
@import './channel.less';
</style>
