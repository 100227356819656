var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.show,
        width: "560px",
        "before-close": _vm.handleCancel,
      },
    },
    [
      _c(
        "div",
        { attrs: { loading: _vm.loading } },
        [
          _c(
            "el-form",
            {
              ref: "modalForm",
              attrs: {
                "label-width": "100px",
                model: _vm.form,
                rules: _vm.rules,
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "专题名称：", prop: "topicName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.topicName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "topicName", $$v)
                      },
                      expression: "form.topicName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "排序数字：", prop: "sortNum" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: 1, step: 1, "step-strictly": "" },
                    model: {
                      value: _vm.form.sortNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sortNum", $$v)
                      },
                      expression: "form.sortNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "适用城市：", prop: "cityIds" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.cityVisible = true
                        },
                      },
                    },
                    [_vm._v("点击选择适用城市")]
                  ),
                  _c(
                    "div",
                    { staticClass: "tags" },
                    _vm._l(_vm.cityNames, function (tag, index) {
                      return _c(
                        "el-tag",
                        {
                          key: index,
                          staticStyle: {
                            "margin-right": "10px",
                            "margin-bottom": "10px",
                          },
                          attrs: { closable: "" },
                          on: { close: () => _vm.handleCityTagClose(index) },
                        },
                        [_vm._v(_vm._s(tag))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.inputLoad },
              on: { click: _vm.createProductType },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _c("city-mange", {
        attrs: { visible: _vm.cityVisible, value: _vm.form.cityIds },
        on: { ok: _vm.cityOk, cancel: _vm.cityCancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }