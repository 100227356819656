var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "sample-list" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: {
                model: _vm.searchForm,
                "show-message": false,
                size: "mini",
                inline: "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "200px" },
                  attrs: { label: "样片类型" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "样片类型" },
                      model: {
                        value: _vm.searchForm.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "categoryId", $$v)
                        },
                        expression: "searchForm.categoryId",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "" } }, [
                        _vm._v("全部"),
                      ]),
                      _vm._l(_vm.categoryList, function (item) {
                        return _c("el-option", {
                          key: item.categoryId,
                          attrs: {
                            value: item.categoryId,
                            label: item.categoryName,
                          },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "200px" },
                  attrs: { label: "样片ID：" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "样片ID" },
                    model: {
                      value: _vm.searchForm.sampleCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "sampleCode", $$v)
                      },
                      expression: "searchForm.sampleCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "200px" },
                  attrs: { label: "样片名称：" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "样片名称" },
                    model: {
                      value: _vm.searchForm.sampleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.searchForm, "sampleName", $$v)
                      },
                      expression: "searchForm.sampleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "200px" },
                  attrs: { label: "是否授权：" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "是否授权" },
                      model: {
                        value: _vm.searchForm.isAuthorize,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchForm, "isAuthorize", $$v)
                        },
                        expression: "searchForm.isAuthorize",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "", label: "全部" } }),
                      _c("el-option", { attrs: { value: 1, label: "是" } }),
                      _c("el-option", { attrs: { value: 0, label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tags-wrapper" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.handleSearch },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.handleReset } },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticClass: "table",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "header-cell-style": { "background-color": "#f5f7fa" },
            size: "mini",
            "max-height": "350",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _c("el-table-column", {
            attrs: { prop: "sampleCode", label: "样片ID", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { prop: "sampleName", label: "样片名称", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { prop: "categoryName", label: "样片类型" },
          }),
          _c("el-table-column", {
            attrs: { label: "封面图", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "60px", height: "60px" },
                      attrs: {
                        src: _vm.handleImageUrl(scope.row.coverPictureUrl),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total,prev,pager,next,sizes",
              total: _vm.total,
              "page-sizes": [10, 50, 100, 200],
              "current-page": _vm.pageNumber,
              "page-size": _vm.pageSize,
            },
            on: {
              "size-change": _vm.pageSizeChange,
              "current-change": _vm.pageChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }