var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "source-channel" }, [
    _c(
      "div",
      { staticClass: "choosed-tag" },
      [
        _c(
          "el-popover",
          {
            attrs: { placement: "bottom", width: "300", trigger: "click" },
            on: { show: _vm.showPopover, hide: _vm.hidePopover },
          },
          [
            _c(
              "el-tag",
              {
                staticStyle: { cursor: "pointer" },
                attrs: {
                  slot: "reference",
                  closable: false,
                  size: "small",
                  "disable-transitions": true,
                },
                slot: "reference",
              },
              [_vm._v(" +选择部门 ")]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.treeDataLoading,
                    expression: "treeDataLoading",
                  },
                ],
                staticClass: "department-select-el-tree",
              },
              [
                _c("el-tree", {
                  ref: "ElTree",
                  attrs: {
                    data: _vm.list,
                    "show-checkbox": "",
                    "node-key": "departmentId",
                    "default-checked-keys": _vm.vals,
                    props: _vm.defaultELTreeProps,
                  },
                  on: { check: _vm.nodeCheck },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._l(_vm.choosedTags, function (item) {
          return _c(
            "el-tag",
            {
              key: item.departmentId,
              attrs: {
                closable: !_vm.disabled,
                size: "small",
                "disable-transitions": true,
              },
              on: {
                close: function ($event) {
                  return _vm.removeTag(item)
                },
              },
            },
            [_vm._v(" " + _vm._s(item.departmentName) + " ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }