import { unionBy, remove } from 'lodash';
import { postAction, deleteAction } from '@/api/manage';

const TreeMinix = {
  data() {
    return {
      loading: false,
      isShowLoading: false,
      treeData: [],
      queryParams: {}, // 其他查询参数
      searchForm: {}, // 表单查询参数
      url: {
        list: ''
      },
      nodeKey: '',
      props: {}, // tree 配置项
      checkedId: -1
    };
  },
  created() {
    this.getTreeList();
  },
  methods: {
    handleSearch() {
      this.getTreeList();
    },
    handleRest() {
      this.getTreeList();
    },
    handleNodeClick(data, key) {
      this.checkedId = data[key];
      this.$emit('nodeSelect', data);
    },

    getQueryParams() {
      return {
        data: { ...this.searchForm, ...this.queryParams }
      };
    },
    async getTreeList() {
      try {
        if (this.isShowLoading) {
          this.loading = true;
        }
        const params = this.getQueryParams();
        const res = await postAction(this.url.list, params);
        if (res.success === false) {
          this.$message.error(res.description);
        }
        this.loading = false;
        this.treeData = res;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },

    // 启用禁用
    async handleSingleSetIsDisable(data) {
      try {
        const res = await postAction(this.url.status, data);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        await this.getTreeList();
      } catch (err) {
        console.log(err);
      }
    },

    // 删除
    async handleDelete(params, options = {}) {
      try {
        const res = await deleteAction(this.url.del, params, options);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        await this.getTreeList();
        this.$message.success('删除成功');
      } catch (e) {
        console.log(e);
      }
    }
  }
};

export default TreeMinix;
