var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.visible,
        "before-close": _vm.handleCancel,
        "destroy-on-close": true,
        width: "700px",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "modalFrom",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-width": "140px",
            size: "small",
          },
        },
        [
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { label: "节点ID：", prop: "nodeId" },
            },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.nodeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nodeId", $$v)
                  },
                  expression: "form.nodeId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点类型：", prop: "nodeType", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.isEdit },
                  model: {
                    value: _vm.form.nodeType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "nodeType", $$v)
                    },
                    expression: "form.nodeType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("菜单")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("操作")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点端口：", prop: "nodePort", required: "" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.nodePort,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "nodePort", $$v)
                    },
                    expression: "form.nodePort",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("运营端")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("企微端")]),
                  _c("el-radio", { attrs: { label: 3 } }, [_vm._v("供应端")]),
                  _c("el-radio", { attrs: { label: 4 } }, [_vm._v("企业端")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "节点名称：", prop: "nodeName" } },
            [
              _c("el-input", {
                attrs: { disable: "" },
                model: {
                  value: _vm.form.nodeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "nodeName", $$v)
                  },
                  expression: "form.nodeName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "父级节点：", prop: "parentId" } },
            [
              _c("el-select-tree", {
                style: { width: "100%" },
                attrs: {
                  placeholder: "请选择内容",
                  data: _vm.menuTree,
                  "check-strictly": "",
                  props: {
                    label: "nodeName",
                    children: "subList",
                    value: "nodeId",
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "option",
                    fn: function ({ data }) {
                      return _c("span", {}, [
                        _vm._v(" " + _vm._s(data.nodeName) + " "),
                        data.nodePort === 1
                          ? _c("span", [_vm._v("- 运营端")])
                          : _vm._e(),
                        data.nodePort === 2
                          ? _c("span", [_vm._v("- 企微端")])
                          : _vm._e(),
                        data.nodePort === 3
                          ? _c("span", [_vm._v("- 供应端")])
                          : _vm._e(),
                        data.nodePort === 4
                          ? _c("span", [_vm._v("- 企业端")])
                          : _vm._e(),
                      ])
                    },
                  },
                ]),
                model: {
                  value: _vm.form.parentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "parentId", $$v)
                  },
                  expression: "form.parentId",
                },
              }),
            ],
            1
          ),
          _vm.form.nodeType === 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    required: "",
                    label: "授权标识：",
                    prop: "nodeCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "授权表示唯一不能重复" },
                    model: {
                      value: _vm.form.nodeCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "nodeCode", $$v)
                      },
                      expression: "form.nodeCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "路由地址：", prop: "path" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.path,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1 &&
          (_vm.form.nodePort === 1 || _vm.form.nodePort === 3)
            ? _c(
                "el-form-item",
                { attrs: { label: "微应用名称：", prop: "mircoName" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mircoName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mircoName", $$v)
                      },
                      expression: "form.mircoName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1 &&
          (_vm.form.nodePort === 1 || _vm.form.nodePort === 3)
            ? _c(
                "el-form-item",
                { attrs: { label: "微应用入口地址：", prop: "mircoEntry" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.mircoEntry,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mircoEntry", $$v)
                      },
                      expression: "form.mircoEntry",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "是否需要数据权限：",
                prop: "isNeedDataPermission",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.isNeedDataPermission,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isNeedDataPermission", $$v)
                    },
                    expression: "form.isNeedDataPermission",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                ],
                1
              ),
            ],
            1
          ),
          false
            ? _c(
                "el-form-item",
                { attrs: { label: "是否缓存：", prop: "noCache" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.noCache,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "noCache", $$v)
                        },
                        expression: "form.noCache",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1 &&
          (_vm.form.nodePort === 1 ||
            _vm.form.nodePort === 3 ||
            _vm.form.nodePort === 4)
            ? _c(
                "el-form-item",
                { attrs: { label: "是否在面包屑中隐藏：", prop: "hideBread" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.hideBread,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hideBread", $$v)
                        },
                        expression: "form.hideBread",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "隐藏路由：", prop: "hidden" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.hidden,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "hidden", $$v)
                        },
                        expression: "form.hidden",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          false
            ? _c(
                "el-form-item",
                { attrs: { label: "重定向：", prop: "redirect" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.redirect,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "redirect", $$v)
                      },
                      expression: "form.redirect",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1 && _vm.form.parentId === "0"
            ? _c(
                "el-form-item",
                { attrs: { label: "图标：", prop: "icon" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.icon,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "icon", $$v)
                      },
                      expression: "form.icon",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.form.nodeType === 1
            ? _c(
                "el-form-item",
                { attrs: { label: "排序：", prop: "sort" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.sort,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleOk },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }