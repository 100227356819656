var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { model: _vm.form, size: "small", inline: "" },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "类型：", prop: "type" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: "" } }),
                  _c("el-option", { attrs: { label: "建议", value: 2 } }),
                  _c("el-option", { attrs: { label: "反馈", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "客户ID：", prop: "customerId" },
            },
            [
              _c("el-input", {
                staticClass: "form-item-input",
                staticStyle: { width: "120px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.customerId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "customerId", $$v)
                  },
                  expression: "form.customerId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "form-item",
              attrs: { label: "用户手机号：", prop: "phone" },
            },
            [
              _c("el-input", {
                staticClass: "form-item-input",
                staticStyle: { width: "120px" },
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "form-item", attrs: { label: "提交时间：" } },
            [
              _c("el-date-picker", {
                staticClass: "form-item-time",
                staticStyle: { width: "320px" },
                attrs: {
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                },
                model: {
                  value: _vm.time,
                  callback: function ($$v) {
                    _vm.time = $$v
                  },
                  expression: "time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "form-item", attrs: { "label-width": "30px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.search },
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }