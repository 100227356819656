<template>
  <el-form :model="form" class="demo-form-inline" size="small" inline ref="searchForm">
    <el-form-item label="键：" class="form-item" prop="dictionaryKey">
      <el-input
        placeholder="请输入"
        class="form-item-input"
        v-model="form.dictionaryKey"
      ></el-input>
    </el-form-item>
    <el-form-item label="名称：" class="form-item" prop="dictionaryName">
      <el-input
        placeholder="请输入"
        class="form-item-input"
        v-model="form.dictionaryName"
      ></el-input>
    </el-form-item>
    <el-form-item class="form-item" label-width="30px">
      <el-button size="small" type="primary" @click="search">查询</el-button>
      <el-button size="small" @click="reset('searchForm')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'page-header',
  components: {},
  data() {
    return {
      form: {
        dictionaryKey: '',
        dictionaryName: ''
      }
    };
  },
  methods: {
    search() {
      console.log('搜索');
      this.$emit('search', this.form);
    },
    reset(searchForm) {
      console.log('重置');
      this.$refs[searchForm].resetFields();
      this.$emit('reset', this.form);
    }
  }
};
</script>
<style lang="less" scoped>
@import './search.less';
</style>
