var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "manage-options" },
    [
      _c("div", { staticClass: "title-bar" }, [
        _c("h4", { staticClass: "on" }, [_vm._v("分类管理")]),
        _c("h4", { on: { click: _vm.openFilterOptions } }, [
          _vm._v("筛选配置"),
        ]),
      ]),
      _c(
        "el-button",
        {
          staticClass: "add-button",
          attrs: { icon: "el-icon-plus", size: "mini" },
          on: {
            click: function ($event) {
              return _vm.handleOptionsCommand("sibling", {}, {})
            },
          },
        },
        [_vm._v(" 添加分类 ")]
      ),
      _c("el-tree", {
        attrs: {
          data: _vm.treeList,
          "show-checkbox": false,
          "node-key": "configurationId",
          "default-expand-all": "",
          "expand-on-click-node": false,
          props: _vm.treeProps,
        },
        on: { "node-click": _vm.treeNodeClick },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ node, data }) {
              return _c(
                "span",
                {
                  staticClass: "custom-tree-node",
                  style: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "space-between",
                    width: "100%",
                    color: node.isCurrent ? "#2861E2" : "",
                    padding: "0 10px 0 0",
                    "user-select": "none",
                    overflow: "hidden",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "white-space": "nowrap",
                        overflow: "hidden",
                        "text-overflow": "ellipsis",
                        "max-width": "80%",
                      },
                    },
                    [_vm._v(" " + _vm._s(data.configurationName) + " ")]
                  ),
                  node.isCurrent
                    ? _c(
                        "el-dropdown",
                        {
                          attrs: { trigger: "click" },
                          on: {
                            command: function ($event) {
                              return _vm.handleOptionsCommand(
                                $event,
                                node,
                                data
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-setting" }),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "sibling" } },
                                [_vm._v("新建同级")]
                              ),
                              node.level < 3
                                ? _c(
                                    "el-dropdown-item",
                                    { attrs: { command: "next" } },
                                    [_vm._v("新建下级")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "edit" } },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "el-dropdown-item",
                                { attrs: { command: "del" } },
                                [_vm._v("删除")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.formDialogTitle,
            visible: _vm.formDialogVisible,
            width: "500px",
            top: "15vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "OptionsForm",
              attrs: {
                model: _vm.optionsForm,
                "status-icon": "",
                rules: _vm.optionsRules,
                "label-width": "100px",
                size: "mini",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分类ID：" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "none", disabled: "" },
                    model: {
                      value: _vm.optionsForm.configurationId,
                      callback: function ($$v) {
                        _vm.$set(_vm.optionsForm, "configurationId", $$v)
                      },
                      expression: "optionsForm.configurationId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类名称：", prop: "configurationName" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "none" },
                    model: {
                      value: _vm.optionsForm.configurationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.optionsForm, "configurationName", $$v)
                      },
                      expression: "optionsForm.configurationName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分类排序：", prop: "configurationSort" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { min: 1, max: 10 },
                    model: {
                      value: _vm.optionsForm.configurationSort,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.optionsForm,
                          "configurationSort",
                          _vm._n($$v)
                        )
                      },
                      expression: "optionsForm.configurationSort",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: { click: _vm.handleFormDialogCancel },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleFormDialogOk },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("FilterOptionsDialog", {
        ref: "FilterOptionsDialog",
        on: { refresh: _vm.filtersRefresh },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }