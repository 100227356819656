<template>
  <el-dialog title="数据范围配置" :visible.sync="dialogVisible" width="900px" top="30px">
    <el-form label-width="80px" size="mini">
      <el-form-item label="状态：">
        <el-radio-group v-model="formData.isDisable">
          <el-radio :label="0">启用</el-radio>
          <el-radio :label="1">停用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="条件：">
        <div class="condition-tips">设置条件后，仅满足以下条件的数据对当前角色可见</div>

        <div class="tree-condition">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="addRow">
            新增条件分组
          </el-button>

          <div class="tree-content" v-if="showTreeConditionList">
            <div class="top-line-group">
              <div class="top-line-group-text">
                <span
                  v-for="(item, index) in conditionWordsArr"
                  :key="index"
                  @click="topLineGroupClick(index)"
                  :class="[{ 'top-line-group-text-active': topConditionCheckValue == item.value }]"
                >
                  {{ item.label }}
                </span>
              </div>
            </div>

            <div class="right-item-list">
              <TreeCondition
                v-for="item in formData.params"
                :key="item.key"
                :item="item"
                @delete="deleteRow"
              />
            </div>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="submit" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TreeCondition from './TreeCondition.vue';
import { saveDataCondition, getDataCondition } from '@/api/roleMenuManage';
import { getDataConfigList } from '@/api/roleMenuManage';

export default {
  components: {
    TreeCondition
  },
  props: {
    nodeId: {
      type: [String, Number],
      default: ''
    }
  },
  provide() {
    return { fieldOptionList: () => this.fieldOptions };
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.getDetail();
        this.getFieldList();
        return;
      }

      this.formData = {
        isDisable: 0,
        relations: 'and',
        params: []
      };
    }
  },
  data() {
    return {
      dialogVisible: false,
      conditionWordsArr: [
        { label: '且', value: 'and' },
        { label: '或', value: 'or' }
      ],
      formData: {
        infoId: '',
        isDisable: 0,
        relations: 'and',
        params: []
      },
      fieldOptions: []
    };
  },
  computed: {
    topConditionCheckValue() {
      return this.formData.relations;
    },
    showTreeConditionList() {
      return this.formData.params.length > 0;
    },
    roleId() {
      return new URLSearchParams(window.location.search).get('roleId') || '';
    }
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    submit() {
      if (this.formData.params.length == 0) {
        this.$message.warning('条件不能为空');
        return;
      }

      const nullFlag = this.formData.params.some((d) => {
        return d.params.some((el) => !el.databaseFieldName || !el.logic);
      });

      if (nullFlag) {
        this.$message.warning('请检查字段名称或运算符是否有为空');
        return;
      }

      const { isDisable, infoId, ...params } = this.formData;
      const data = {
        roleId: this.roleId,
        nodeId: this.nodeId,
        isDisable,
        params,
        infoId
      };

      saveDataCondition({ data: data })
        .then((res) => {
          if (res) {
            this.$message.success('操作成功');
            this.$emit('refresh');
            this.close();
          }
        })
        .catch((err) => console.log(err));
    },
    getDetail() {
      getDataCondition({ data: { roleId: this.roleId, nodeId: this.nodeId } })
        .then((res) => {
          Object.keys(res || {}).forEach((key) => {
            if (!res[key]) return;

            if (key == 'params') {
              this.formData = { ...this.formData, ...(res.params || {}) };
              return;
            }

            this.formData[key] = res[key];
          });
        })
        .catch((err) => console.log(err));
    },
    topLineGroupClick(index) {
      this.formData.relations = this.conditionWordsArr[index].value;
    },
    addRow() {
      this.formData.params.push({ key: Date.now(), relations: 'and', params: [] });
    },
    deleteRow(item) {
      this.formData.params.splice(this.formData.params.indexOf(item), 1);
    },
    getFieldList() {
      getDataConfigList({ data: this.nodeId })
        .then((res) => {
          const obj = (res || []).map((x) => ({
            ...x,
            label: x.fieldName,
            value: x.databaseFieldName
          }));

          this.fieldOptions = obj;
        })
        .catch((err) => console.log(err));
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
}

.condition-tips {
  color: #f57939;
  font-size: 14px;
}

.tree-condition {
  box-sizing: border-box;
  padding-top: 10px;

  .tree-content {
    display: flex;
    flex-direction: row;
    margin-top: 40px;

    > div {
      flex-shrink: 0;
    }

    .top-line-group {
      width: 1px;
      position: relative;
      height: auto;
      background-color: rgb(192 196 204 / 60%);

      &-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        writing-mode: vertical-lr;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 6px;
        user-select: none;
        overflow: hidden;

        > span {
          display: inline;
          background-color: #f5f5f5;
          color: #9a9d9e;
          padding: 4px;
          font-size: 13px;
          font-weight: 400;
        }

        &-active {
          background-color: #e9effc !important;
          color: #2861e2 !important;
        }
      }
    }

    .right-item-list {
      flex: 1;
      margin-left: 40px;
    }
  }
}
</style>
