var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content-edit" },
    [
      _c("div", { staticClass: "submit-bar" }, [
        _c("span", { staticClass: "title" }, [_vm._v("设置内容")]),
        _c(
          "div",
          { staticClass: "submit-btns" },
          [
            _c(
              "el-button",
              {
                attrs: { size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.submitContent(0)
                  },
                },
              },
              [_vm._v("保存")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: {
                  click: function ($event) {
                    return _vm.submitContent(1)
                  },
                },
              },
              [_vm._v("发布")]
            ),
          ],
          1
        ),
      ]),
      _c("el-divider"),
      _c("div", { staticClass: "submit-bar" }, [
        _c("span", { staticClass: "sub-title" }, [
          _vm._v(_vm._s(_vm.treeNodeItem.configurationName)),
        ]),
        _c(
          "div",
          { staticClass: "submit-btns" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "text", icon: "el-icon-plus", size: "small" },
                on: { click: _vm.addItemClick },
              },
              [_vm._v(" 添加 ")]
            ),
          ],
          1
        ),
      ]),
      _vm.list.length > 0
        ? _vm._l(_vm.list, function (item, index) {
            return _c("ContentItem", {
              key: item.configurationSubId,
              ref: `contentItem${item.configurationSubId}`,
              refInFor: true,
              attrs: { item: item, filterList: _vm.filterList },
              on: {
                delItemClick: function ($event) {
                  return _vm.delItemClick(item, index)
                },
              },
            })
          })
        : _c("el-empty", { attrs: { description: "暂无数据" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }