var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "position-wrapper" },
    [
      _c("search", { on: { search: _vm.handleSearch, reset: _vm.handleRest } }),
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _c(
            "vxe-grid",
            _vm._b(
              {
                attrs: {
                  border: "",
                  data: _vm.dataSource,
                  columns: _vm.tableColumn,
                  size: "small",
                },
                scopedSlots: _vm._u([
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowDetail(row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "customerId",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleJump(row.customerId)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.customerId))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "pager",
                    fn: function () {
                      return [
                        _c("i-pagination", {
                          attrs: {
                            "page-size": _vm.iPagination.pageSize,
                            "current-page": _vm.iPagination.currentPage,
                            "page-sizes": [10, 20, 30, 40],
                            layout: "total,prev, pager, next ,sizes,jumper",
                            total: _vm.iPagination.total,
                          },
                          on: {
                            "current-change": _vm.handlePageChange,
                            "size-change": _vm.handleSizeChange,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              "vxe-grid",
              _vm.tableOptions,
              false
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }