<template>
  <div id="richWrapper"></div>
</template>

<script>
import WangEditor from 'wangeditor';

export default {
  name: 'RichText',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      editor: null,
      editorData: ''
    };
  },
  watch:{
    // value(newVal) {
    //   this.editorData = newVal;
    // },
  },

  mounted() {
    const editor = new WangEditor(`#richWrapper`);
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.editorData = newHtml;
      this.$emit('change', newHtml);
    };
    editor.config.onchangeTimeout = 500;
    // 创建编辑器
    editor.create();
    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    getEditorData() {
      // 通过代码获取编辑器内容
      const data = this.editor.txt.html();
      console.log(data);
    },
    setEditData(val){
      this.editor.text(val)
    }
  }
};
</script>

<style lang="scss" scoped></style>
