var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "preview-box" }, [
    _vm.bannerList.length > 0
      ? _c("div", { staticClass: "banner-box" }, [
          _c("i", { staticClass: "el-icon-picture img" }),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "navigation-box" },
      _vm._l(_vm.navigationList, function (nav, index) {
        return _c("div", { key: index, staticClass: "navigation-item" }, [
          _c("i", { staticClass: "el-icon-picture img" }),
          _c("div", { staticClass: "1" }, [_vm._v(_vm._s(nav.name))]),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "recommend-box" },
      _vm._l(_vm.recommandList, function (area, index) {
        return _c("div", { key: index, staticClass: "recommend-item" }, [
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(area.title))]),
          _c("div", { staticClass: "sub-title" }, [
            _vm._v(_vm._s(area.subject)),
          ]),
          _vm._m(0, true),
        ])
      }),
      0
    ),
    _vm.brandData.length > 0
      ? _c(
          "div",
          { staticClass: "brand-box" },
          [
            _c(
              "div",
              {
                staticClass: "brand-tab",
                style:
                  _vm.brandData.length > 4
                    ? ""
                    : "justify-content: space-around",
              },
              _vm._l(_vm.brandData, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "brand-name-item" },
                  [_vm._v(" " + _vm._s(item.recommendBrandName) + " ")]
                )
              }),
              0
            ),
            _vm._l(_vm.brandData, function (item, index) {
              return _c("div", { key: index, staticClass: "brand-item" }, [
                _c("div", { staticClass: "brand-name" }, [
                  _vm._v(_vm._s(item.recommendBrandName)),
                ]),
                _vm._m(1, true),
              ])
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img" }, [
      _c("i", { staticClass: "el-icon-picture img" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "img" }, [
      _c("i", { staticClass: "el-icon-picture img" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }