<template>
  <section class="tree-wrapper">
    <header class="search">
      <el-form inline :model="searchForm" size="small">
        <el-form-item prop="dictionaryKey">
          <el-input
            v-model="searchForm.dictionaryKey"
            placeholder="请输入键"
            style="width: 100px"
          />
        </el-form-item>
        <el-form-item prop="dictionaryName">
          <el-input
            v-model="searchForm.dictionaryName"
            placeholder="请输入类型名称"
            style="width: 130px"
          />
        </el-form-item>
      </el-form>
    </header>
    <article class="tree-wrapper">
      <div class="tree-header">
        <div class="key">键</div>
        <div class="value" style="padding-left: 10px">类型名称</div>
      </div>
      <el-tree
        :data="treeData"
        :props="props"
        @node-click="(data) => handleNodeClick(data, 'dictionaryId')"
        :expand-on-click-node="false"
        class="tree-custom"
        default-expand-all
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div>
            <span class="key">{{ data.dictionaryKey }}</span>
            <span class="value">{{ data.dictionaryName }}</span>
          </div>
          <el-dropdown
            v-show="checkedId === data.dictionaryId"
            @command="(command) => handleCommand(command, data)"
          >
            <span class="el-dropdown-link">
              <span class="el-icon-setting"></span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1">新建同级类型</el-dropdown-item>
              <el-dropdown-item :command="2">编辑类型</el-dropdown-item>
                           <el-dropdown-item :command="3">删除类型</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-tree>
    </article>
    <edit-dictionary-modal ref="EditDictionaryModal" @ok="handleOk" />
  </section>
</template>

<script>
import EditDictionaryModal from './EditDictionaryModal.vue';
import TreeMinix from '@/minixs/TreeMinix';
import { deleteDictionary } from '@/api/dictionary';

export default {
  name: 'DictionaryTypeTree',
  mixins: [TreeMinix],
  components: {
    EditDictionaryModal
  },
  data() {
    return {
      treeData: [],
      departmentTree: [],
      searchForm: {
        dictionaryKey: '',
        dictionaryName: ''
      },
      props: {
        label: 'dictionaryName',
        value: 'dictionaryKey'
      },
      url: {
        list: 'misc-web-api/admin/dictionary/parentDictionarys'
      },
      visible: false,
      checkedId: -1
    };
  },
  mounted() {},
  watch: {
    'searchForm.dictionaryName': {
      handler(newVal) {
        console.log(newVal);
        this.getTreeList();
      }
    },
    'searchForm.dictionaryKey': {
      handler(newVal) {
        console.log(newVal);
        this.getTreeList();
      }
    }
  },
  methods: {
    handleCommand(command, node) {
      if (command === 1) {
        // 新增字典
        this.$refs.EditDictionaryModal.isShowSelectParent = false;
        this.$refs.EditDictionaryModal.form.parentId = '0';
        this.$refs.EditDictionaryModal.add();
      }
      if (command === 2) {
        this.$refs.EditDictionaryModal.isShowSelectParent = false;
        this.$refs.EditDictionaryModal.edit(node);
      }
      if (command === 3) {
        this.handleDeleteClassify(node);
      }
    },
    async delete(params) {
      try {
        const res = await deleteDictionary(params);
        console.log(res);
        await this.getTreeList();
        this.$message.success('删除成功');
      } catch (e) {
        console.log(e);
      }
    },
    handleDeleteClassify(node) {
      const that = this;
      const params = {
        data: { dictionaryId: node.dictionaryId }
      };
      console.log(params);
      const message = '确定要删除吗？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.delete(params);
        })
        .catch(() => {});
    },

    handleOk() {
      this.getTreeList();
    }
  }
};
</script>

<style scoped lang="less">
.tree-wrapper {
  .search {
    padding: 15px 0px 0 20px;
  }
  .tree-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    font-size: 14px;
    color: #999;
    .key {
      width: 100px;
      word-spacing: break;
    }
    .value {
      margin-left: 1px;
      flex: 1;
    }
  }

  /deep/ .el-tree-node__content {
    height: 40px !important;

    .custom-tree-node {
      padding-right: 10px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      font-size: 14px;
      .key {
        display: inline-block;
        width: 100px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
      .value {
        margin-left: 10px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
      .status {
        display: inline-block;
        margin-right: 10px;
        color: #00a199;
      }

      .status-stop {
        margin-right: 10px;
        color: #e86247;
      }

      .el-icon-setting {
        color: #2861e2;
      }
    }
  }
  ::v-deep.is-current .el-tree-node__content {
    background-color: #e9effc;
  }
}
</style>
