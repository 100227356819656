<template>
  <section class="department-wrapper">
    <div class="side">
      <department-tree @selectDepartment="selectDepartment" />
    </div>
    <div class="department-table">
      <department-info v-if="currentDepartment" :departmentId="currentDepartment.departmentId" />
      <department-table  :departmentId="currentDepartment.departmentId" />
    </div>
  </section>
</template>

<script>
import DepartmentTree from './components/DepartmentTree.vue';
import DepartmentInfo from './components/DepartmentInfo.vue';
import DepartmentTable from './components/DepartmentTable.vue';

export default {
  name: 'Department',
  data() {
    return {
      currentDepartment: '',
      treeArr: []
    };
  },
  components: {
    DepartmentTree,
    DepartmentInfo,
    DepartmentTable
  },
  methods: {
    selectDepartment(data) {
      this.currentDepartment = data;
    }
  }
};
</script>

<style scoped lang="less">
@import './department.less';
</style>
