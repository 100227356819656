var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "altitude",
      staticClass: "banner-list-wrapper",
    },
    [
      _vm.form.bannerList.length === 0 ? _c("el-empty") : _vm._e(),
      _vm.form.bannerList.length > 0
        ? _c(
            "div",
            { staticStyle: { margin: "18px 0" } },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    color: "#606266",
                    "font-size": "14px",
                    "font-weight": "bold",
                  },
                },
                [_vm._v("图片展示样式：")]
              ),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.layoutType,
                    callback: function ($$v) {
                      _vm.layoutType = $$v
                    },
                    expression: "layoutType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("横屏")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("竖屏")]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.form.bannerList.length > 0
        ? _c(
            "el-form",
            {
              ref: "bannerForm",
              attrs: {
                "label-width": "84px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            _vm._l(_vm.form.bannerList, function (banner, index) {
              return _c(
                "section",
                { key: banner.bannerId, staticClass: "banner-list-item" },
                [
                  _c("div", { staticClass: "delete-area" }, [
                    _c("i", {
                      staticClass: "el-icon-delete delete",
                      on: {
                        click: function ($event) {
                          return _vm.handleDeleteBanner(banner, index)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "图片或视频：",
                        prop: "bannerList." + index + ".picUrl",
                        rules: {
                          required: true,
                          message: "请上传图片",
                          trigger: "blur",
                        },
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            accept: ".jpg,.png,.jpeg,.mp4,.avi,.mov,.mkv",
                            action: _vm.actionUrl,
                            "show-file-list": false,
                            "http-request": (event) =>
                              _vm.handleOnProgress(event, index),
                            "before-upload": _vm.beforeUpload,
                          },
                        },
                        [
                          _vm.checkMediaType(banner.picUrl) == "image"
                            ? _c("img", {
                                style: { width: "150px" },
                                attrs: { src: banner.picUrl },
                              })
                            : _vm._e(),
                          _vm.checkMediaType(banner.picUrl) == "video"
                            ? _c("video", {
                                attrs: {
                                  width: "150",
                                  src: banner.picUrl,
                                  autoplay: false,
                                },
                              })
                            : _vm._e(),
                          !banner.picUrl
                            ? _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              })
                            : _vm._e(),
                        ]
                      ),
                      _c("div", { staticClass: "upload-tip" }, [
                        _c("i", { staticClass: "el-icon-info" }),
                        _vm._v(
                          " 图片支持jpg/png格式，视频支持mp4/avi格式，文件不能超过5M，横屏尺寸375px*375px，竖屏比例1:2 "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "自定义链接：",
                        prop: "bannerList." + index + ".productId",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "url" },
                        model: {
                          value: banner.productId,
                          callback: function ($$v) {
                            _vm.$set(banner, "productId", $$v)
                          },
                          expression: "banner.productId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序：", prop: "sortNum" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "100%" },
                        attrs: { min: 1, max: 9999, label: "排序" },
                        model: {
                          value: banner.sortNum,
                          callback: function ($$v) {
                            _vm.$set(banner, "sortNum", $$v)
                          },
                          expression: "banner.sortNum",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }