var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "70%",
        title: _vm.title,
        "before-close": _vm.handleCancel,
      },
      on: { closed: _vm.closed },
    },
    [
      _c(
        "el-form",
        {
          ref: "modalFrom",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "版本号：", prop: "version" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.form.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "version", $$v)
                  },
                  expression: "form.version",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "内容：", prop: "content" } },
            [
              _c("rich-text", {
                ref: "richEdit",
                attrs: { value: _vm.form.content },
                on: { change: _vm.handleRichTextChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传图片：", prop: "picUrl" } },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    accept: ".jpg,.png,.jpeg",
                    action: _vm.actionUrl,
                    "show-file-list": false,
                    "on-success": (res, file) =>
                      _vm.handleAvatarSuccess(res, file),
                    "before-upload": _vm.beforeUpload,
                  },
                },
                [
                  _vm.form.picUrl
                    ? _c("img", {
                        style: { width: "150px" },
                        attrs: { src: _vm.form.picUrl },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
              _c("div", { staticClass: "upload-tip" }, [
                _c("i", { staticClass: "el-icon-info" }),
                _vm._v(" 只能上传jpg/png格式文件，单个文件不能超过50MB "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleOk } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }