var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "新增/编辑",
        visible: _vm.visible,
        width: "500px",
        "show-close": false,
      },
      on: { close: _vm.onCancel },
    },
    [
      _vm.typeList.length > 0
        ? _c(
            "el-form",
            {
              ref: "modalFrom",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px",
                size: "small",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "channelId", label: "渠道ID：" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "编号规则示例:首字母 +区号 + 4位数编号",
                      },
                      model: {
                        value: _vm.form.channelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "channelId", $$v)
                        },
                        expression: "form.channelId",
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            slot: "suffix",
                            effect: "dark",
                            content: "编号规则示例:首字母 +区号 + 4位数编号",
                            placement: "top",
                          },
                          slot: "suffix",
                        },
                        [
                          _c("i", {
                            staticClass: "el-input__icon el-icon-warning",
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "渠道类型：", prop: "channelTypeId" } },
                [
                  _c("el-select-tree", {
                    ref: "tree",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.typeList,
                      props: {
                        label: "channelTypeName",
                        child: "children",
                        value: "channelTypeId",
                      },
                      "check-strictly": "",
                      accordion: "",
                      "node-key": "channelTypeId",
                    },
                    on: {
                      "node-expand": _vm.typeChange,
                      "current-change": _vm.typeChangeToBol,
                    },
                    model: {
                      value: _vm.form.channelTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelTypeId", $$v)
                      },
                      expression: "form.channelTypeId",
                    },
                  }),
                ],
                1
              ),
              _vm.showCity
                ? _c(
                    "el-form-item",
                    { attrs: { label: "渠道城市：", prop: "districtId" } },
                    [
                      _c("el-select-tree", {
                        ref: "cascader",
                        staticStyle: { width: "100%" },
                        attrs: {
                          load: _vm.lazyLoad,
                          lazy: "",
                          props: {
                            value: "districtId",
                            label: "districtName",
                            child: "childs",
                          },
                          accordion: "",
                          filterable: "",
                        },
                        on: { "current-change": _vm.cityChange },
                        model: {
                          value: _vm.form.districtId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "districtId", $$v)
                          },
                          expression: "form.districtId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称：", prop: "channelName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入渠道名称" },
                    model: {
                      value: _vm.form.channelName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "channelName", $$v)
                      },
                      expression: "form.channelName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "渠道等级：",
                    prop: "level",
                    placeholder: "请选择渠道等级",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      model: {
                        value: _vm.form.level,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "level", $$v)
                        },
                        expression: "form.level",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 1, label: "A级" } }),
                      _c("el-option", { attrs: { value: 2, label: "B级" } }),
                      _c("el-option", { attrs: { value: 3, label: "C级" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "负责人：", prop: "userName" } },
                [
                  _c("el-autocomplete", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-key": "userName",
                      label: "userId",
                      "fetch-suggestions": _vm.querySearchStaffAsync,
                      placeholder: "请输入负责人姓名",
                      "popper-class": "my-autocomplete",
                    },
                    on: { select: _vm.userSelect },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "prdtname" }, [
                                _c("span", [
                                  _vm._v(_vm._s(item.userName + " - ")),
                                ]),
                                _c("span", [_vm._v(_vm._s(item.userId))]),
                              ]),
                              _c("div", [
                                _c("span", { staticClass: "brdtype" }, [
                                  _vm._v(_vm._s(item.postNames)),
                                ]),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      350739517
                    ),
                    model: {
                      value: _vm.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "userName", $$v)
                      },
                      expression: "form.userName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleOk },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }