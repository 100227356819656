var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "数据选项配置",
        visible: _vm.dialogVisible,
        width: "700px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { margin: "0 0 20px 0" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
              on: { click: _vm.addRow },
            },
            [_vm._v("添加")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: { data: _vm.tableData, border: "", size: "small" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "字段名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: { size: "small", placeholder: "请输入字段名称" },
                      model: {
                        value: scope.row.fieldName,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "fieldName", $$v)
                        },
                        expression: "scope.row.fieldName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "数据库字段名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-input", {
                      attrs: {
                        size: "small",
                        placeholder: "请输入数据库字段名称",
                      },
                      model: {
                        value: scope.row.databaseFieldName,
                        callback: function ($$v) {
                          _vm.$set(scope.row, "databaseFieldName", $$v)
                        },
                        expression: "scope.row.databaseFieldName",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "字段值类型" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("KeySelect", {
                      attrs: {
                        "req-key": "field_type",
                        val: scope.row.fieldType,
                      },
                      on: {
                        "update:val": function ($event) {
                          return _vm.$set(scope.row, "fieldType", $event)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.close } },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }