// 意见反馈

import request from '@/utils/request';

// 意见反馈详情
export function getAdviceDetail(data) {
  return request({
    url: "misc-web-api/admin/feedback/findDetailById ",
    method: 'POST',
    data,
  })
}

export default {}
