<template>
  <section class="pagination-wrapper">
    <div class=""></div>
    <div class="">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
      >
      </el-pagination>
    </div>
  </section>
</template>

<script>
export default {
  name: 'IPagination',
  props: {
    hideOnSinglePage: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number,
      default: 1,
      required: true
    },
    pageSize: {
      type: Number,
      default: 10,
      required: true
    },
    layout: {
      type: String,
      default: '',
      required: false
    },
    pageSizes: {
      type: Array
    },
    total: {
      type: Number
    }
  },
  data() {
    return {
      radio: -1
    };
  },
  methods: {
    handleSizeChange(e) {
      this.$emit('size-change', e);
    },
    handleCurrentChange(current) {
      this.$emit('current-change', current);
    }
  }
};
</script>

<style scoped lang="less">
@import './ipagination.less';
</style>
