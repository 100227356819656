import request from '@/utils/request';

export function getDataConfigList(data) {
  return request({
    url: 'user-web-api/admin/permission/config/getConfigList',
    method: 'POST',
    data
  });
}

export function saveDataConfig(data) {
  return request({
    url: 'user-web-api/admin/permission/config/save',
    method: 'POST',
    data
  });
}

export function saveDataCondition(data) {
  return request({
    url: 'user-web-api/admin/permission/info/add',
    method: 'POST',
    data
  });
}

export function getDataCondition(data) {
  return request({
    url: 'user-web-api/admin/permission/info/getPermissionInfo',
    method: 'POST',
    data
  });
}
