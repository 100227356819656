var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "售后说明设置", name: "2" } },
            [
              _c("el-input", {
                staticStyle: { width: "700px" },
                attrs: {
                  type: "textarea",
                  rows: "20",
                  placeholder: "请输入售后说明内容",
                },
                model: {
                  value: _vm.content2,
                  callback: function ($$v) {
                    _vm.content2 = $$v
                  },
                  expression: "content2",
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "预约改期须知设置", name: "3" } },
            [
              _c("el-input", {
                staticStyle: { width: "700px" },
                attrs: {
                  type: "textarea",
                  rows: "20",
                  placeholder: "请输入预约改期须知内容",
                },
                model: {
                  value: _vm.content3,
                  callback: function ($$v) {
                    _vm.content3 = $$v
                  },
                  expression: "content3",
                },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "客服取消预约须知设置", name: "4" } },
            [
              _c("el-input", {
                staticStyle: { width: "700px" },
                attrs: {
                  type: "textarea",
                  rows: "20",
                  placeholder: "请输入客服取消预约须知内容",
                },
                model: {
                  value: _vm.content4,
                  callback: function ($$v) {
                    _vm.content4 = $$v
                  },
                  expression: "content4",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存设置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }