var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.dialogTitle[_vm.dialogStatus],
        visible: _vm.visible,
        "close-on-click-modal": false,
        width: "30%",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
        open: _vm.onOpen,
        closed: _vm.onClosed,
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "SeatForm",
              staticStyle: { width: "90%" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "坐席渠道：", prop: "callAppConfigId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择渠道" },
                      model: {
                        value: _vm.formData.callAppConfigId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "callAppConfigId", $$v)
                        },
                        expression: "formData.callAppConfigId",
                      },
                    },
                    _vm._l(_vm.optionsCall, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "坐席名称：", prop: "ccUserName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入坐席名称" },
                    model: {
                      value: _vm.formData.ccUserName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "ccUserName", $$v)
                      },
                      expression: "formData.ccUserName",
                    },
                  }),
                ],
                1
              ),
              _vm.formData.callAppConfigId !== "1"
                ? [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "呼叫技能组：", prop: "skillGroupsId" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择技能组" },
                            model: {
                              value: _vm.formData.skillGroupsId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "skillGroupsId", $$v)
                              },
                              expression: "formData.skillGroupsId",
                            },
                          },
                          _vm._l(_vm.listByQuery, function (itemList) {
                            return _c("el-option", {
                              key: itemList.skillGroupsId,
                              attrs: {
                                label: itemList.skillGroups,
                                value: itemList.skillGroupsId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "编号：", prop: "outUserNo" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入编号" },
                          model: {
                            value: _vm.formData.outUserNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "outUserNo", $$v)
                            },
                            expression: "formData.outUserNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "账号：", prop: "outUserAccount" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入账号" },
                          model: {
                            value: _vm.formData.outUserAccount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "outUserAccount", $$v)
                            },
                            expression: "formData.outUserAccount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "密码：", prop: "outUserPwd" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入密码" },
                          model: {
                            value: _vm.formData.outUserPwd,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "outUserPwd", $$v)
                            },
                            expression: "formData.outUserPwd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.buttonLoading },
              on: { click: _vm.createSeat },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }