var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "advertisement" },
    [
      _c("div", { staticClass: "title-bar" }, [
        _c("span", { staticClass: "title" }, [_vm._v("广告配置")]),
        _c(
          "div",
          { staticClass: "btn-wrap" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "text",
                  icon: "el-icon-refresh-left",
                  size: "large",
                },
                on: { click: _vm.reduceAdvertisement },
              },
              [_vm._v(" 还原到上个版本 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "mini" },
                on: { click: _vm.saveAdvertisement },
              },
              [_vm._v("立即发布")]
            ),
          ],
          1
        ),
      ]),
      _c("el-divider", { staticClass: "divider" }),
      _c("div", { staticClass: "common-title" }, [_vm._v("轮播间隔时长配置")]),
      _c("div", { staticClass: "common-row" }, [
        _vm._m(0),
        _c("div", { staticClass: "common-row_container" }, [
          _c(
            "div",
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 4, size: "small" },
                model: {
                  value: _vm.interval,
                  callback: function ($$v) {
                    _vm.interval = $$v
                  },
                  expression: "interval",
                },
              }),
              _vm._v(" 秒 "),
            ],
            1
          ),
          _c("div", { staticClass: "tips" }, [_vm._v("仅支持1-4的整数")]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "common-title ad-settings" },
        [
          _c("span", [_vm._v("广告配置")]),
          _c("el-button", {
            attrs: {
              type: "primary",
              icon: "el-icon-plus",
              size: "small",
              plain: "",
            },
            on: { click: _vm.addItem },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "setting-list" },
        _vm._l(_vm.adList, function (item, index) {
          return _c("AdvertisementItem", {
            key: item.key || item.picUrl,
            attrs: { index: index, item: item },
            on: {
              deleteItem: _vm.deleteItem,
              change: _vm.advertisementItemChange,
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "common-row_name" }, [
      _c("span", { staticClass: "symbol" }, [_vm._v("*")]),
      _vm._v("间隔时长："),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }