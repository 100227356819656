<template>
  <section class="banner-list-wrapper" ref="altitude" v-loading="loading">
    <el-empty v-if="form.bannerList.length === 0" />
    <el-form
      label-width="150px"
      :model="form"
      ref="navigationFrom"
      v-if="form.bannerList.length > 0"
    >
      <section
        class="banner-list-item"
        v-for="(banner, index) in form.bannerList"
        :key="banner.bannerId"
      >
        <div class="delete-area">
          <i class="el-icon-delete delete" @click="handleDeleteBanner(banner, index)"></i>
        </div>
        <el-form-item
          label="图片："
          :prop="'bannerList.' + index + '.picUrl'"
          :rules="{
            required: true,
            message: '请上传图片',
            trigger: 'blur'
          }"
        >
          <el-upload
            class="avatar-uploader"
            :action="actionUrl"
            accept=".jpg,.png,.jpeg"
            :show-file-list="false"
            :on-success="(res, file) => handleAvatarSuccess(res, file, index)"
            :before-upload="beforeUpload"
          >
            <img v-if="banner.picUrl" :src="banner.picUrl" :style="{ width: '150px' }" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="upload-tip">
            <i class="el-icon-info"></i>
            只能上传jpg/png格式文件，文件不能超过50M，建议尺寸40px*40px
          </div>
        </el-form-item>
        <el-form-item
          label="导航名称："
          :prop="'bannerList.' + index + '.name'"
          :rules="{
            required: true,
            message: '请输入导航名称',
            trigger: 'blur'
          }"
        >
          <el-input type="url" v-model="banner.name" />
        </el-form-item>
        <el-form-item
          label="套系产品分类ID："
          :rules="{
            required: true,
            message: '请输入套系产品分类ID',
            trigger: 'blur'
          }"
          :prop="'bannerList.' + index + '.productCategoryId'"
        >
          <el-input type="url" v-model="banner.productCategoryId" />
        </el-form-item>
        <el-form-item label="排序：" prop="sortNum">
          <el-input-number
            v-model="banner.sortNum"
            :min="1"
            :max="9999"
            label="排序"
            style="width: 100%"
          ></el-input-number>
        </el-form-item>
      </section>
    </el-form>
  </section>
</template>

<script>
import { getBannerOrNavigationList, bannerSave, deleteBanner } from '@/api/wechat';

export default {
  name: 'BannerEdit',
  props: ['topicId'],

  data() {
    return {
      loading: false,
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`,
      imageUrl: '',
      form: {
        bannerList: []
      }
    };
  },
  watch: {
    topicId: {
      handler(val) {
        if (val !== '') {
          this.getBannerList();
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    toDown() {
      this.$nextTick(() => {
        this.$refs.altitude.scrollTop = this.$refs.altitude.scrollHeight;
      });
    },
    // 上传图片
    handleAvatarSuccess(res, file, bannerIndex) {
      if (res.success === false) {
        this.$message.error('上传图片失败');
        return;
      }
      this.form.bannerList[bannerIndex].picUrl = res.data.http;
    },
    // 删除banner
    async handleDeleteBanner(banner, bannerIndex) {
      if (!banner.bannerId) {
        this.form.bannerList = this.form.bannerList.filter((_, index) => index !== bannerIndex);
        this.$message.success('删除成功');
        return;
      }
      const { bannerId } = banner;
      const params = {
        data: {
          bannerId
        }
      };
      try {
        const res = await deleteBanner(params);
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.form.bannerList = this.form.bannerList.filter((item) => item.bannerId !== bannerId);
        this.$message.success('删除成功');
      } catch (e) {
        console.log(e);
      }
    },
    // 获取 banner 列表
    async getBannerList() {
      this.loading = true;
      try {
        const params = {
          data: {
            siteId: 2,
            status: 2, // 1发布   2 备份草稿
            topicId: this.topicId
          }
        };

        const res = await getBannerOrNavigationList(params);
        this.loading = false;
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }
        this.form.bannerList = res;
      } catch (e) {
        this.loading = false;
      }
    },
    async save() {
      let params = {
        data: {
          siteId: 2,
          status: 2,
          banners: this.form.bannerList,
          topicId: this.topicId
        }
      };

      if (this.form.bannerList.length > 0) {
        this.$refs.navigationFrom.validate(async (valid) => {
          if (!valid) {
            return false;
          }
        });
      } else {
        params.banners = [];
      }

      const res = await bannerSave(params);
      if (res.success === false) {
        this.$message.error(res.description);
        return;
      }
      this.$message.success('保存导航栏成功');
    },
    // 发布banner
    async publish() {
      let params = {
        data: {
          siteId: 2,
          status: 1,
          banners: this.form.bannerList,
          topicId: this.topicId
        }
      };

      if (this.form.bannerList.length > 0) {
        this.$refs.navigationFrom.validate(async (valid) => {
          if (!valid) {
            return false;
          }
        });
      } else {
        params.data.banners = [];
      }

      const res = await bannerSave(params);
      if (res.success === false) {
        this.$message.error(res.description);
        return;
      }
      this.getBannerList();
      this.$message.success('发布成功');
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 50;
      if (!isLt1M) {
        this.$message.error('图片大小超过50M');
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped lang="less">
.banner-list-wrapper {
  height: 100%;
  overflow: auto;

  .banner-list-item {
    position: relative;
    background: #f5f5f5;
    padding: 15px;
    margin-bottom: 20px;

    .delete-area {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 16px;
      height: 16px;
      z-index: 999;

      .delete {
        cursor: pointer;
      }
    }
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #000;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #fff;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: #fff;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .upload-tip {
    font-size: 12px;
    color: #999;
  }
}
</style>
