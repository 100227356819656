var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form",
    {
      ref: "searchForm",
      attrs: { inline: "", size: "small", model: _vm.searchForm },
    },
    [
      _c(
        "el-form-item",
        [
          _c("el-input", {
            staticStyle: { width: "110px" },
            attrs: { placeholder: "渠道ID" },
            model: {
              value: _vm.searchForm.channelId,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "channelId", $$v)
              },
              expression: "searchForm.channelId",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("el-input", {
            staticStyle: { width: "130px" },
            attrs: { placeholder: "渠道名称" },
            model: {
              value: _vm.searchForm.channelName,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "channelName", $$v)
              },
              expression: "searchForm.channelName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c("el-date-picker", {
            staticStyle: { width: "320px" },
            attrs: {
              type: "datetimerange",
              "range-separator": "至",
              "start-placeholder": "开始日期",
              "end-placeholder": "结束日期",
            },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100px" },
              attrs: { value: "", placeholder: "启用状态" },
              model: {
                value: _vm.searchForm.isDisable,
                callback: function ($$v) {
                  _vm.$set(_vm.searchForm, "isDisable", $$v)
                },
                expression: "searchForm.isDisable",
              },
            },
            [
              _c("el-option", { attrs: { value: "", label: "全部" } }),
              _c("el-option", { attrs: { value: 0, label: "启用" } }),
              _c("el-option", { attrs: { value: 1, label: "停用" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "所属区域：" } },
        [
          _c("el-select-tree", {
            ref: "cascader",
            staticStyle: { width: "100%" },
            attrs: {
              load: _vm.lazyLoad,
              lazy: "",
              props: {
                value: "districtId",
                label: "districtName",
                child: "childs",
              },
              accordion: "",
              filterable: "",
            },
            model: {
              value: _vm.searchForm.districtId,
              callback: function ($$v) {
                _vm.$set(_vm.searchForm, "districtId", $$v)
              },
              expression: "searchForm.districtId",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "负责人：" } },
        [
          _c("el-autocomplete", {
            staticStyle: { width: "100%" },
            attrs: {
              "value-key": "userName",
              label: "userId",
              "fetch-suggestions": _vm.querySearchStaffAsync,
              placeholder: "请输入负责人姓名",
              "popper-class": "my-autocomplete",
            },
            on: { select: _vm.userSelect },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ item }) {
                  return [
                    _c("div", { staticClass: "prdtname" }, [
                      _c("span", [_vm._v(_vm._s(item.userName + " - "))]),
                      _c("span", [_vm._v(_vm._s(item.userId))]),
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "brdtype" }, [
                        _vm._v(_vm._s(item.postNames)),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.userName,
              callback: function ($$v) {
                _vm.userName = $$v
              },
              expression: "userName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("查询")]
          ),
          _c("el-button", { on: { click: _vm.reset } }, [_vm._v("重置")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }