var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "altitude",
      staticClass: "recommentd-edit-content",
    },
    [
      _c(
        "el-form",
        {
          ref: "recommendForm",
          attrs: { "label-width": "180px", model: _vm.form },
        },
        _vm._l(_vm.form.recommendDataList, function (area, index) {
          return _c(
            "div",
            { key: `area${index}`, staticClass: "form-content-wrapper" },
            [
              _c("div", { staticClass: "delete-area" }, [
                _c("i", {
                  staticClass: "el-icon-delete delete",
                  on: {
                    click: function ($event) {
                      return _vm.handleDeleteArea(area, index)
                    },
                  },
                }),
              ]),
              _c(
                "el-form-item",
                {
                  style: { width: "100%" },
                  attrs: {
                    label: "推荐标题：",
                    prop: "recommendDataList." + index + ".title",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写推荐标题", maxlength: "20" },
                    model: {
                      value: area.title,
                      callback: function ($$v) {
                        _vm.$set(area, "title", $$v)
                      },
                      expression: "area.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "小标题：",
                    prop: "recommendDataList." + index + ".subject",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请填写小标题", maxlength: "20" },
                    model: {
                      value: area.subject,
                      callback: function ($$v) {
                        _vm.$set(area, "subject", $$v)
                      },
                      expression: "area.subject",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "套系产品分类ID：",
                    prop: "recommendDataList." + index + ".categoryId",
                    rules: {
                      required: true,
                      message: "请输入套系产品分类ID：",
                      trigger: "blur",
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: "请输入套系产品分类ID",
                      min: "1",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        return _vm.proving1($event)
                      },
                    },
                    model: {
                      value: area.categoryId,
                      callback: function ($$v) {
                        _vm.$set(area, "categoryId", $$v)
                      },
                      expression: "area.categoryId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "排序：",
                    prop: "recommendDataList." + index + ".sortNum",
                  },
                },
                [
                  _c("el-input-number", {
                    attrs: {
                      min: 1,
                      max: 9999,
                      label: "请输入排序数字，数字越小越靠前",
                    },
                    model: {
                      value: area.sortNum,
                      callback: function ($$v) {
                        _vm.$set(area, "sortNum", $$v)
                      },
                      expression: "area.sortNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "推荐样式：",
                    prop: "recommendDataList." + index + ".composeType",
                    rules: _vm.areaRules.composeType,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "recommend-list",
                      staticStyle: { overflow: "auto" },
                    },
                    [
                      _c(
                        "el-row",
                        { staticStyle: { "min-width": "600px" } },
                        _vm._l(_vm.layoutList, function (layout, sIndex) {
                          return _c(
                            "el-col",
                            {
                              key: `s${sIndex}`,
                              attrs: { md: 8, sm: 8, lg: 4 },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  on: {
                                    change: (e) =>
                                      _vm.handleStyleChange(index, e),
                                  },
                                  model: {
                                    value: area.composeType,
                                    callback: function ($$v) {
                                      _vm.$set(area, "composeType", $$v)
                                    },
                                    expression: "area.composeType",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "layout-wrapper" },
                                    [
                                      _c("we-chat-style-wrapper", {
                                        attrs: {
                                          index: parseInt(
                                            layout.dictionaryValue
                                          ),
                                        },
                                      }),
                                      _c(
                                        "el-radio",
                                        {
                                          attrs: {
                                            label: Number(
                                              layout.dictionaryValue
                                            ),
                                          },
                                        },
                                        [_vm._v(_vm._s(layout.dictionaryName))]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._l(area.products, function (product, pIndex) {
                return _c(
                  "div",
                  {
                    key: `product${pIndex}`,
                    staticClass: "recommend-list-item",
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            area.composeType == 1 ||
                            area.composeType == 7 ||
                            area.composeType == 8,
                          expression:
                            "area.composeType == 1 || area.composeType == 7 || area.composeType == 8",
                        },
                      ],
                      staticClass: "el-icon-delete delete",
                      on: {
                        click: function ($event) {
                          return _vm.handleDeleteProduct(index, pIndex)
                        },
                      },
                    }),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "图片：",
                          prop:
                            "recommendDataList." +
                            index +
                            ".products." +
                            pIndex +
                            ".picUrl",
                          rules: {
                            required: true,
                            message: "请选择图片",
                            trigger: "blur",
                          },
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              accept: ".jpg, .png, .jpeg",
                              action: _vm.actionUrl,
                              "show-file-list": false,
                              "on-success": (res, file) =>
                                _vm.handleAvatarSuccess(
                                  res,
                                  file,
                                  index,
                                  pIndex
                                ),
                              "before-upload": _vm.beforeUpload,
                            },
                          },
                          [
                            product.picUrl
                              ? _c("img", {
                                  style: { width: "150px" },
                                  attrs: { src: product.picUrl },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                        _c("div", { staticClass: "upload-tip" }, [
                          _c("i", { staticClass: "el-icon-info" }),
                          _vm._v(
                            " 只能上传jpg/png格式文件，文件不能超过50M，建议尺寸" +
                              _vm._s(
                                _vm.widthHeightTip(area.composeType, pIndex)
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "标题：",
                          prop:
                            "recommendDataList." +
                            index +
                            ".products." +
                            pIndex +
                            ".productName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { type: "text", maxlength: "20" },
                          model: {
                            value: product.productName,
                            callback: function ($$v) {
                              _vm.$set(product, "productName", $$v)
                            },
                            expression: "product.productName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "自定义链接：",
                          prop:
                            "recommendDataList." +
                            index +
                            ".products." +
                            pIndex +
                            ".productId",
                          rules: {
                            required: true,
                            message: "请输入自定义链接",
                            trigger: "blur",
                          },
                        },
                      },
                      [
                        _c("el-input", {
                          model: {
                            value: product.productId,
                            callback: function ($$v) {
                              _vm.$set(product, "productId", $$v)
                            },
                            expression: "product.productId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "排序：",
                          prop:
                            "recommendDataList." +
                            index +
                            ".products." +
                            pIndex +
                            ".sortNum",
                        },
                      },
                      [
                        _c("el-input-number", {
                          attrs: {
                            min: 1,
                            max: 9999,
                            disabled:
                              area.composeType !== 1 &&
                              area.composeType !== 7 &&
                              area.composeType !== 8,
                            label: "请输入排序数字，数字越小越靠前",
                          },
                          model: {
                            value: product.sortNum,
                            callback: function ($$v) {
                              _vm.$set(product, "sortNum", $$v)
                            },
                            expression: "product.sortNum",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            area.composeType == 1 ||
                            area.composeType == 7 ||
                            area.composeType == 8,
                          expression:
                            "area.composeType == 1 || area.composeType == 7 || area.composeType == 8",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleAddProduct(area, index)
                        },
                      },
                    },
                    [_vm._v("增加推荐产品")]
                  ),
                ],
                1
              ),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }