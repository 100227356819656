<template>
  <section class="sample-list">
    <div class="search">
      <el-form :model="searchForm" ref="searchForm" :show-message="false" size="mini" inline>
        <el-form-item label="样片类型" style="width: 200px">
          <el-select v-model="searchForm.categoryId" placeholder="样片类型" style="width: 100%">
            <el-option value>全部</el-option>
            <el-option
              :value="item.categoryId"
              v-for="item in categoryList"
              :key="item.categoryId"
              :label="item.categoryName"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item style="width: 200px" label="样片ID：">
          <el-input placeholder="样片ID" v-model="searchForm.sampleCode" />
        </el-form-item>

        <el-form-item style="width: 200px" label="样片名称：">
          <el-input placeholder="样片名称" v-model="searchForm.sampleName" />
        </el-form-item>

        <el-form-item style="width: 200px" label="是否授权：">
          <el-select v-model="searchForm.isAuthorize" placeholder="是否授权">
            <el-option value="" label="全部"></el-option>
            <el-option :value="1" label="是"></el-option>
            <el-option :value="0" label="否"></el-option>
          </el-select>
        </el-form-item>

        <div class="tags-wrapper">
          <el-button size="mini" @click="handleSearch">查询</el-button>
          <el-button size="mini" @click="handleReset">重置</el-button>
        </div>
      </el-form>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%"
      class="table"
      border
      :header-cell-style="{ 'background-color': '#f5f7fa' }"
      size="mini"
      max-height="350"
      @selection-change="handleSelectionChange"
      ref="multipleTable"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column prop="sampleCode" label="样片ID" width="100"> </el-table-column>
      <el-table-column prop="sampleName" label="样片名称" width="120"> </el-table-column>
      <el-table-column prop="categoryName" label="样片类型"> </el-table-column>
      <el-table-column label="封面图" width="100">
        <template slot-scope="scope">
          <el-image
            :src="handleImageUrl(scope.row.coverPictureUrl)"
            style="width: 60px; height: 60px"
          >
          </el-image>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination
        background
        layout="total,prev,pager,next,sizes"
        :total="total"
        :page-sizes="[10, 50, 100, 200]"
        @size-change="pageSizeChange"
        @current-change="pageChange"
        :current-page="pageNumber"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>
  </section>
</template>

<script>
import { getSampleTypeTree, getSampleList } from '@/api/sample';

export default {
  data() {
    return {
      categoryList: [], // 分类；分类类表
      searchForm: {
        sampleCode: '',
        categoryId: '',
        sampleName: '',
        isAuthorize: '',
        tagList: []
      },
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      tableData: []
    };
  },
  computed: {
    tagIdList() {
      return this.searchForm.tagList.map((el) => el.tagId);
    }
  },
  created() {
    this.getList();
    this.getSampleCategory();
  },
  methods: {
    handleSearch() {
      this.pageNumber = 1;
      this.getList();
    },
    handleReset() {
      this.searchForm.categoryId = '';
      this.searchForm.isAuthorize = '';
      this.searchForm.sampleName = '';
      this.searchForm.categoryId = '';
      this.searchForm.sampleCode = '';
      this.searchForm.tagList = [];
      this.searchForm.productTagValues = [];

      this.pageNumber = 1;
      this.getList();
    },
    async getSampleCategory() {
      try {
        const res = await getSampleTypeTree({ data: { isDisable: 1, auditStatus: 2 } });
        if (res.success === false) {
          this.$message.error(res.description);
          return;
        }

        this.categoryList = res;
      } catch (e) {}
    },
    getList() {
      const params = {
        data: { ...this.searchForm, tagList: this.tagIdList },
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      };

      getSampleList(params)
        .then((res) => {
          if (!res) return;
          const { total, content } = res;
          this.total = Number(total || 0);
          this.tableData = content || [];
        })
        .catch((err) => console.log(err));
    },
    pageSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    pageChange(val) {
      this.pageNumber = val;
      this.getList();
    },
    handleImageUrl(url) {
      if (!url) return url;
      if (url.includes('?')) return url + '&x-oss-process=image/quality,q_30';
      return url + '?x-oss-process=image/quality,q_30';
    },
    handleSelectionChange(val) {
      if (val.length === 0 || val.length === 1) {
        if (val.length === 1) this.$emit('change', val);
        return;
      }

      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
    }
  }
};
</script>

<style lang="less" scoped>
.sample-list {
  flex: 1;
  border-right: 1px solid #e0e0e0;
  padding-right: 20px;
  margin-right: 20px;
  overflow: hidden;

  .tags-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
  }

  .table {
    /deep/ .el-table__header {
      .el-checkbox {
        display: none;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }
}
</style>
