<template>
  <el-dialog title="数据选项配置" :visible.sync="dialogVisible" width="700px">
    <div style="margin: 0 0 20px 0">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addRow">添加</el-button>
    </div>

    <el-table :data="tableData" border style="width: 100%" size="small">
      <el-table-column label="字段名称">
        <template slot-scope="scope">
          <el-input
            size="small"
            v-model="scope.row.fieldName"
            placeholder="请输入字段名称"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="数据库字段名称">
        <template slot-scope="scope">
          <el-input
            size="small"
            v-model="scope.row.databaseFieldName"
            placeholder="请输入数据库字段名称"
          ></el-input>
        </template>
      </el-table-column>

      <el-table-column label="字段值类型">
        <template slot-scope="scope">
          <KeySelect req-key="field_type" :val.sync="scope.row.fieldType" />
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
      <el-button type="primary" @click="submit" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getDataConfigList, saveDataConfig } from '@/api/roleMenuManage.js';

export default {
  props: {
    nodeId: {
      type: [String, Number],
      default: ''
    }
  },
  watch: {
    dialogVisible(newVal) {
      if (!this.dialogVisible) this.tableData = [];
      if (newVal) this.getDetail();
    }
  },
  data() {
    return {
      dialogVisible: false,
      tableData: []
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
    },
    submit() {
      const flag = this.tableData.some((x) => {
        return !x.fieldName || !x.databaseFieldName || !x.fieldType;
      });

      if (flag) {
        this.$message.warning('请检查相关字段配置是否设置！');
        return;
      }

      saveDataConfig({ data: this.tableData.map((el) => ({ ...el, nodeId: this.nodeId })) })
        .then((res) => {
          if (!res) return;
          this.$message.success('操作成功');
          this.$emit('refresh');
          this.close();
        })
        .catch((err) => console.log(err));
    },
    addRow() {
      this.tableData.push({ fieldName: '', databaseFieldName: '', fieldType: '' });
    },
    getDetail() {
      getDataConfigList({ data: this.nodeId })
        .then((res) => {
          this.tableData = res || [];
        })
        .catch((err) => console.log(err));
    }
  }
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  text-align: right;
}
</style>
