var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "set-city-manage",
        title: "",
        visible: _vm.dialogVisible,
        "show-close": false,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.cancel,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          "prefix-icon": "el-icon-search",
                          placeholder: "搜索城市名称",
                        },
                        model: {
                          value: _vm.filterText,
                          callback: function ($$v) {
                            _vm.filterText = $$v
                          },
                          expression: "filterText",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-divider"),
                  _c(
                    "div",
                    { staticClass: "city-tree" },
                    [
                      _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          data: _vm.data,
                          "default-expand-all": "",
                          "filter-node-method": _vm.filterNode,
                          "icon-class": "el-icon-arrow-right",
                          "show-checkbox": "",
                          "node-key": "districtId",
                          props: _vm.defaultProps,
                          "default-checked-keys": _vm.defaultKeys,
                        },
                        on: { "check-change": _vm.selectNode },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ node, data }) {
                              return _c(
                                "span",
                                { staticClass: "custom-tree-node" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "title",
                                      style:
                                        data.districtType == 1
                                          ? "font-weight:600"
                                          : "",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.districtName) + " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "margin-left": "30px",
                      "margin-bottom": "10px",
                      "margin-top": "0",
                      "font-weight": "600",
                    },
                  },
                  [_vm._v(" 已选 ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "city-tags",
                    staticStyle: {
                      padding: "10px",
                      "padding-top": "0",
                      "padding-left": "30px",
                      height: "430px",
                    },
                  },
                  _vm._l(_vm.selectList, function (city) {
                    return _c(
                      "el-tag",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: city.districtType !== 1,
                            expression: "city.districtType !== 1",
                          },
                        ],
                        key: city.districtId,
                        staticStyle: {
                          "margin-right": "10px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v("#" + _vm._s(city.districtName))]
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "right",
                      "margin-top": "15px",
                    },
                  },
                  [
                    _c("el-button", { on: { click: _vm.cancel } }, [
                      _vm._v("取消"),
                    ]),
                    _c(
                      "el-button",
                      { attrs: { type: "primary" }, on: { click: _vm.ok } },
                      [_vm._v("确认")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }