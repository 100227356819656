var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "phone-wrapper" },
    [
      _vm.form.list.length > 0
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: { "label-width": "260px", size: "small", model: _vm.form },
            },
            [
              _c("common-title", { attrs: { title: "用户端平台联系电话" } }),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "400电话：",
                        prop: "list." + 0 + ".dictionaryValue",
                        rules: {
                          required: true,
                          message: "400电话不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.form.list[0].dictionaryValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.list[0], "dictionaryValue", $$v)
                          },
                          expression: "form.list[0].dictionaryValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "客服电话：",
                        prop: "list." + 1 + ".dictionaryValue",
                        rules: {
                          required: true,
                          message: "客服电话不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.form.list[1].dictionaryValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.list[1], "dictionaryValue", $$v)
                          },
                          expression: "form.list[1].dictionaryValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("common-title", { attrs: { title: "供应端联系平台电话" } }),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "供应端删除服务单照片联系电话：",
                        prop: "list." + 2 + ".dictionaryValue",
                        rules: {
                          required: true,
                          message: "供应端删除服务单照片联系电话不能为空",
                          trigger: "blur",
                        },
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        model: {
                          value: _vm.form.list[2].dictionaryValue,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.list[2], "dictionaryValue", $$v)
                          },
                          expression: "form.list[2].dictionaryValue",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSave },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }