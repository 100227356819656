var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "department-table-wrapper" },
    [
      _c(
        "article",
        { staticClass: "search" },
        [
          _c("chanel-table-search", {
            on: { search: _vm.handleSearch, reset: _vm.handleRest },
          }),
        ],
        1
      ),
      _c(
        "article",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "table-wrapper",
        },
        [
          _c(
            "vxe-grid",
            _vm._b(
              {
                attrs: {
                  border: "",
                  data: _vm.dataSource,
                  columns: _vm.tableColumn,
                  size: "small",
                },
                scopedSlots: _vm._u([
                  {
                    key: "toolbar_buttons",
                    fn: function () {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "primary" },
                            on: { click: _vm.handleAdd },
                          },
                          [_vm._v("添加渠道")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "customerNum",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticStyle: { display: "block" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.gotoCustomer(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.customerNum))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "importBatchNum",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-link",
                          {
                            staticStyle: { display: "block" },
                            attrs: { type: "primary", underline: false },
                            on: {
                              click: function ($event) {
                                return _vm.gotoBatch(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.importBatchNum))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "districtName",
                    fn: function ({ row }) {
                      return [
                        _vm._v(" " + _vm._s(row.districtName || "全国") + " "),
                      ]
                    },
                  },
                  {
                    key: "operate",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowIsDisableModal(row)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(row.isDisable === 0 ? "停用" : "启用")
                            ),
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShowDeleteModal(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "isDisable",
                    fn: function ({ row }) {
                      return [
                        _vm._v(_vm._s(row.isDisable === 0 ? "启用" : "停用")),
                      ]
                    },
                  },
                  {
                    key: "level",
                    fn: function ({ row }) {
                      return [_vm._v(_vm._s(_vm.levelText(row.level)))]
                    },
                  },
                  {
                    key: "pager",
                    fn: function () {
                      return [
                        _c("i-pagination", {
                          attrs: {
                            "page-size": _vm.iPagination.pageSize,
                            "current-page": _vm.iPagination.currentPage,
                            "page-sizes": [10, 20, 30, 40],
                            layout: "total,prev, pager, next ,sizes,jumper",
                            total: _vm.iPagination.total,
                          },
                          on: {
                            "current-change": _vm.handlePageChange,
                            "size-change": _vm.handleSizeChange,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              "vxe-grid",
              _vm.tableOptions,
              false
            )
          ),
        ],
        1
      ),
      _c("edit-channel-modal", {
        ref: "modalForm",
        attrs: { channelId: _vm.isDisable === 0 ? _vm.channelTypeId : "" },
        on: { ok: _vm.modalFormOk },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }