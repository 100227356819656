var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "sample-list" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.visible,
                width: "1140px",
                "before-close": _vm.handleCancel,
                top: "8vh",
              },
            },
            [
              _c(
                "section",
                { staticClass: "sample-list-content" },
                [
                  _c("SampleList", {
                    ref: "sampleList",
                    on: { change: _vm.tableChange },
                  }),
                  _c(
                    "div",
                    { staticClass: "images-choose" },
                    _vm._l(_vm.currentAlbums, function (item, index) {
                      return _c(
                        "div",
                        { key: item, staticClass: "img-item-wrap" },
                        [
                          _c("img", {
                            attrs: { src: _vm.handleImageUrl(item) },
                            on: {
                              click: function ($event) {
                                _vm.currentIndex = index
                              },
                            },
                          }),
                          index === _vm.currentIndex
                            ? _c("i", { staticClass: "el-icon-success" })
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini" },
                      on: { click: _vm.handleCancel },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.handleOk },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }