<template>
  <el-dialog
    :visible="visible"
    width="70%"
    :title="title"
    @closed="closed"
    :before-close="handleCancel"
  >
    <el-form :model="form" :rules="rules" label-width="100px" ref="modalFrom">
      <el-form-item label="版本号：" prop="version">
        <el-input v-model="form.version" />
      </el-form-item>
      <el-form-item label="内容：" prop="content">
        <rich-text :value="form.content" @change="handleRichTextChange" ref="richEdit" />
      </el-form-item>
      <el-form-item label="上传图片：" prop="picUrl">
        <el-upload
          class="avatar-uploader"
          accept=".jpg,.png,.jpeg"
          :action="actionUrl"
          :show-file-list="false"
          :on-success="(res, file) => handleAvatarSuccess(res, file)"
          :before-upload="beforeUpload"
        >
          <img v-if="form.picUrl" :src="form.picUrl" :style="{ width: '150px' }" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div class="upload-tip">
          <i class="el-icon-info"></i>
          只能上传jpg/png格式文件，单个文件不能超过50MB
        </div>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleOk">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { cloneDeep } from 'lodash';
import TableModalMinix from '@/minixs/TableModalMinix';
import RichText from '@/components/rich-text/index.vue';
import { addAgreement } from '@/api/agreement';

export default {
  name: 'EditModal',
  mixins: [TableModalMinix],
  components: {
    RichText
  },
  data() {
    return {
      visible: false,
      isDetail: false,
      modalFrom: 'modalFrom',
      title: '',
      imageUrl: '',
      defaultForm: {
        type: '',
        version: '',
        content: '',
        picUrl: ''
      },
      form: {
        type: '',
        version: '',
        content: '',
        picUrl: ''
      },
      rules: {
        version: [{ required: true, message: '请输入版本号', trigger: 'blur' }],
        content: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      url: {
        add: 'misc-web-api/admin/agreement/save',
        update: 'misc-web-api/admin/agreement/save'
      },
      actionUrl: `${process.env.VUE_APP_BASE_URL}misc-web-api/common/file/uploadImage`
    };
  },
  methods: {
    closed() {
      this.$nextTick(() => {
        this.$refs.richEdit.editor.txt.clear();
      });
    },
    add() {
      this.title = '新增';
      this.visible = true;
    },
    edit(row) {
      this.title = '编辑';
      this.visible = true;

      this.$nextTick(() => {
        this.$refs.richEdit.editor.txt.append(row.content);
      });
      this.form.version = row.version;
      this.form.content = row.content;
      this.form.picUrl = row.picUrl ? JSON.parse(row.picUrl)[0] : '';
    },
    // 确定
    async handleOk() {
      await this.$refs.modalFrom.validate(async (valid) => {
        if (valid) {
          try {
            const formData = cloneDeep(this.form);
            if (formData.picUrl) {
              formData.picUrl = [formData.picUrl];
            } else {
              formData.picUrl = [];
            }
            const params = {
              data: formData
            };
            const res = await addAgreement(params);
            if (res.success === false) {
              this.$message.error(res.description);
              return;
            }
            this.$emit('modalFormOk');
            this.handleCancel();
          } catch (e) {
            console.log(e);
          }
        }
      });
    },
    handleRichTextChange(data) {
      this.form.content = data;
    },
    handleAvatarSuccess(res, file) {
      if (res.success === false) {
        this.$message.error('上传图片失败');
        return;
      }
      this.form.picUrl = res.data.http;
    },
    beforeUpload(file) {
      console.log(file.size);
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!isLt5M) {
        this.$message.error('图片大小超过50M');
        return false;
      }
      return true;
    }
  }
};
</script>

<style scoped lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #000;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 104px;
  height: 104px;
  line-height: 104px;
  text-align: center;
  border: 1px solid #eee;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.upload-tip {
  font-size: 12px;
  color: #999;
}
</style>
