
import request from '@/utils/request';

/**
 * 获取菜单树
 * @param {*} data
 * @returns
 */
export function getNodeTree(data) {
  return request({
    url: "user-web-api/admin/permission/tree",
    method: 'POST',
    data,
  })
}

/**
 * 更新菜单|资源
 * @param {*} data
 * @returns
 */
export function updateNode(data) {
  return request({
    url: "user-web-api/admin/permission/update",
    method: 'POST',
    data,
  })
}
/**
 * 批量删除菜单
 * @param {*} data
 * @returns
 */
export function deletNodes(data) {
  return request({
    url: "user-web-api/admin/permission/deleteByIds",
    method: 'POST',
    data,
  })
}

/**
 * 检查新增，编辑node节点名称是否重复
 * @param {}} data
 * @returns
 */
export function checkNodeNameRepeat(data) {
  return request({
    url: "user-web-api/admin/permission/deleteByIds",
    method: 'POST',
    data,
  })
}

export default {}
