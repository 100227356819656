var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-settings" },
    [
      _c(
        "el-form",
        {
          ref: "dialogSettingsForm",
          attrs: { model: _vm.ruleForm, "label-width": "100px" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "推荐图片：" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    action: "#",
                    "list-type": "picture-card",
                    "on-remove": _vm.handleRemoveImage,
                    "auto-upload": false,
                    accept: "image/*",
                    "file-list": _vm.ruleForm.imgList,
                    "on-change": _vm.imageChange,
                    limit: 1,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "自定义链接：" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.link,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "link", $$v)
                  },
                  expression: "ruleForm.link",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }