var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      ref: "keySelect",
      attrs: {
        placeholder: _vm.placeholder,
        size: "small",
        filterable: true,
        remote: true,
        "remote-method": _vm.requestUserList,
        disabled: _vm.disabled,
        multiple: "",
      },
      on: { change: _vm.change, "visible-change": _vm.visibleChange },
      model: {
        value: _vm.selectVal,
        callback: function ($$v) {
          _vm.selectVal = $$v
        },
        expression: "selectVal",
      },
    },
    _vm._l(_vm.options, function (item) {
      return _c(
        "el-option",
        { key: item.value, attrs: { label: item.label, value: item.value } },
        [
          _c("span", [_vm._v("用户名：" + _vm._s(item.label) + " ")]),
          _c("span", [_vm._v("电话：" + _vm._s(item.phoneNumber) + " ")]),
          _c("span", [_vm._v("部门：" + _vm._s(item.departmentNames) + " ")]),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }