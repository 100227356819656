<template>
  <section class="position-wrapper">
    <search @search="handleSearch" @reset="handleRest" />
    <div class="table-wrapper">
      <vxe-grid
        :ref="tableRef"
        :rowId="rowId"
        border
        :data="dataSource"
        :columns="tableColumn"
        size="small"
        v-bind="tableOptions"
        v-loading="loading"
        @checkbox-change="handleCheckboxChange"
        @checkbox-all="handleCheckboxAll"
      >
        <template #toolbar_buttons>
          <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
          <!-- <el-button size="small" type="default" @click="handleExport">导出</el-button> -->
          <el-button size="small" type="default" @click="handleShowMultipleIsDisableModal(1)"
            >停用</el-button
          >
          <el-button size="small" type="default" @click="handleShowMultipleIsDisableModal(0)"
            >启用</el-button
          >
        </template>
        <template #isDisable="{ row }">
          <span>{{ row.isDisable === 0 ? '启用' : '停用' }}</span>
        </template>
        <template #operate="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleShowIsDisableModal(row)">
            {{ row.isDisable === 0 ? '停用' : '启用' }}
          </el-button>
        </template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </div>
    <edit-position-modal ref="modalForm" @ok="modalFormOk" />
  </section>
</template>

<script>
//  岗位
import Search from './components/search/index.vue';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMixin from '@/minixs/TableListMinix';
import EditPositionModal from './components/EditPositionModal.vue';
import { postAction } from '@/api/manage';

export default {
  name: 'List',
  mixins: [TableListMixin],
  components: {
    Search,
    IPagination,
    EditPositionModal
  },
  data() {
    return {
      rowId: 'postId',
      tableRef: 'PositionTable',
      modalForm: 'modalForm',
      url: {
        list: 'user-web-api/admin/post/getPostPage',
        status: 'user-web-api/admin/post/updateIsDisableInIds'
      },
      tableColumn: [
        { type: 'checkbox', width: 50 },
        { field: 'postId', title: '岗位ID' },
        { field: 'postName', title: '岗位名称' },
        { field: 'postTypeName', title: '岗位类型' },
        { field: 'roleName', title: '角色权限' },
        { field: 'isDisable', title: '启用状态', slots: { default: 'isDisable' } },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right' }
      ],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        },
        checkboxConfig: {
          // checkField:'rankId',
          checkRowKeys: [],
          reserve: true // 保留勾选状态
        }
      }
    };
  },
  methods: {
    // 单个启用禁用
    handleShowIsDisableModal(row) {
      const that = this;
      const params = {
        data: { ids: [parseInt(row.postId, 10)], upadateFlag: row.isDisable === 0 ? 1 : 0 }
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 多个启用禁用
    handleShowMultipleIsDisableModal(isDisable) {
      const that = this;
      const ids = this.checkedRows.map((item) => item[this.rowId]);
      if (ids.length === 0) {
        this.$message.closeAll();
        this.$message({
          message: '至少选择一条数据',
          type: 'warning'
        });
        return;
      }
      const params = {
        data: { ids: [...ids], upadateFlag: isDisable }
      };
      const message = isDisable === 0 ? '是否确定启用？' : '是否确定停用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params, 2);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 导出
    handleExport() {
      try {
        const postIds = this.tableOptions.checkboxConfig.checkRowKeys;
        const params = {
          data: {
            postIds,
            isExport: 1
          }
        };
        postAction(this.url.list, params, {
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          responseType: 'blob'
        }).then((res) => {
          console.log(res);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped lang="less">
.position-wrapper {
  margin-top: 16px;

  .table-wrapper {
    background: #fff;
    padding: 0 20px;
    margin-top: 20px;
  }
}
</style>
